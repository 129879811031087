import React, { Component, createRef } from 'react';
import { makeStyles, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Box,
  Button,
  Container,
  Typography,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Switch,
  FormControlLabel,
  Avatar,
  Menu,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LanguageSupport from '../../blocks/LanguageSupport/src/LanguageSupport.web';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
const i18n = require('i18next');


const logo = require('./Wecom_logo_2x.svg');
const facebook = require('./facebook.svg');
const insta = require('./instagram.png');
const linkdein = require('./linkdein.svg');
const twitter = require('./twitter.svg');
const youtube = require('./youtube.svg');
const downArrow = require('./downArrow.svg');
const lanugage = require('./language.svg');
const whiteDownArrow = require("./down_arrowWhite.svg");
const whiteLanguage = require("./language_white.svg");
const arrowRight = require("./arrowRight.svg");
const dummyUserImage = require('./dummyUserImage.png')
const upwardArrowIcon = require('./upwardArrow.svg')
const downwardArrowIcon = require('./downwardArrow.svg')
const editProfileIcon = require('./profileuserEditLogo.svg')
const noteIcon = require('./noteIcon.svg')
const logoutIcon = require('./logoutIcon.svg')

import AffiliateUrl from '../../blocks/AffiliateUrl/src/AffiliateUrl.web';
import storage from '../../framework/src/StorageProvider.web';
import { runEngine } from '../../framework/src/RunEngine';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { Message } from '../../framework/src/Message';
import { BlockComponent } from '../../framework/src/BlockComponent';
import { IBlock } from '../../framework/src/IBlock.jsx';
import { Translation } from 'react-i18next';
import { removeStorageData } from "../../framework/src/Utilities"
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement.web';



const styles = (theme: Theme) => ({
  headerTop: {
    backgroundColor: ' #DFE8ED',
    padding: '19px 83px 19px 66px',
    display: "flex",
    justifyContent: "space-between"
  },
  drawerBackgroundColor: {
    backgroundColor: ' #DFE8ED'
  },
  footerSections: {
    backgroundColor: '#012677',
  },
  primaryBtn: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#fff',
    padding: '8px 51px',
    border: '2px solid #fff',
    borderRadius: '10px',
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
  },
  footerTop: {
    padding: '90px 20px',
  },
  getStartHeading: {
    fontSize: '56px',
    fontWeight: 700,
    color: '#fff',
    lineHeight: '84px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      lineHeight: '40px',
      marginBottom: '30px',
    },
  },
  getStartParagraph: {
    color: '#F6F6F6 ',
    fontSize: '16px',
    fontWeight: 500,

  },
  mainFooterHeading: {
    color: '#F6F6F6',
    fontSize: '32px',
    fontWeight: 500,
  },
  mainFooter: {
    paddingTop: '90px',
    paddingBottom: '90px',
  },
  mainFooterPara: {
    color: '#F6F6F6',
    fontSize: '20px',
    paddingTop: '33px',
    maxWidth: '503px',
    fontWeight: 300,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      marginBottom: '25px',
    },
  },
  listHeading: {
    fontSize: '32px',
    color: '#F6F6F6',
    fontWeight: 500,

  },
  list: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    marginTop: '33px'
  },
  listItem: {
    fontSize: '20px',
    fontWeight: 300,
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    marginBottom: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  br: {
    background: '#D8D8D8'
  },
  socialList: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    display: 'flex',
    gap: '19px'
  },
  bottomFooter: {
    padding: '32px 0'
  },
  copyRightText: {
    color: '#fff',
    fontSize: '16px',
    textAlign: 'right' as 'right',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      textAlign: 'center',
    },
  },
  navbar: {
    backgroundColor: "#F6F6F6",
    boxShadow: "none"
  },
  toolbarStyle: {
    justifyContent: "end"
  },
  navItem: {
    color: "rgba(16, 33, 38, 0.60)",
    flex: 'unset', // Override the flex property
    minWidth: 'unset', // Override the min-width property
    marginTop: '0', // Override the margin-top property
    marginBottom: '0',
    fontSize: '20px',

  },
  navList: {
    display: "flex",

  },

  secondaryBtn: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#fff',
    padding: '11px 28px 7px 30px',
    height: '48px',
    width: '192px',
    borderRadius: '10px',
    backgroundColor: '#012677',
    textTransform: 'capitalize' as 'capitalize',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: '#012677', // Set the hover background color to be the same as the default color
    }
  },
  secondaryBtnWhite: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#012677',
    padding: '11px 28px 7px 30px',
    height: '48px',
    width: '192px',
    borderRadius: '10px',
    backgroundColor: '#F6F6F6;',
    textTransform: 'capitalize' as 'capitalize',
    fontFamily: 'Poppins',
    '&:hover': {
      backgroundColor: '#F6F6F6;', // Set the hover background color to be the same as the default color
    }
  },

  listItems: {
    display: 'flex',
    width: '128px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    color: 'rgba(16, 33, 38, 0.60)',
    '&:hover': {
      backgroundColor: '#F6F6F6', // Set the hover background color to be the same as the default color
    }
  },

  listItemsActiveColor: {
    display: 'flex',
    width: '128px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    color: 'rgba(246, 246, 246, 0.60)',
    '&:hover': {
      backgroundColor: '#012677', // Set the hover background color to be the same as the default color
    }
  },

  languageDropdown: {
    display: 'flex',
    width: '128px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    color: 'rgba(16, 33, 38, 0.60)',
    '&:hover': {
      backgroundColor: 'transparent', // Set the hover background color to be the same as the default color
    }
  },

  languageDropwnActive: {
    display: 'flex',
    width: '128px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: 'transparent', // Set the hover background color to be the same as the default color
    }
  },


  customListItemText: {
    flex: 'unset', // Override the flex property
    minWidth: 'unset', // Override the min-width property
    marginTop: '0', // Override the margin-top property
    marginBottom: '0', // Override the margin-bottom property
    /* Add any other custom styles you want to apply */
  },
  listitemText: {
    color: 'rgba(16, 33, 38, 0.60)',
    marginBottom: '4px',

  },
  loginText: {
    color: '#102126',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  },

  activeIndicator: {
    // color:'black'
  },

  pricingBackground: {
    backgroundColor: '#012677'
  },
  productBackground: {
    backgroundColor: "white",
    boxShadow: 'none'
  },

  pricingColorText: {
    color: 'rgba(246, 246, 246, 0.60)'
  },

  activeMenuColor: {
    color: 'white !important'
  },
  perfectPlan: {
    color: "#F6F6F6",
    fontFamily: "Poppins",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  perfectPlanSubHeading: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  talkBtn: {
    width: "145px",
    height: "48px",
    borderRadius: "10px",
    border: "2px solid #F6F6F6",
    background: "#012677",
    color: "white",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "19px",
    display: "flex",
    justifyContent: "space-around",
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }

  },
  talkBtnContainer: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "start",
    },

  },
  marginTalk: {
    margin: "126px 0 16px 0"
  },
  virtual: {
    maxWidth: "378px",
    width: '100%',
    height: "112px",
    backgroundColor: "#DFE8ED",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    color: "#102126",
    fontSize: "20px",
    fontWeight: 700,
    borderRadius: "0px",
    '&:hover': {
      backgroundColor: '#DFE8ED;', // Set the hover background color to be the same as the default color
    }
  },
  virtualUnactive: {
    maxWidth: "378px",
    width: '100%',
    height: "112px",
    backgroundColor: "#DFE0126778ED",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    color: "#F6F6F6",
    fontSize: "20px",
    border: 'none' as 'none',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },
  margin103: {
    marginTop: "103px",
    fontFamily: 'Poppins',
  },

  lowerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px 0px 10px 55px"
  },

  //header avatar start
  mainMenuItemBox: {
    marginTop: "70px",
  },
  dropdownContainer: {
    position: 'relative' as 'relative',
  },
  dropdown: {
    position: 'absolute' as 'absolute',
    top: 68,
    right: 0,
    left: '-140px',
    zIndex: 1,
    borderRadius: '16px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: '228px',
    boxShadow: 'rgb(0 0 0 / 21%) 0px 2px 25px 0px'
  },


  productDropdown: {
    position: 'absolute' as 'absolute',
    direction: 'ltr' as 'ltr',
    top: 38,
    right: 0,
    zIndex: 1,
    borderRadius: '16px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: '228px',
    boxShadow: 'rgb(0 0 0 / 21%) 0px 2px 25px 0px'
  },


  editProfileHeadingText: {
    color: "#102126",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "42px",
    flexGrow: 1,
  },

  headerUserImageMainAvatar: {
    width: "48px",
    height: "48px",
    background: "linear-gradient(to bottom, #023B86, #1BC0FF, #1BC0FF)"
  },

  profileImageAvatarImage: {
    width: "96.5%",
    height: "96.5%",
    borderRadius: "50%"
  },

  avatarMenuButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },

  openMenuButton: {
    cursor: "pointer"
  },

  listItemTextPoppins: {
    fontFamily: 'Poppins',
    // Add any other styles you need
  },

  menuItemsBox: {
    padding: "14px 0 20px 0",
    "& .MuiBox-root": {
      display: "flex",
      cursor: "pointer",
      padding: "0 40px 0 16px",
      "& .MuiTypography-root": {
        whiteSpace: "wrap",
        color: "#102126",
        fontSize: "16px",
        fontWeight: 300
      },
      "& img": {
        width: "24px"
      }
    },
    "& .MuiDivider-root": {
      marginTop: "6.25px",
      marginBottom: "16px"
    }
  },
  '@media (max-width: 1366px)': {
    mainMenuItemBox: {
      position: "fixed",
      top: 0,
      right: 0,
      transform: "translate(30%, 5%)",
      marginTop: "0px",
    },
  },
  //header avatar end
})


interface State {
  mobileOpen: boolean;
  activeMenu: string;
  isUserMenuItemActive: boolean,
  token: string | null,
  userProfileImage: string | null,
  languageDropwn: boolean,
  langKey: string,
  isDropwDownVisible: boolean,
  servicesIds: { id: string, serviceName: string }[]


}

interface Props extends WithStyles<typeof styles> {
  // Define your props here
  classes: any;
  navigation: any
  isHideGetStartButton: boolean;
  isShowSignUpHeading: boolean;
  isActiveGetStratedFooterButton: boolean;
  showEditProfileHeading: boolean;
  showHeaderAvatar: boolean
  userImage?: string,
}



class AppHeader extends Component<Props, State> {

  getSubjectMessageId = "";
  subScribedMessages: string[];
  serviceApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileOpen: false,
      activeMenu: window.location.pathname,
      isUserMenuItemActive: false,
      languageDropwn: false,
      langKey: '',
      token: null,
      isDropwDownVisible: false,
      servicesIds: [],
      userProfileImage: null
    };
    this.menuRef = createRef<HTMLImageElement>();
    this.componentDidMount = () => {
      this.getServices();
      this.getUserToken().then(() => {
        this.fetchUserProfile();
      })
        .catch((error) => {
          console.log("Error getting user token:", error);
        })

    }
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)]
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages)

  }





  static defaultProps = {
    isHideGetStartButton: false,
    isShowSignUpHeading: false,
    isActiveGetStratedFooterButton: false,
    showEditProfileHeading: false,
    showHeaderAvatar: false
  };

  public menuRef: React.RefObject<HTMLImageElement>;

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen
    })
  };


  handleLangueChange = async (newLang: string) => {

    i18n.changeLanguage(newLang, () => {
      this.setState(() => {
        return { langKey: newLang }
      }, () => window.dispatchEvent(new Event("storage")))
    });



    this.closeLanguageDrowdown();

  }


  getDemo = () => {
    const id = this.props.navigation.getParam('activeTab') || this.props.navigation.getParam('serviceId') || this.state.servicesIds[0].id;
    this.props.navigation.navigate('RequestDemoWithStyles', { id })
  }

  goToPricing = () => {
    this.props.navigation.navigate('PricingFinal', { activeTab: this.state.servicesIds[0].id })
  }

  goToProduct = () => {
    this.props.navigation.navigate('ProductQuickview')
  }

  handleIsUserMenuItemActive = () => {
    this.setState((prevState) => ({
      isUserMenuItemActive: !prevState.isUserMenuItemActive
    }));
  }


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSubjectMessageId) {

      if (responseJson?.errors) {

        await removeStorageData('authToken')
        this.setState({
          token: null
        })
      }
      else {
        this.setState((prevState) => {
          return { ...prevState, userProfileImage: responseJson?.image }
        })
      }

    }

    if (apiRequestCallId === this.serviceApiId) {

      //  console.log("services", responseJson.data)
      if (responseJson?.errors) {
        console.log("erorr", responseJson.errors)
      }
      else {
        this.setState(() => {
          const data = responseJson?.data.map((item: any) => {
            return { id: item.id, serviceName: item.attributes.service_name }
          })

          return { servicesIds: data }

        })


      }



    }
  }


  fetchUserProfile = () => {
    const headers = {
      token: this.state.token,
    };

    const getSubjectsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectMessageId = getSubjectsMessage.messageId;

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/profile_show`
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessage.id, getSubjectsMessage);
  };

  getServices = async () => {

    // const langKey =   await getStorageData('i18nextLng')


    const headers = {
      "Content-Type": "application/json",
      // "Accept-Language": langKey

    };

    const apiEndPoint = `bx_block_request_demo/services`;
    const getAvaialblePlansId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.serviceApiId = getAvaialblePlansId.messageId;

    getAvaialblePlansId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    getAvaialblePlansId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAvaialblePlansId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAvaialblePlansId.id, getAvaialblePlansId);




  }





  handleLogout = async () => {


    removeStorageData('authToken')
    this.setState((prevState) => {
      return {
        ...prevState,
        token: null
      }
    })

    const isUserProfile = this.state.activeMenu.includes('CustomisableUserProfiles');

    if (isUserProfile) {
      this.props.navigation.goBack();
    }
  }

  getUserToken = async () => {

    const token = await storage.get('authToken')
    this.setState((prevState) => {

      return {
        ...prevState,
        token
      }
    })


  }



  renderMenu = () => {

    return (
      <Translation>
        {t => <ClickAwayListener onClickAway={this.handleCickAway}>
          <div className={this.props.classes.dropdown}>
            <div className={this.props.classes.menuItemsBox}>

              <Box onClick={() => this.props.navigation.navigate('CustomisableUserProfiles')}>
                <Box>
                  <img src={editProfileIcon} alt="Edit Profile" />
                </Box>
                <Typography>{t('editProfile')}</Typography>
              </Box>
              {/* <Divider />
              <Box>
                <Box>
                  <img src={noteIcon} alt="Note Icon" />
                </Box>
                <Typography>{t('library')}</Typography>
              </Box> */}
              <Divider />
              <Box onClick={this.handleLogout}>
                <Box>
                  <img src={logoutIcon} alt="Logout Icon" />
                </Box>
                <Typography>{t('logout')}</Typography>
              </Box>
            </div>
          </div>
        </ClickAwayListener>}
      </Translation>

    )

  }

  handleProductNavigation = (id: string, serviceId: string) => {

    this.props.navigation.navigate('ProductQuickview', { id, serviceId })
    this.setState({
      isDropwDownVisible: false
    })

  }


  renderProductDropdown = () => {

    return (
      <Translation>
        {t => <ClickAwayListener onClickAway={() => this.setState({ isDropwDownVisible: false })}>
          <div className={this.props.classes.productDropdown}>
            <div className={this.props.classes.menuItemsBox}>

              {this.state.servicesIds.map((item) => (
                <>
                  <Box key={item.serviceName} onClick={() => this.handleProductNavigation('virtualPbx', item.id)}>

                    <Typography>{item.serviceName}</Typography>
                  </Box>
                  <Divider />
                </>
              ))}

              {/* <Box key="product2" onClick={() => this.handleProductNavigation('contactCenter', this.state.servicesIds[1].id) }>
                
                <Typography>{this.state.servicesIds[0].serviceName}</Typography>
              </Box>
              <Divider />
              <Box key="product3"  onClick={() => this.handleProductNavigation('mobileEngagement', this.state.servicesIds[2].id)} >
              
                <Typography>Mobile Engagement & Voice of Customer Platform</Typography>
              </Box> */}
            </div>
          </div>
        </ClickAwayListener>}
      </Translation>

    )

  }


  handleCickAway = () => {
    this.setState({
      isUserMenuItemActive: false
    })
  }

  handleProductDropwn = () => {
    this.setState((prevState) => {
      return {
        isDropwDownVisible: !prevState.isDropwDownVisible
      }
    })
  }




  renderHeaderAvatar = () => {
    const { classes } = this.props;
    const { isUserMenuItemActive, userProfileImage } = this.state;

    return (
      <Box className={`${classes.avatarMenuButtonWrapper} ${classes.dropdownContainer}`}>
        <Box>
          <Avatar className={classes.headerUserImageMainAvatar} src={userProfileImage || ''} />
        </Box>


        <Box
          className={classes.openMenuButton}
          onClick={this.handleIsUserMenuItemActive}
        >
          {isUserMenuItemActive ? (
            <img src={upwardArrowIcon} alt='Upward Arrow' />
          ) : (
            <img src={downwardArrowIcon} alt='Downward Arrow' />
          )}
        </Box>

        {this.state.isUserMenuItemActive && this.renderMenu()}
      </Box>
    );
  };

  handleLanguageDropDown = () => {
    this.setState({
      languageDropwn: !this.state.languageDropwn
    })


  }

  closeLanguageDrowdown = () => {
    this.setState({
      languageDropwn: false
    })
  }

  render() {
    const { mobileOpen } = this.state;
    const classes = this.props.classes;

    const isPricing = this.state.activeMenu.includes('pricing');
    const isProduct = this.state.activeMenu.includes('ProductQuickview');
    const isContactUs = this.state.activeMenu.includes('Contactus');



    const drawer = (
      <Translation>
        {t => <div style={{ fontFamily: 'Poppins' }}>

          <Box style={{ padding: '10px' }}>
            <img src={logo} alt='wecomm image' style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate('Home')} />
          </Box>
          <List style={{ display: 'flex', flexDirection: 'column', gap: '35px', paddingTop: '40px', alignItems: 'center' }}>
            <ListItem button
              className={classes.listItems}
              onClick={this.renderProductDropdown}
            >
              <Box style={{ display: "flex", flexDirection: 'column', gap: '3px' }}>
                <ListItemText onClick={this.handleProductDropwn} primary={t('products')} className={`${classes.navItem} ${classes.listItemTextPoppins}`} />
                {isProduct && <div style={{ width: '100%', height: '2px', background: "rgba(16, 33, 38, 0.60)" }} className={classes.activeIndicator} />}
                {this.state.isDropwDownVisible && this.renderProductDropdown()}
              </Box>
              <Box onClick={this.renderProductDropdown} >
                {isPricing ? <img alt="" src={whiteDownArrow} /> : <img src={downArrow} alt='downArrow' />}
              </Box>
            </ListItem>
            <ListItem button className={`${classes.listItems} `}>
              <Box style={{ display: "flex", flexDirection: 'column', gap: '3px' }}>
                <ListItemText primary={t('pricing')} onClick={this.goToPricing} className={`${classes.navItem} ${classes.listItemTextPoppins} `} />

                {isPricing && <div style={{ width: '100%', height: '2px', background: "rgba(16, 33, 38, 0.60)" }} className={classes.activeIndicator} />}
              </Box>
            </ListItem>
            <ListItem button className={classes.listItems} onClick={() => this.props.navigation.navigate('Contactus')}>
              <ListItemText primary={t('contactUs')} className={`${classes.navItem} ${classes.listItemTextPoppins}`} />
            </ListItem>

            <ListItem button onClick={this.handleLanguageDropDown} className={`${classes.listItems}`}>
              <Box>
                <img alt="" src={lanugage} />

              </Box>
              <Box>
                <img src={downArrow} alt='downArrow' />
              </Box>
              {this.state.languageDropwn && (

                <ClickAwayListener onClickAway={this.closeLanguageDrowdown}>
                  <LanguageSupport
                    navigation={this.props.navigation}
                    id='languageSupport'
                    languageTranslation={this.handleLangueChange}

                  />
                </ClickAwayListener>

              )}
            </ListItem>


            <ListItem key="mobileLang" button className={`${classes.listItems} `}
              onClick={() => this.props.navigation.navigate('LoginWithStyles')}   >
              <Box>
                <Typography style={{
                  color: '#102126',
                  fontSize: '20px',
                  fontWeight: 500,
                }}>{t('login')}</Typography>

              </Box>
              <Box >
                <img src={downArrow} alt='downArrow' />
              </Box>
            </ListItem>
            <Button className={`${classes.secondaryBtn}`}>{t('contactUs')}</Button>






          </List>
        </div>}
      </Translation>);

    return (
      <Translation>
        {t => <>
          <Box className={classes.headerTop}>
            <Container>
              <Box>
                <img style={{ cursor: "pointer" }} src={logo} alt="wecomm image" onClick={() => this.props.navigation.navigate('Home')} />
              </Box>
            </Container>
            {this.state.token && this.renderHeaderAvatar()}
            {window.innerWidth > 500 && <ListItem className={`${isPricing ? classes.languageDropwnActive : classes.languageDropdown}`}>
              <Box className={this.props.classes.languageDropdownContainer}>
                <img src={lanugage} alt="language" />


              </Box>
              <Box style={{ cursor: "pointer" }} onClick={this.handleLanguageDropDown}>
                <img src={downArrow} alt='downArrow' />
              </Box>

              {this.state.languageDropwn && (
                <ClickAwayListener onClickAway={this.closeLanguageDrowdown}>
                  <LanguageSupport
                    navigation={this.props.navigation}
                    id='languageSupport'
                    languageTranslation={this.handleLangueChange}

                  />
                </ClickAwayListener>
              )}

            </ListItem>
            }
          </Box>
          <div>
            <AppBar position="static" className={`${isPricing ? classes.pricingBackground : isProduct ? classes.productBackground : classes.navbar}`}>
              <Container className={classes.lowerHeader}>
                {this.props.showEditProfileHeading ? <Typography variant="h5" className={classes.editProfileHeadingText}>
                  {t('editProfile')}
                </Typography> : null}
                <Toolbar className={classes.toolbarStyle}>
                  <Hidden mdUp>
                    <IconButton
                      aria-label="Open drawer"
                      edge="start"
                      onClick={this.handleDrawerToggle}

                    >
                      <span className={classes.navItem}>
                        <MenuIcon style={{ color: isPricing ? 'white' : 'inherit' }} />
                      </span>
                    </IconButton>
                  </Hidden>

                  <Hidden smDown>
                    <List component="nav" style={{ fontFamily: "Poppins", cursor: 'pointer' }} className={classes.navList}>
                      <ListItem className={`${isPricing ? classes.listItemsActiveColor : classes.listItems} `}>
                        <Box>
                          <ListItemText primary={t('products')} onClick={this.handleProductDropwn} className={`${classes.navItem} ${classes.listitemText}`} style={isPricing ? { color: 'white' } : isProduct ? { color: "#102126", fontFamily: "Poppins" } : {}} />
                          {this.state.isDropwDownVisible && this.renderProductDropdown()}
                          {isProduct && <div style={{ width: '100%', height: '2px', background: "#102126" }} className={classes.activeIndicator} />}
                        </Box>
                        <Box>
                          {isPricing ? <img onClick={this.handleProductDropwn} alt="" src={whiteDownArrow} />
                            : <img onClick={this.handleProductDropwn} src={downArrow} alt='downArrow' />}
                        </Box>
                      </ListItem>
                      <ListItem button className={`${isPricing ? classes.listItemsActiveColor : classes.listItems} `}>
                        <Box>
                          <ListItemText primary={t('pricing')} onClick={this.goToPricing} className={`${classes.navItem} ${classes.listitemText} ${isPricing && classes.activeMenuColor}`} style={isPricing ? { color: 'white' } : {}} />
                          {isPricing && <div style={{ width: '100%', height: '2px', background: 'white' }} className={classes.activeIndicator} />}
                        </Box>

                      </ListItem>
                      <ListItem button className={`${isPricing ? classes.listItemsActiveColor : classes.listItems} `} onClick={() => this.props.navigation.navigate('Contactus')} >
                        <Box>
                          <ListItemText primary={t('contactUs')} className={`${classes.navItem} ${classes.listitemText}`} style={isPricing ? { color: 'white' } : {}} />
                          {isContactUs && <div style={{ width: '100%', height: '2px', background: "#102126" }} className={classes.activeIndicator} />}
                        </Box>
                      </ListItem>


                      {!this.state.token && <ListItem button className={`${isPricing ? classes.listItemsActiveColor : classes.listItems} `} onClick={() => this.props.navigation.navigate('LoginWithStyles')}>
                        <Box>
                          <Typography className={classes.loginText}
                            style={isPricing ? { color: 'white' } : {}}>
                            {t('login')}
                          </Typography>


                        </Box>
                        <Box >
                          {isPricing ? <img alt="" src={whiteDownArrow} /> : <img src={downArrow} alt='downArrow' />}
                        </Box>
                      </ListItem>
                      }


                    </List>
                    {this.props.isHideGetStartButton || this.state.token ? null : <Button onClick={() => this.props.navigation.navigate('EmailAccountRegistration')} className={`${isPricing ? classes.secondaryBtnWhite : classes.secondaryBtn}`}>{t('getStarted')}</Button>}
                  </Hidden>

                </Toolbar></Container >

              {isPricing && (

                <Container>
                  <Box className={classes.marginTalk}>
                    <Typography className={classes.perfectPlan}>{t('findPlan')}</Typography>
                  </Box>
                  <Box className={classes.talkBtnContainer}>
                    <Typography className={classes.perfectPlanSubHeading}>{t('simplyLevrage')}</Typography>
                    <Box style={{ marginLeft: "88px" }}>
                      <Button onClick={() => this.props.navigation.navigate('Contactus')} className={classes.talkBtn}>{t('talkUs')}
                        <span>
                          <img src={arrowRight} alt="" />
                        </span>
                      </Button>
                    </Box>
                  </Box>

                  <Box className={classes.margin103}>


                    {this.state.servicesIds.length > 0 &&
                      <>
                        <Button className={this.props.navigation.getParam('activeTab') === this.state.servicesIds[0].id ?
                          classes.virtual : classes.virtualUnactive}
                          onClick={() => this.props.navigation.navigate('PricingFinal',
                            { activeTab: this.state.servicesIds[0].id })}>{this.state.servicesIds[0].serviceName}</Button>
                        <Button className={this.props.navigation.getParam('activeTab') === this.state.servicesIds[1].id ? classes.virtual : classes.virtualUnactive} onClick={() =>
                          this.props.navigation.navigate('PricingFinal',
                            { activeTab: this.state.servicesIds[1].id })}>{this.state.servicesIds[1].serviceName}</Button>
                        <Button className={this.props.navigation.getParam('activeTab') === this.state.servicesIds[2].id
                          ? classes.virtual : classes.virtualUnactive} onClick={() =>
                            this.props.navigation.navigate('PricingFinal',
                              { activeTab: this.state.servicesIds[2].id })}>{this.state.servicesIds[2].serviceName}</Button>
                      </>
                    }

                  </Box>



                </Container>


              )}

            </AppBar>
            <Hidden mdUp>

              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true
                }}
                PaperProps={{ style: { backgroundColor: '#F6F6F6' } }}
              >
                {drawer}
              </Drawer>

            </Hidden>
            <Hidden mdUp>

              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true
                }}
                PaperProps={{ style: { backgroundColor: '#F6F6F6' } }}
              >
                {drawer}
              </Drawer>

            </Hidden>
          </div>
          {this.props.children}
          <Box className={classes.footerSections}>

            <Container className={classes.footerTop} >
              <Grid container  >

                <Grid item xs={12} sm={12} md={8}>
                  {this.props.isShowSignUpHeading ?
                    <Box style={{ marginBottom: '40px' }}>
                      <Typography className={classes.getStartHeading}>{t('notCustomer')}</Typography>
                      <Typography className={classes.getStartHeading}>{t('signUpToday')}</Typography>
                    </Box> :
                    isProduct ? <Box style={{ marginBottom: '40px' }}>
                      <Typography className={classes.getStartHeading}>{t('stillThinking')}</Typography>
                      <Typography className={classes.getStartHeading}>{t('whichPlan')}</Typography>
                    </Box> :
                      <Box style={{ marginBottom: '40px' }}>
                        <Typography className={classes.getStartHeading}>{t('getStartedToday')}</Typography>
                        <Typography className={classes.getStartParagraph}>Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br /> eiusmod tempor incididunt</Typography>
                      </Box>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Box style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>
                    {this.props.isActiveGetStratedFooterButton ? <Button className={classes.primaryBtn}>{t('getStarted')}</Button> : isProduct ? <>
                      <Button className={classes.primaryBtn} onClick={() => this.props.navigation.navigate('Contactus')}>{t('contactUs')}</Button>
                    </> : <Button className={classes.primaryBtn} onClick={this.getDemo}>{t('getDemo')}</Button>}
                  </Box>
                </Grid>

              </Grid>
            </Container>
            <Divider className={classes.br} />
            <Container className={classes.footerTop}>
              {/* <Grid container >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box>
                    <Typography className={classes.mainFooterHeading}>{t('aboutUs')}</Typography>
                    <Typography className={classes.mainFooterPara}>
                      {t('aboutCompany')}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={12} sm={12} md={2} lg={2}>
                  <Box>
                    <Typography className={classes.listHeading}>{t('products')}</Typography>
                    <Box component={'ul'} className={classes.list}>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={2} lg={2}>
                  <Box>
                    <Typography className={classes.listHeading}>{t('company')}</Typography>
                    <Box component={'ul'} className={classes.list}>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                      <Box component={'li'} className={classes.listItem}>
                        {t('products')}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={2} lg={2}> <Box>
                  <Typography className={classes.listHeading}>{t('quickLinks')}</Typography>
                  <Box component={'ul'} className={classes.list}>
                    <Box component={'li'} className={classes.listItem}>
                      {t('products')}
                    </Box>
                    <Box component={'li'} className={classes.listItem}>
                      {t('products')}
                    </Box>
                    <Box component={'li'} className={classes.listItem}>
                      {t('products')}
                    </Box>
                  </Box>
                </Box>
                </Grid>
              </Grid> */}
              <ContentManagement id='footer' navigation={this.props.navigation} />
            </Container>
            <Divider className={classes.br} />
            <Box className={classes.bottomFooter}>
              <Container>
                <Grid container alignItems='center' justifyContent='space-between'>
                  <Grid item md={6}>
                    <Box component={'ul'} className={classes.socialList}>

                      <AffiliateUrl navigation={{}} id="footer" />

                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box>
                      <Typography className={classes.copyRightText}>
                        {t('copyRight')}
                      </Typography>
                    </Box>
                  </Grid>

                </Grid>
              </Container>
            </Box>

          </Box>
        </>}

      </Translation>
    );
  }
}

export default withStyles(styles)(AppHeader);
