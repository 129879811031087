export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imageCloud = require("../assets/imageCloud.png")
export const agile = require("../assets/agileScalable.svg");
export const cloud = require("../assets/cloudSolution.svg");
export const manage = require("../assets/easyManage.svg");
export const hostel = require("../assets/hostel.svg");
export const professional = require("../assets/prfessionalSupport.svg");
export const quickDeploy = require("../assets/quickDeployment.svg");
export const upfront = require("../assets/upfront.svg");
export const endPointImage = require("../assets/endpointImage.png");
export const dotImage = require("../assets/oval.svg");
export const cisco = require("../assets/Cicsco.svg");
export const grandSteam = require("../assets/grandStream.png");
export const omantel = require("../assets/omantell.svg");
export const polycam = require("../assets/polyCom.svg");
export const snom = require("../assets/snom.svg");
export const unify = require("../assets/unify.svg");
export const yealink = require("../assets/yealink.svg");
export const panasonic = require("../assets/panasonic.svg")
export const basic = require("../assets/telephone.svg");
export const standard = require("../assets/standard.svg");
export const premeium = require("../assets/premium.svg");
export const confrence = require("../assets/confrence.svg");
export const desktop = require("../assets/desktop.svg");
export const laptop =  require("../assets/laptop.svg");
export const mobile = require("../assets/mobile.svg");
export const tick = require("../assets/tick.svg");
export const rightArrow = require("../assets/rightArrow.svg");
export const smartContactSectionOne = require("../assets/smarContactMain.png")
export const smartContactSectionThree = require("../assets/smartContactCenterSectionThree.png")
export const smartSelection =  require("../assets/multipleChecks.png")
export const imageCloudMobile = require("../assets/imageCloudMobile.png")
export const sectionThreeMobile = require("../assets/sectionThreeImage.png")
export const sectionFourMobile = require("../assets/imageAdvantage.png")
