import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    navigation: any;
    closeTermsAndCondition: React.MouseEventHandler<HTMLImageElement>;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    termsAndCondition: {
        id: string;
        title: string;
        content: string;
        meta_title: string;
        meta_keywords: string;
    }
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    termsAndConditionApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            termsAndCondition: {
                id: "",
                title: "",
                content: "",
                meta_title: "",
                meta_keywords: ""
            }
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    async componentDidMount(): Promise<void> {

        this.getTermsAndCondition();
    }




    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallIds = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJsons = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponses = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallIds && responseJsons) {

                if (apiRequestCallIds === this.termsAndConditionApiId) {
                     this.setState({
                         termsAndCondition: responseJsons.data[2]
                     })
                }

            }
            this.parseApiCatchErrorResponse(errorReponses);
        }
    }

    // Customizable Area End





 


    getTermsAndCondition = async() => {
            
        const langkey = await getStorageData('i18nextLng');
        const header = {
            "Content-Type": configJSON.applicationType,
            "Accept-Language": langkey
        };

        const apiEndPoint = `${configJSON.termAndConditionUrl}?locale=${langkey}`;
        const termsAndCondtion = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.termsAndConditionApiId = termsAndCondtion.messageId;

        termsAndCondtion.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        termsAndCondtion.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        termsAndCondtion.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(termsAndCondtion.id, termsAndCondtion);

    }

 

    // Customizable Area End
}
