import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Avatar,
  Badge,
  Grid,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {CloseRounded} from "@material-ui/icons"
import AppHeader from "../../../components/src/AppHeader.web";
import CustomInput from "../../../components/src/CustomInput.web";
import {Translation} from "react-i18next"

import {
  editIcon,
  successCheckedIcon,
  dummyUserImage
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  breakpoints: {
    values:{
      xs: 0,
      sm: 400,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
});
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFormicForm = (t:Function) => {
    return (
      <form onSubmit={this.validateForm} data-testid="formTestID">
        <Grid style={webStyle.innerFormContainer}>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="firstNameTestID"
              label={t('firstName')}
              type="text"
              name="userFirstName"
              placeholder={t('fullName')}
              onChange={this.handleChange}
              value={this.state.userFirstName}
            />
            {this.state.errorsUserFirstName && (
              <Typography style={webStyle.error}>
                {this.parseMessage(this.state.errorsUserFirstName, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="lastNameTestID"
              label={t('lastName')}
              type="text"
              name="userLastName"
              placeholder={t('enterLastName')}
              onChange={this.handleChange}
              value={this.state.userLastName}
            />
            {this.state.errorsUserLastName && (
              <Typography style={webStyle.error}>
                 {this.parseMessage(this.state.errorsUserLastName, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="companyNameTestID"
              label={t('companyName')}
              type="text"
              name="companyName"
              placeholder={t('enterCompanyName')}
              onChange={this.handleChange}
              value={this.state.companyName}
            />
            {this.state.errorsCompanyName && (
              <Typography style={webStyle.error}>
                   {this.parseMessage(this.state.errorsCompanyName, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="jobTitleTestID"
              label={t('jobTitle')}
              type="text"
              name="jobTitle"
              placeholder={t('enterJobTitle')}
              onChange={this.handleChange}
              value={this.state.jobTitle}
            />
            {this.state.errorsJobTitle && (
              <Typography style={webStyle.error}>
                {this.parseMessage(this.state.errorsJobTitle, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="countryNameTestID"
              label={t('country')}
              type="text"
              name="country"
              placeholder={t('enterCountryName')}
              onChange={this.handleChange}
              value={this.state.country}
            />
            {this.state.errorsCountry && (
              <Typography style={webStyle.error}>
                {this.parseMessage(this.state.errorsCountry, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="emailAddressTestID"
              label={t('emailAddress')}
              type="text"
              name="emailAddress"
              placeholder={t('enterEmail')}
              onChange={this.handleChange}
              value={this.state.emailAddress}
            />
            {this.state.errorsEmailAddress && (
              <Typography style={webStyle.error}>
                   {this.parseMessage(this.state.errorsEmailAddress, t) }
              </Typography>
            )}
          </Box>
          <Box style={webStyle.labelInputContainer as React.CSSProperties}>
            <CustomInput
              data-testid="phoneNumberTestID"
              label={t('phoneNumber')}
              type="text"
              name="phoneNumber"
              placeholder={t('enterPhone')}
              onChange={this.handleChange}
              value={this.state.phoneNumber}
            />
            {this.state.errorsPhoneNumber && (
              <Typography style={webStyle.error}>
              {this.parseMessage(this.state.errorsPhoneNumber, t) }
              </Typography>
            )}
          </Box>
        </Grid>
        <Box style={webStyle.formButtonWrapper as React.CSSProperties}>
          <Button variant="outlined" data-test-id="cancelBtn" onClick={this.goToHome} style={webStyle.formCancelButton as React.CSSProperties}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained" type="submit" style={webStyle.formSaveButton as React.CSSProperties}>
            {t('saveChanges')}
          </Button>
        </Box>
      </form>
    );
  };

  renderUploadPhotoModal = (t:Function) => {
    return (
      <Modal data-testid="uploadModalTestID" open={this.state.showUplodImageModal}>
        <Box style={webStyle.modalMainBox}>
          <Box style={webStyle.innerModalBox as React.CSSProperties}>
            <Box style={webStyle.headingWrapper}>
              <Typography
                variant="h5"
                style={webStyle.editHeadingTypography as React.CSSProperties}>
                {t('modalEditProfileHeading')}
              </Typography>
              <CloseRounded
                data-testid="uploadModalCloseIcon"
                style={webStyle.closeRoundIcon}
                onClick={this.handleCloseUploadImageModal}
              />
            </Box>
            <Avatar style={webStyle.profileImageMainModalAvatar}  src={ this.state.showImage || dummyUserImage}>
              {/* <img
                alt="user profile image"
                src={ this.state.showImage || dummyUserImage}
                style={webStyle.profileImageModalAvatarImage}
              /> */}
            </Avatar>
            <Box style={webStyle.modalButtonWrapper as React.CSSProperties}>
              <input
                data-testid="inputFileElementTestID"
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={this.handleChangeImage}
                style={webStyle.inputFile}
              />
              <label htmlFor="file">
                <Typography style={webStyle.uploadImageButton}>
                  {t('modalUploadButton')}
                </Typography>
              </label>
              <Button
                data-testid="removeImageButtonTestID"
                style={webStyle.uploadCancelButton as React.CSSProperties} onClick={this.handleRemoveImage}>
                {t('modalRemoveButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderSuccessModal = (t:Function) => {
    return (
      <Modal data-testid="successModalTestID" open={this.state.showSubmitSuccessModal}>
        <Box style={webStyle.modalMainBox}>
          <Box style={webStyle.innerModalBox as React.CSSProperties}>
            <Typography>
              <img
                src={successCheckedIcon}
                alt="success check icon"
                style={webStyle.checkIconWrapper} />
            </Typography>
            <Typography style={webStyle.modalSubmitText}>
              {t('submitSuccessfullText')}
            </Typography>
            <Button
              data-testid="succesOkButtonTestID"
              variant="contained"
              color="primary"
              style={webStyle.formSaveButton as React.CSSProperties}
              onClick={this.handleSuccessModalClose}
            >
              {t('ok')}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Translation>
        {t =>   <ThemeProvider theme={theme}>
        <AppHeader
          showEditProfileHeading={true}
          showHeaderAvatar={true}
          userImage={this.state.headerUserImage}
          isHideGetStartButton={true}
          isShowSignUpHeading={true}
          isActiveGetStratedFooterButton={true}
          navigation={this.props.navigation}
        >
          <Box style={webStyle.mainUserProfileContainer} >
            <Box style={webStyle.innerContainer}>
              <Box style={webStyle.userNameImageWrapper}>
                <Badge
                  data-testid="openUploadModalBadgeTestID"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Avatar
                      data-testid="openUploadModalButtonTestID"
                      style={webStyle.editIconMainAvatar}
                      onClick={this.handleOpenUploadImageModal}
                      src={editIcon}
                    >
                      {/* <img
                        alt="edit image icon"
                        src={editIcon}
                        style={webStyle.editIconAvatarImage}
                      /> */}
                    </Avatar>
                  }
                >
                  <Avatar
                    style={webStyle.profileImageMainAvatar}
                     src={this.state.showImage || dummyUserImage}
                  >
                    {/* <img
                      alt="user profile image"
                      src={this.state.showImage || dummyUserImage}
                      style={webStyle.profileImageAvatarImage}
                    /> */}
                  </Avatar>
                </Badge>
                <Typography variant="h5">{this.state.userFullName}</Typography>
              </Box>
              {this.renderFormicForm(t)}
            </Box>
            {this.renderUploadPhotoModal(t)}
            {this.renderSuccessModal(t)}
          </Box>
        </AppHeader>
      </ThemeProvider>}
      </Translation>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  mainUserProfileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "42px 0 160px 0"
  },

  innerContainer: {
    width: "965px",
    padding: "0 5px"
  },

  userNameImageWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginBottom: "65px"
  },

  profileImageMainAvatar: {
    width: "116px",
    height: "116px",
    border:"4px solid #023B86",
    background: 'url(<path-to-image>), lightgray 50% / cover no-repeat'
  },

  profileImageAvatarImage: {
    objectFit:"cover",
  
  },

  editIconMainAvatar: {
    width: "28px",
    height: "28px",
    backgroundColor: "#DFE8ED",
    cursor: "pointer"
  },

  editIconAvatarImage: {
    width: "16px",
    height: "16px"
  },

  innerFormContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "40px 48px",
    "@media (max-width: 450px)": {
      gridTemplateColumns: "1fr"
    }
  },

  twoInputFieldContainer: {
    display: "flex",
    gap: "48px",
    justifyContent: "space-between"
  },

  labelInputContainer: {
    display: "flex",
    flexDirection: "column"
  },

  formLabelText: {
    color: "#102126",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "14px"
  },

  formicInputField: {
    color: "red",
    maxWidth: "800px"
  },

  formButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "50px",
    flexWrap: "wrap",
    paddingTop: "128px",
    "& .MuiButton-root": {
      width: "200px",
      textTransform: "capitalize",
      fontSize: "20px",
      fontWeight: 700
    }
  },

  formCancelButton: {
    border: "1px solid #012677",
    width: "209px",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 700,
    "@media (max-width: 450px)": {
      width: "100%"
    }
  },

  formSaveButton: {
    width: "209px",
    background: "#012677",
    color: "white",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 700,
    "&:hover": {
      background: "#012677"
    },
    "@media (max-width: 450px)": {
      width: "100%"
    }
  },

  error: {
    color: "red"
  },

  modalMainBox: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  innerModalBox: {
    backgroundColor: "white",
    padding: "30px 50px 50px 50px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "26px"
  },

  headingWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },

  editHeadingTypography: {
    flexGrow: 1,
    textAlign: "center"
  },

  closeRoundIcon: {
    width: "16px",
    marginTop: "6px",
    cursor: "pointer"
  },

  profileImageMainModalAvatar: {
    width: "200px",
    height: "200px",
    border: "4px solid #023B86",
    background: 'url(<path-to-image>), lightgray 50% / cover no-repeat'
    // background: "linear-gradient(to bottom, #023B86, #1BC0FF, #1BC0FF)"
  },

  profileImageModalAvatarImage: {
    // width: "96.5%",
    // height: "96.5%",
    borderRadius: "50%"
  },

  modalButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .MuiButton-root": {
      width: "300px"
    }
  },

  checkIconWrapper: {
    color: "#38e685",
    fontSize: "80px"
  },

  modalSubmitText: {
    fontSize: "25px"
  },

  inputFile: {
    display: "none"
  },

  uploadImageButton: {
    width: "300px",
    height: "40px",
    background: "#012677",
    borderRadius: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },

  uploadCancelButton: {
    width: "300px",
    border: "1px solid #012677",
    textTransform: "capitalize"
  }
};

export {CustomisableUserProfiles};
// Customizable Area End
