import React from "react";

import {
  Box,
  Button,
  Typography,
 // Customizable Area Start
  Grid,
  Hidden,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, Theme, withStyles, createStyles } from "@material-ui/core/styles";
import { loginBanner, topImage } from "./assets";
import CustomInput from "../../../components/src/CustomInput.web";
import SuccessModal from "../../../components/src/SuccessModal.web";
import {Translation} from "react-i18next"

import { Formik, Form } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif', // Replace 'Poppins' with the actual font name
    // You can also specify font weights and other typography styles here
    h3: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '60px',
    },
    // Add other typography elements as needed
  },
});

const styles = (theme: Theme) => createStyles({
  formBackgroundColor: {
    backgroundColor: '#F6F6F6',
    backgroundImage: `url(${topImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -200px',
    height:'100%'
  },
  linearGradient: {
    background: 'linear-gradient(180deg, #024EAF 14.18%, #1BC0FF 130.33%)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative' as 'relative',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '32px',
  },
  createHeadings: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '60px',
    paddingTop: '96px',
  },
  createForms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth:'573px',
    margin: 'auto',
    height:'100%',
    

  },
  subHeadingss: {
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '30px',
    marginTop: '12px',
    color: '#102126',
  },
  submitBtn: {
    borderRadius: '10px',
    background: '#012677',
    width: '100%',
    padding: '12px 0',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none' as 'none',
    marginTop:'30px',
    lineHeight: '36px',
    '&:hover': {
      background: '#012677',
      color: '#fff',
    },
  },

  errorClass: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#C30A2A',
  },

  viewPortHeights:{
    minHeight:'100%'
  },

  "@media (max-width: 600px)": {
    createHeadings: {
      fontSize: "30px", // Adjust the font size for smaller screens
      lineHeight: "45px",
    },
    createForms: {
      padding: "20px 20px", // Adjust the padding for smaller screens
    },
    linearGradient: {
      display: 'none'
    },
    formBackgroundColor:{
      height:'auto',
    }

   
},


});


// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "././ForgotPasswordController";

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  EmailField =  (t:Function)=>{
    const classes = this.props.classes
     return (

      <>
        <Box style={{width:'100%'}}>
         <Typography variant='h3' className={classes.createHeadings}>{t('forgotPassword')}</Typography>
         <Typography variant='h5' className={classes.subHeadingss}>
           {t('link')}
         </Typography>
       </Box>
      <Formik
      data-test-id='formik'
      enableReinitialize
      validateOnBlur={true}
      initialValues={{
       emailAddress: ''
      }}
      onSubmit={(values) => {
         this.sendOtp(values)
      }}
      validationSchema={()=> this.ResetPasswordSchema(t)}
    >
      {
        ({ errors, values,  handleChange, touched, setFieldValue }) => {
          return (

            <Form style={{width:'100%'}}>


              <Box className={classes.fieldContainer}>
            
                <Box>
                  <CustomInput
                    label={t('emailAddress')}
                    type='text'
                    name="emailAddress"
                    placeholder={t('enterEmail')}
                    onChange={handleChange}
                    value={values.emailAddress}
                  />
                     {
                    Boolean(touched.emailAddress && errors.emailAddress) && (
                      <Typography className={classes.errorClass}>{errors.emailAddress}</Typography>
                    )
                  }
                 {this.state.errorModal &&  <Typography className={classes.errorClass}>{this.state.errorMessage}</Typography> }
                 
                  </Box>
            
           
               <Box style={{maxWidth:'573px'}}>
                  <Button type="submit"
                    className={classes.submitBtn}
                    color="primary"
                    data-test-id="submitBtn"
                    >
                    {t('reset')}
                  </Button>
                </Box>
              
              </Box>

            </Form>
          )
        }
      }
    </Formik>
    <SuccessModal 
     handelCloseModal={this.handleCloseModal} 
    showSubmitSuccessModal={this.state.showSuccessModal}  
    successMessage={t('otpSent')} 
    />
    </>

     )
  }
  OtpField = (t:Function)=>{
    const classes = this.props.classes
    return (
      <>
        <Box style={{width:'100%'}}>
         <Typography variant='h3' className={classes.createHeadings}>{t('forgotPassword')}</Typography>
         <Typography variant='h5' className={classes.subHeadingss}>
           {t('otpSent')}
         </Typography>
       </Box>
    
     <Formik
     data-test-id='formik_otp'
     enableReinitialize
     validateOnBlur={true}
     initialValues={{
       otp:''
     }}
     onSubmit={(values) => {
        this.otpConfirmation(values)
     }}
     validationSchema={()=> this.OtpSchema(t)}
    
   >
     {
       ({ errors, values,  handleChange, touched, setFieldValue }) => {
         return (

           <Form style={{width:'100%'}}>
            <Box className={classes.fieldContainer}>
           
               <Box>
                 <CustomInput
                   label={t('otp')}
                   type='text'
                   name="otp"
                   placeholder={t("enterOtp")}
                   onChange={handleChange}
                   value={values.otp}
                 />
                    {
                   Boolean(touched.otp && errors.otp) && (
                     <Typography className={classes.errorClass}>{errors.otp}</Typography>
                   )
                 }
                 
                 {this.state.errorModal &&  <Typography className={classes.errorClass}>{this.state.errorMessage}</Typography> }


                 </Box>
           
          
              <Box style={{maxWidth:'573px'}}>
                 <Button type="submit"
                   className={classes.submitBtn}
                   color="primary"
                   data-test-id="submitBtn"
                   >
                   {t('submit')}
                 </Button>
               </Box>
             
             </Box>

           </Form>
         )
       }
     }
   </Formik>
   <SuccessModal 
     handelCloseModal={this.handleCloseModalOtp} 
    showSubmitSuccessModal={this.state.otpVerifiedModal}  
    successMessage={t('optVerified')} 
    />
   </>
   )
  }
  PasswordField = (t:Function)=>{
    const classes = this.props.classes
    return (
      <>
        <Box style={{width:'100%'}}>
         <Typography variant='h3' className={classes.createHeadings}>{t('resetPassword')}</Typography>
         <Typography variant='h5' className={classes.subHeadingss}>
           {t('chooseYourNewPassword')}
         </Typography>
       </Box>
    
     <Formik
     data-test-id='formik'
     enableReinitialize
     validateOnBlur={true}
     initialValues={{
       password:'',
       confirmPassword:''
     }}
     onSubmit={(values) => {
        this.passwordChangeAfterOtp(values)
     }}
     validationSchema={()=> this.NewPasswordSchema(t)}
   >
     {
       ({ errors, values,  handleChange, touched, setFieldValue }) => {
         return (

           <Form style={{width:'100%'}}>
            <Box className={classes.fieldContainer}>
           
               <Box>
                 <CustomInput
                   label={t('newPassword')}
                   type='password'
                   name="password"
                   placeholder={t("enterNewPassword")}
                   onChange={handleChange}
                   value={values.password}
                 />
                    {
                   Boolean(touched.password && errors.password) && (
                     <Typography className={classes.errorClass}>{errors.password}</Typography>
                   )
                 }

                 </Box>


                 <Box>
                 <CustomInput
                   label={t('confirmPassword')}
                   type='password'
                   name="confirmPassword"
                   placeholder={t('confirmNewPassword')}
                   onChange={handleChange}
                   value={values.confirmPassword}
                 />
                    {
                   Boolean(touched.confirmPassword && errors.confirmPassword) && (
                     <Typography className={classes.errorClass}>{errors.confirmPassword}</Typography>
                   )
                 }

                 {this.state.errorModal &&  <Typography className={classes.errorClass}>{errors.confirmPassword}</Typography> }

                 </Box>
           
          
              <Box style={{maxWidth:'573px'}}>
                 <Button type="submit"
                   className={classes.submitBtn}
                   color="primary"
                   data-test-id="submitBtn"
                   >
                   {t('submit')}
                 </Button>
               </Box>
             
             </Box>

           </Form>
         )
       }
     }
   </Formik>
   <SuccessModal 
     handelCloseModal={this.handleCloseModalPassowrdChange} 
    showSubmitSuccessModal={this.state.passwordChangeModal}  
    successMessage={t('changedSuccessfully')} 
    />
   </>
   )
  }


  renderCorrectForm = (t:Function) => {
    if (this.state.enableOtpField) {
      return this.OtpField(t);
    }
    else if (this.state.otpVerified) {
      return this.PasswordField(t);
    }
    
      return this.EmailField(t);
    

  }


  // Customizable Area End

  render() {

    const { classes } = this.props;
  
    return (
      // Customizable Area Start
      <Translation>
        {t =>  <ThemeProvider theme={theme}>
      <Box className={classes.viewPortHeights}>
      <Grid container className={classes.viewPortHeights} >

 <Hidden smDown> <Grid item xs={12} sm={12} md={6} >
   <Box className={classes.linearGradient}>
    <Box> <img src={loginBanner} alt='' width={'100%'} /></Box>

   </Box>

 </Grid></Hidden>

 <Grid item xs={12} sm={12} md={6}>

   <Box className={classes.formBackgroundColor}>


     <Box className={classes.createForms}>

      {this.renderCorrectForm(t)}
     </Box>

     {/* <SuccessModal 
     handelCloseModal={()=>this.setState({errorModal:false})} 
    showSubmitSuccessModal={this.state.errorModal}  
    successMessage={this.state.errorMessage} 
    /> */}

   </Box>
 </Grid>

</Grid>
      </Box>
      </ThemeProvider> }
      </Translation>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(ForgotPassword);

// Customizable Area End
