Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CarouselDisplay";
exports.labelBodyText = "CarouselDisplay Body";
exports.btnExampleTitle = "CLICK ME";
exports.seeMore = "See More";
exports.readMore = "Read More";
exports.readLess = "Read Less";
// Customizable Area End