Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.apiContentType = "application/json"

exports.btnExampleTitle = "CLICK ME";
exports.cardHeading = "Virtual PBX";
exports.cardText = "Virtual PBX offered by Omantel gives your business a complete freedom from traditional PBX solution with flexibility of scaling-up or down as per your business requirement along with pay as you go model";
exports.seeMore = "See More";
exports.mainHeading = "Enhanced Cloud";
exports.communication = "Communication";
exports.services = " Services";
exports.mainHeading_2 =  "WeCom provides high quality and cost effective IT Solutions through technological synergy. With the vast experience in integrating diverse technologies, WeCom identifies and address to the requirements of the Region including IP Telephony, Unified Communication and Contact Center Solutions."
exports.seePricing = "See pricing";
exports.getDemo = "Get a demo";
exports.anyTime = "Anytime";
exports.anywhere = "Anywhere";
exports.whyChooseUs = "Why Choose Us ?";
exports.oneOfBest = "One of the best";
exports.isp = " ISP Class product";
exports.partners = "Our Strategic Partners";
exports.landingPageUrl = "bx_block_content_management/landing_pages"







// Customizable Area End