import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Hidden,
  Container
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, Theme, withStyles, createStyles } from "@material-ui/core/styles";
import { topBackgroundImage, onlineTech, checkedBox, unchecked, userImage, editIcon } from "./assets";
import CustomInput from "../../../components/src/CustomInput.web";
import SuccessModal from "../../../components/src/SuccessModal.web";
import { Formik, Form } from "formik";
import TermsAndConditions from "./TermsAndConditions.web"
import {Translation} from "react-i18next"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif', // Replace 'Poppins' with the actual font name
    // You can also specify font weights and other typography styles here
    h3: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '60px',
    },
    // Add other typography elements as needed
  },
});

const styles = (theme: Theme) => createStyles({
  formBackgroundColor: {
    backgroundColor: '#F6F6F6',
    backgroundImage: `url(${topBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -200px',
    height: '100%'
  },
  linearGradient: {
    background: 'linear-gradient(180deg, #024EAF 14.18%, #1BC0FF 130.33%)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative' as 'relative',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '32px',
  },
  createHeading: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '60px',
    paddingTop: '96px',
  },
  createForm: {

    maxWidth: '573px',
    margin: 'auto',
  },
  subHeading: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
    marginTop: '12px',
    color: 'rgba(16, 33, 38, 0.60)',
  },
  agreeInfoBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  agreeInfo: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.60)',
    cursor: "pointer"
  },
  createAccBtn: {
    borderRadius: '10px',
    background: '#012677',
    width: '100%',
    padding: '12px 0',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    '&:hover': {
      background: '#012677',
      color: '#fff',
    },
  },
  alreadyAccText: {
    color: '#102126',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
  },
  error: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#C30A2A',
  },
  loginText: {
    textDecoration: 'underline',
    color: '#000',
    cursor: 'pointer'
  },
  alreadyAcc: {
    display: 'flex',
    justifyContent: 'center'
  },
  agreeAll: {
    textDecoration: 'underline',
    fontWeight: 500
  },
  viewPortHeight: {
    minHeight: '100%'
  },
  createAccountBg: {
    backgroundColor: "#f6f6f6",
    height: "100%"
  },
  createAccounContainer: {
    display: 'flex',
    gap: "22px",
    alignItems: 'center',
  },
  createAccountHeading: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  createAccountSubHeading: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
  },
  createAccountBox: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  createAccountInputBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    marginTop: '59px'
  },

  saveAccBtn: {
    borderRadius: '10px',
    background: '#012677',
    width: '100%',
    padding: '12px 0',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    '&:hover': {
      background: '#012677',
      color: '#fff',
    },
  },
  cancelBtn: {
    borderRadius: '10px',
    background: '#f6f6f6',
    width: '100%',
    padding: '12px 0',
    fontSize: '24px',
    fontWeight: 500,
    color: '#102126',
    textTransform: 'none' as 'none',
    border: "1px solid #012677",
    lineHeight: '36px',
    '&:hover': {
      background: '#f6f6f6',
      color: '#102126',
    },
  },

  cancelBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '26px',
    marginTop: '128px',
    alignItems: 'center'
  },
  createAccountMainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editImageBox: {
    width: "116px",
    height: "118px",
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  editInnerBox: {
    position: 'absolute',
    bottom: '0px',
    right: '4px',
    background: '#DFE8ED',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  "@media (max-width: 600px)": {
    createHeading: {
      fontSize: "30px", // Adjust the font size for smaller screens
      lineHeight: "45px",
    },
    createForm: {
      padding: "20px 20px", // Adjust the padding for smaller screens
    },
    linearGradient: {
      display: 'none'
    },
    createAccountBg: {
      height: "auto"
    },
    createAccountBox: {
      flexDirection: 'column',
      gap: "22px"

    },
    cancelBtnContainer: {
      marginTop: '22px'
    },
    cancelBtn: {
      fontSize: "15px"
    },
    saveAccBtn: {
      fontSize: "15px !important"
    }

  },



});


// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "././EmailAccountRegistrationController";




class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  renderSignUpForm = (t:Function) => {

    const classes = this.props.classes
    return (

      
      <>

        <Box className={classes.viewPortHeight}>
          <Grid container className={classes.viewPortHeight} >

            <Hidden smDown> <Grid item xs={12} sm={12} md={6} >
              <Box className={classes.linearGradient}>
                <Box style={{ position: 'absolute', top: '46px', }}> <img src={onlineTech} alt='' width={'100%'} /></Box>

              </Box>

            </Grid></Hidden>

            <Grid item xs={12} sm={12} md={6}>

              <Box className={classes.formBackgroundColor}>


                <Box className={classes.createForm}>

                  <Box>
                    <Typography variant='h3' className={classes.createHeading}>{t('createAccount')}</Typography>
                    <Typography variant='h5' className={classes.subHeading}>
                      {t('getStartedWithAdditional')}<br />{t('benfits')}
                    </Typography>
                  </Box>

                  <Formik
                    data-test-id='formik'
                    enableReinitialize
                    validateOnBlur={true}
                    initialValues={{
                      ...this.state.initialValues,
                    }}
                    onSubmit={(values) => {
                      this.getSignUpData(values)
                    }}
                    validationSchema={()=> this.FormSchema(t)}
                  >
                    {
                      ({ errors, values, handleChange, touched, setFieldValue }) => {
                        return (

                          <Form>


                            <Box className={classes.fieldContainer}>
                              <Box>
                                <CustomInput
                                  label={t("name")}
                                  type='text'
                                  name="name"
                                  placeholder={t('fullName')}
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.updateStateWithFieldValues({ name: e.target.value });
                                  }}
                                  value={values.name}
                                />
                                {
                                  Boolean(touched.name && errors.name) && (
                                    <Typography className={classes.error}>{errors.name}</Typography>
                                  )
                                }
                              </Box>
                              <Box>
                                <CustomInput
                                  label={t('emailAddress')}
                                  type='text'
                                  name="emailAddress"
                                  placeholder={t("enterEmail")}
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.updateStateWithFieldValues({ emailAddress: e.target.value });
                                  }}
                                  value={values.emailAddress}
                                />

                                {
                                  Boolean(touched.emailAddress && errors.emailAddress) && (
                                    <Typography className={classes.error}>{errors.emailAddress}</Typography>
                                  )
                                }

                                {this.state.EmailAlreadyExists && (
                                  <Typography className={classes.error}>{t('alreadyExists')}</Typography>
                                )}
                                {this.state.errorMessage && (
                                  <Typography className={classes.error}>{this.state.errorMessage}</Typography>
                                )}

                              </Box>
                              <Box>
                                <CustomInput
                                  label={t("createPassword")}
                                  type='password'
                                  name="password"
                                  placeholder={t("createPassword")}
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.updateStateWithFieldValues({ password: e.target.value });
                                  }}
                                  value={values.password}

                                />

                                {Boolean(touched.password && errors.password) && (
                                  <Typography className={classes.error}>{errors.password}</Typography>
                                )
                                }
                              </Box>
                              <Box>
                                <CustomInput
                                  label={t('confirmPassword')}
                                  type='password'
                                  name="confirmPassword"
                                  placeholder={t('confirmPassword')}
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.updateStateWithFieldValues({ confirmPassword: e.target.value });
                                  }}
                                  value={values.confirmPassword}

                                />

                                {Boolean(touched.confirmPassword && errors.confirmPassword) && (
                                  <Typography className={classes.error}>{errors.confirmPassword}</Typography>
                                )
                                }

                              </Box>

                              <Box className={classes.agreeInfoBox}>
                                <Box>
                                  <img src={values.acceptTerms ?
                                    checkedBox : unchecked}
                                    alt='checkbox'
                                    onClick={() => {
                                      this.updateStateWithFieldValues({ acceptTerms: !values.acceptTerms })
                                      setFieldValue('acceptTerms', !values.acceptTerms)
                                    }}
                                    data-test-id="acceptTerms"
                                  />
                                </Box>
                                <Typography
                                  data-test-id="termsAndCondition"
                                  onClick={() => this.handleTerms()}
                                  className={classes.agreeInfo}>
                                  {t('agreeAll')} <span className={classes.agreeAll}>
                                    {t('termsCondition')}</span>
                                </Typography>
                              </Box>
                              {Boolean(touched.acceptTerms && errors.acceptTerms) && (
                                <Typography className={classes.error}>{errors.acceptTerms}</Typography>
                              )
                              }
                              <Box style={{ maxWidth: '573px' }}>
                                <Button type="submit"
                                  className={classes.createAccBtn}
                                  color="primary"
                                  data-test-id="submitBtn"
                                >
                                  {t('createAccount')}
                                </Button>
                              </Box>
                              <Box className={classes.alreadyAcc}>
                                <Typography className={classes.alreadyAccText}>
                                  {t('alreadyAccount')}
                                  <span data-test-id="gotoLogin" className={classes.loginText} onClick={this.goToLogin}>{t('login')}</span> </Typography>
                              </Box>
                            </Box>

                          </Form>
                        )
                      }
                    }
                  </Formik>


                  <SuccessModal
                    handelCloseModal={this.handleCloseModal}
                    showSubmitSuccessModal={this.state.showModal}
                    successMessage={t('accountCreatedSuccessfully')}
                  />

                </Box>



              </Box>
            </Grid>

          </Grid>
        </Box>


      </>
    )
  }





  renderCreateAccount = (t:Function) => {
    const classes = this.props.classes
    return (

      <>
        <Box className={classes.createAccountBg}>
          <Container>
            <Box className={classes.createAccounContainer}>
              {/* <Box>
                <img src={backArrow} alt="backarrow" />
              </Box> */}

              <Box>
                <Typography className={classes.createAccountHeading}>
                {t('createProfile')}
                </Typography>
                <Typography className={classes.createAccountSubHeading}>
                  {t('createMessage')}
                </Typography>
              </Box>

            </Box>

            <Box className={this.props.classes.createAccountMainBox}>

              <Box className={this.props.classes.editImageBox}>

                <img
                  height={56}
                  width={56}
                  style={{ objectFit: 'cover' }}
                  src={this.state.userImage || userImage} alt=""
                />

                <Box
                  className={this.props.classes.editInnerBox}
                  onClick={this.handleEditImageClick}
                >
                  <img height={16} width={16} src={editIcon} alt="" />
                </Box>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={this.fileInputRef}
                  onChange={this.handleImageChange}
                />
              </Box>





            </Box>
            <Formik

              enableReinitialize
              validateOnBlur={true}
              initialValues={{
                firstName: this.state.initialValues.name.split(' ')[0] || "",
                lastName: this.state.initialValues.name.split(' ')[1] || "",
                companyName: "",
                jobTitle: "",
                country: "",
                emailAddress: this.state.initialValues.emailAddress || "",
                phone: "",
              }}
              onSubmit={(values) => {
                console.log(values)
                this.createProfile(values)
              }}
              validationSchema={this.CreateAccountSchema}

            >

              {
                ({ errors, values, handleChange, touched, setFieldValue }) => {
                  return (
                    <Form>
                      <Box className={classes.createAccountInputBox}>
                        <Box className={classes.createAccountBox}>
                          <CustomInput name="firstName"
                            label="First Name"
                            placeholder="Enter first Name"
                            value={values.firstName}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.firstName && errors.firstName)}
                            errorMessage={errors.firstName}
                          />
                       
                          <CustomInput name="lastName"
                            label="Last Name"
                            placeholder="Enter Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.lastName && errors.lastName)}
                            errorMessage={errors.lastName}
                          />
                     

                        </Box>

                        <Box className={classes.createAccountBox}>
                          <CustomInput name="companyName"
                            label="Company Name"
                            placeholder="Enter Company Name"
                            value={values.companyName}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.companyName && errors.companyName)}
                            errorMessage={errors.companyName}
                          />
                    
                          <CustomInput
                            name="jobTitle"
                            label="Job Title"
                            placeholder="Enter Job Title"
                            value={values.jobTitle}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.jobTitle && errors.jobTitle)}
                            errorMessage={errors.jobTitle}
                          />

                    
                        </Box>

                        <Box className={classes.createAccountBox}>
                          <CustomInput name="country"
                            label="Country"
                            placeholder="Enter country name"
                            value={values.country}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.country && errors.country)}
                            errorMessage={errors.country}
                          />
                      
                          <CustomInput name="emailAddress"
                            label="Email Address"
                            placeholder="Enter Email address"
                            value={values.emailAddress}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.emailAddress && errors.emailAddress)}
                            errorMessage={errors.emailAddress}
                            disabled={true}
                          />

                        </Box>

                        <Box className={classes.createAccountBox}>
                          <CustomInput
                            name="phone"
                            label="Phone Number"
                            placeholder="Enter phone number"
                            value={values.phone}
                            onChange={handleChange}
                            type="text"
                            flexGrow={1}
                            error={Boolean(touched.phone && errors.phone)}
                            errorMessage={errors.phone}
                          />

                          <Box style={{
                            maxWidth: '573px',
                            flexGrow: 1
                          }}>

                          </Box>

                        </Box>
                      </Box>

                      <Box className={classes.cancelBtnContainer}>

                        <Box style={{ width: "209px" }}>
                          <Button className={classes.cancelBtn}
                            onClick={() => this.props.navigation.navigate('LoginWithStyles')}>
                            {configJSON.cancel}
                          </Button>
                        </Box>
                        <Box style={{ width: "209px" }}>
                          <Button className={classes.saveAccBtn} data-test-id="createAccount" type="submit">
                            {configJSON.saveChanges}
                          </Button>
                        </Box>

                      </Box>

                    </Form>
                  )
                }
              }

            </Formik>

            <SuccessModal
              handelCloseModal={this.handleCreateProfileModal}
              showSubmitSuccessModal={this.state.createProfileModal}
              successMessage={configJSON.profileCreate}
            />


          </Container>










        </Box>


      </>




    )



  }


  renderForm = (t:Function) => {
    if (this.state.termAndConditionOpen) {
      return (<TermsAndConditions id={"termsAndCondition"}
        navigation={undefined}
        closeTermsAndCondition={this.handleTerms} />
      )
    }
    else if (this.state.createAccount) {

      return this.renderCreateAccount(t);
    }
    else {
      return this.renderSignUpForm(t);
    }
  }


  // Customizable Area End

  render() {


    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Translation>
          {t=> this.renderForm(t)}
        </Translation>
       
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(styles)(EmailAccountRegistration);



// Customizable Area End
