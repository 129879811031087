import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  tableOpenState: any[];
  selectedPlan: string;
  selectedPlanId: string;
  selectedCurrency: string;
  virutalPbx: string;
  planId: {
    monthlyId: {id:string, visiblity:boolean | undefined},
    anuallyId: {id:string, visiblity:boolean | undefined}
  } | undefined;
  availablePlans: {

    name: string
    price: string,
    content: string,
    planId: string,
    visiblity: string,
    discountAmount: string,
    previousAmount: string,
    priceContent:string,
    features: {
      id: string,
      type: string,
      attributes: {
        name: string,
        status: string,
        visiblity: boolean
      }
    }[]


  }[] | null,
  voiceTable: any,
  unifiedTable: any;
  unifiedInstant: any;
  userRange: {id:string, userRange:string, min:string, max:string, visiblity:boolean} [];
  sliderValue: number;
  selectedRange: string;
  noPlans: string | null;
  zoomLevel:number,
  servicesIds:string[],
  currentActiveTab:null | string,
  previousActiveTab: null | string,
  loading: boolean


  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  serviceApiId: string = "";
  planApiId: string = "";
  getPricingApiId: string = "";
  userRangeApiId:string = "";  
  timer:any;
  langKey:any;
  

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      tableOpenState: [
           { id: "1", open: false },
          { id: "2", open: false },
           {id:"3", open:false}
      ],
       selectedPlan: 'anually',
      selectedPlanId: '',
      selectedCurrency: 'USD',
      virutalPbx: '',
      planId: {
        monthlyId: {id:'', visiblity:true},
        anuallyId: {id:'', visiblity:true}
      },
      availablePlans: [],
      voiceTable: [],
      unifiedTable: [],
      unifiedInstant: [],
      userRange:[],
      selectedRange:'1-4',
      sliderValue: 3,
      noPlans:null,
      zoomLevel:0,
      servicesIds:[],
      currentActiveTab:null,
      previousActiveTab: null,
      loading:false
    };
    this.handleZoomChange = this.handleZoomChange.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getServices();
    this.getUserRange();
    this.updateZoomLevel();
    window.addEventListener('resize',this.handleZoomChange )
    const initialServiceId = this.props.navigation.getParam('activeTab');

       this.setState({
        currentActiveTab: initialServiceId,
        previousActiveTab: initialServiceId,
        loading:true
       })

    // Customizable Area End
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    
      this.handleSessionResponse(message);

     } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestApiResponse(message);
   

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    return window.removeEventListener('resize', this.handleZoomChange);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {

    

    const currentServiceId =  this.props.navigation!==undefined &&  this.props.navigation.getParam('activeTab'); 
   
  
    // Compare currentServiceId with previousServiceId
    if (currentServiceId !== prevState.previousActiveTab) {
      // ServiceId has changed, update state
      this.setState({
        currentActiveTab: currentServiceId,
        previousActiveTab: currentServiceId, // Update previousServiceId,
        sliderValue:3,
        loading:true
      }, ()=>{
        // this.callData(this.state.currentServiceId)
        this.getPlan(this.state.currentActiveTab)
        this.getUserRange()

      });
    }
   }
 


  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleExpandTable = (id:string) => {
  
 const newOpenState =    this.state.tableOpenState.map((item,index)=>{
       if(item.id === id){
             return {...item, open: !this.state.tableOpenState[index].open }  
           }
           return item
       })
      this.setState({
         tableOpenState:newOpenState
    })
   

  }

  gotoDemo = (id:string)=>{
  
    this.props.navigation.navigate("RequestDemoWithStyles", {
    'id':id
    })
}


selectedPlan = (plan: string, planid: string | undefined) => {
  this.setState({
    selectedPlan: plan,
    selectedPlanId: planid || ""
  },
    () => {
        this.getPricing(this.state.selectedRange, this.state.selectedCurrency.toLowerCase(), this.state.selectedPlanId)
    }
  
  )
}

selectedCurrency = (currecny: string) => {
  this.setState(()=>{
    return {
      selectedCurrency:currecny
    }
  },
    () => {
      this.getPricing(this.state.selectedRange, this.state.selectedCurrency.toLowerCase(), this.state.selectedPlanId)
    }
  )
}


getPricing = async(userRange: string, currency: string, currentPlan: string) => {

  const langsKey = await this.langKey
  const activeTab = this.props.navigation.getParam('activeTab')

  const isGreaterThan100 = userRange.includes('+') ? '100%2B': userRange
  const header = {
    "Content-Type": configJSON.categoryApiContentType,
    token: this.state.token,
    "Accept-Language":  langsKey
  };

  const apiEndPoint = `${configJSON.pricingUrl}?service_id=${activeTab}&user_range=${isGreaterThan100}&currency=${currency}&plan_id=${currentPlan}&locale=${langsKey}`
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getPricingApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);



}


getServices = async() => {
  const langsKey = await this.langKey
  const headers = {
    "Content-Type": configJSON.categoryApiContentType,
    "Accept-Language":  langsKey
  };

  const apiEndPoints = configJSON.servicesApiUrl;
  const getServicesidss = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.serviceApiId = getServicesidss.messageId;

  getServicesidss.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoints
  );
  getServicesidss.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getServicesidss.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(getServicesidss.id, getServicesidss);

}



handleServiceResponse = (response: {
  id: string,
  type: string,
  attributes: {
    service_name: string,
    status: number,
    short_description: string,
    long_description: string,
    price: null | string,
    discount: null | string,
    feature_id: null | string,
    created_at: string,
    updated_at: string
  }
}[]) => {
  this.setState(()=>{
    const servicesIds =   response.map((item)=>item.id)    
    return {
      servicesIds,
      
    }
  })
}
  getPlan = async (serviceId: string | null) => {
    const langsKey = await this.langKey
    this.setState({loading:true})
   const headers = {
    "Content-Type": configJSON.categoryApiContentType,
    "Accept-Language":  langsKey
  };

  const apiEndPointss = `${configJSON.planApiUrl}?service_id=${serviceId}`;
  const getPlanidss = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.planApiId = getPlanidss.messageId;

  getPlanidss.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPointss
  );
  getPlanidss.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getPlanidss.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(getPlanidss.id, getPlanidss);

}


getUserRange = async() => {

  const langsKey = await this.langKey
  const header = {
   "Content-Type": configJSON.categoryApiContentType,
   "Accept-Language":  langsKey
 };
 const activeTab = this.props.navigation.getParam('activeTab')

 const apiEndPoint = `${configJSON.userRangeApi}?service_id=${activeTab}`;
 const getPlanids = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );
 this.userRangeApiId = getPlanids.messageId;

 getPlanids.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   apiEndPoint
 );
 getPlanids.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );
 getPlanids.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.httpGetType
 );
 runEngine.sendMessage(getPlanids.id, getPlanids);

}





handlePlanResponse = (response: {
  id: string,
  type: string,
  attributes: {
    name: string,
    service_id: string,
    pricing_section_id: number,
    visiblity: boolean,
    plan_details: string
  }
}[]) => {
  const monthlyPlan = response.find(item => item.attributes.name.toLowerCase() === 'monthly');
  const annuallyPlan = response.find(item => item.attributes.name.toLowerCase() === 'annually');

 this.setState((prevState) => {
    return {
      ...prevState,
      planId: {
        monthlyId: {id:monthlyPlan?.id || "", visiblity: monthlyPlan?.attributes.visiblity },
        anuallyId: {id: annuallyPlan?.id || "", visiblity: annuallyPlan?.attributes.visiblity } ,
      },
      selectedPlanId: annuallyPlan?.id || "",
     

    }
  },
    () => {
      // Callback function to ensure state is updated before calling getPricing
     this.getPricing('1', this.state.selectedCurrency, this.state.selectedPlanId);
    });
};



handlePricing = (response: any) => {


   
    if (response.data) {
      this.setState((prevState: any) => {
        let voiceTables: { feature: string; availablity: boolean[] }[] = [];
        let unifiedTables: { feature: string; availablity: boolean[] }[] = [];
        let unifiedInstant: { feature: string; availablity: boolean[] }[] = [];
       
        const availablePlans = (response || []).data.map((item: any) => ({
          name: item.attributes.name,
          price: item.attributes.price,
          content: item.attributes.content,
          planId: item.attributes.plan_id,
          visiblity: item.attributes.visiblity,
          discountAmount: item.attributes.discount_amount,
          previousAmount: item.attributes.previous_amount,
          features: item.attributes?.features?.data,
          priceContent:item.attributes.price_content
        }));
  
        (response || []).data.forEach((item: any) => {
          (item.attributes.category_and_sub_category || []).forEach((data: any) => {
            if (data.category_name === 'voice') {
              // Handle 'voice' category as before
              voiceTables = this.parseData(data.sub_category_name || [], voiceTables)
            } else if (data.category_name.trim() === 'Unified Communication (Video Collaboration)- Optional with additional Charges') {
              unifiedTables = this.parseData(data.sub_category_name || [], unifiedTables);
            }
            else if (data.category_name.trim() === 'Unified Communication (Instant Messaging)') {
              unifiedInstant = this.parseData(data.sub_category_name || [], unifiedInstant);
            }
          });
        });
  
        return {
          ...prevState,
          availablePlans,
          voiceTable: voiceTables,
          unifiedTable: unifiedTables,
          unifiedInstant,
          noPlans:null,
          loading:false
        };
      });
    }
    else {
      this.setState({
          noPlans: response.errors,
          loading:false
        })
    }
  }


parseData = (data: any, voiceTables: any) => {
  (data || []).forEach((voice: any) => {
    const index = voiceTables.findIndex((voiceTable: any) => voiceTable.feature === voice.subcategory_name);

    if (index !== -1) {
      voiceTables[index].availablity.push(voice.price_option);
    } else {
      voiceTables.push({
        feature: voice.subcategory_name,
        availablity: [voice.price_option],
      });
    }
  });

  return voiceTables;
};


handleUserRange =  (response: { "id": string,
"type": string,
"attributes": {
    "user_range": string,
    "visiblity": boolean,
    "sort_order": null | number
}}[])=>{

    this.setState(()=>{
        const userRange = response.map((item)=>{
          return  {
            id:item.id,
            userRange: item.attributes.user_range,  
            min:  item.attributes.user_range.includes('+') ? '98': item.attributes.user_range.split('-')[0], 
            max: item.attributes.user_range.split('-')[1] || '200', visiblity: item.attributes.visiblity  }
        })

        return {userRange}

    })


}

getSliderValue  = (value:string)=>{

 let index;

 if(parseInt(value) >=0 && parseInt(value) <= 4 ){
  index = 0
}
else if(parseInt(value) >= 11 && parseInt(value) <= 51 ){
  index = 1
}
else if(parseInt(value) >=51 && parseInt(value) < 99 ){
  index =2
}
else{
  index =3
}


  const userRange =  this.state.userRange[index].id


  this.setState({
    selectedRange:userRange
  }, ()=>this.getPricing(userRange,this.state.selectedCurrency.toLowerCase(), this.state.selectedPlanId))
}




handleDebouncedInput = () => {
  // Perform the actual action with the debounced input
   this.getSliderValue(this.state.sliderValue?.toString()) 
 
};

handleSessionResponse(message: Message) {
  runEngine.debugLog("Message Received", message);
  let token = message.getData(getName(MessageEnum.SessionResponseToken));
  this.setState({ token: token }, () => {
    this.getCategories();
  });
}


handleRestApiResponse(message: Message) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );

  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  runEngine.debugLog("API Message Received", message);

  if (this.isDeleteCategoriesOrSubCategories(apiRequestCallId, responseJson)) {
    this.handleDeleteCategoriesOrSubCategories(apiRequestCallId, responseJson);
  } else {
    this.handleOtherApiRequests(apiRequestCallId, responseJson);
  }
}

isDeleteCategoriesOrSubCategories(apiRequestCallId: string, responseJson: any): boolean {
  return apiRequestCallId === this.deleteCategoriesApiCallId || apiRequestCallId === this.deleteSubCategoriesApiCallId;
}

handleDeleteCategoriesOrSubCategories(apiRequestCallId: string, responseJson: any) {
  if (!responseJson.error) {
    this.getCategories();
  } else {
    this.parseApiCatchErrorResponse(responseJson.error.message);
  }
}

handleOtherApiRequests(apiRequestCallId: string, responseJson: any) {
  // Handle other API requests...
  if (this.shouldHandleApiResponse(apiRequestCallId, responseJson)) {
    this.handleApiResponse(apiRequestCallId, responseJson);
  }
}

shouldHandleApiResponse(apiRequestCallId: string, responseJson: any): boolean {
  return (
    (apiRequestCallId === this.serviceApiId || apiRequestCallId === this.planApiId ||
     apiRequestCallId === this.getPricingApiId || apiRequestCallId === this.userRangeApiId) &&
    responseJson
  );
}

handleApiResponse(apiRequestCallId: string, responseData: any) {
  switch (apiRequestCallId) {
    case this.serviceApiId:
      this.handleServiceResponse(responseData.data);
      break;
    case this.planApiId:
      this.handlePlanResponse(responseData.data);
      break;
    case this.getPricingApiId:
      this.handlePricing(responseData);
      break;
    case this.userRangeApiId:
      this.handleUserRange(responseData.data);
      break;
    // Add more cases for other API requests as needed
  }
}


handleZoomChange = ()=>{
  this.updateZoomLevel();
}


updateZoomLevel() {
  this.setState({
    zoomLevel: window.devicePixelRatio,
  });
}

  // Customizable Area End
}
