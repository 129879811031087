import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  imgPasswordInVisible, imgPasswordVisible,
  cisco,
  grandSteam,
  omantel,
  polycam,
  snom,
  unify,
  yealink,
  panasonic,
  basic,
  standard,
  premeium,
  confrence,
  desktop,
  laptop,
  mobile
} from "./assets";

export const vendorList = [
  { id: "1", image: cisco },
  { id: "2", image: grandSteam },
  { id: "3", image: omantel },
  { id: "4", image: polycam },
  { id: "5", image: unify },
  { id: "6", image: yealink },
  { id: "7", image: snom },
  {id:"8", image:panasonic}
]

import { getStorageData } from "../../../framework/src/Utilities"; 
 



export const rangeList = [
  { id: "1", image: basic , title:"Basic", subTile:""},
  { id: "2", image: standard, title:"Standard",subTile:"" },
  { id: "3", image: premeium, title:"Premium", subTile:"" },
  { id: "4", image: confrence, title:"Conference phone",subTile:"" },
  { id: "5", image: desktop, title:"Desktop", subTile:"( windows  & apple)"},
  { id: "6", image: laptop, title:"Laptop", subTile:"( windows  & apple)" },
  { id: "7", image: mobile, title:"Mobile",subTile:" ( android & apple)"  },
  
]

export interface ProductContent {
  "id": string,
  "title":string,
  "status": boolean,
  "img_content": {
    "content": string,
    "sub_content": string,
    "image": string
}[]
}

interface PlansResponse {
  "id": string,
  "type": string,
  "attributes": {
      "name": string,
      "price":string,
      "content": string,
      "plan_id": number,
      "pricing_section_id": number,
      "user_range": {
          "id": number,
          "user_range": string,
          "pricing_section_id": number,
          "created_at": string,
          "updated_at": string,
          "sort_order": number
      },
      "visiblity": boolean,
      "discount_amount": string,
      "previous_amount": string,
      "price_content": string,
      "category_and_sub_category":  {
        "category_name": string,
        "sub_category_name":    {
          "subcategory_name": string,
          "price_option": boolean
      } []
    }[],
      "features": {
          "data":   {
            "id": string,
            "type": string,
            "attributes": {
                "name": string,
                "status": string,
                "visiblity": boolean
            }
        }[]
      }
  }
}



interface Product {
  "section1": ProductContent,
"section2": ProductContent,
"section3": ProductContent,
"section4": ProductContent,
"section5": ProductContent,
"section6":ProductContent,
"section7": ProductContent
}




import * as Yup from 'yup';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  message?:any;
  langKey:string;
  productCotent:Product;
  currentPlan:string | null;
  availablePlan:  {

    name: string
    price: string,
    content: string,
    planId: string,
    visiblity: string,
    discountAmount: string,
    previousAmount: string,
    priceContent:string,
    features: {
      id: string,
      type: string,
      attributes: {
        name: string,
        status: string,
        visiblity: boolean
      }
    }[]


  }[] | null;  
  showSuccessModal:boolean;
  virutalPbx:string;
  currentServiceId:null | string,
  previousServiceId:null | string,
  loading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductQuickviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiId:string="";
  planApiId:string="";
  availablePlanId:string="";
  contactUsApiId:string="";
  serviceApiId:string="";
  productId:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      langKey: '',
      productCotent: {} as Product,
      currentPlan: null,
      availablePlan: [],
      showSuccessModal:false,
      virutalPbx:"",
      currentServiceId:null,
      previousServiceId:null,
      loading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
        this.handleApiResponses(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {


    const initialServiceId = this.props.navigation.getParam('serviceId');

       this.setState({
        currentServiceId: initialServiceId,
        previousServiceId: initialServiceId
       })
 }

 componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {

    

  const currentServiceId =  this.props.navigation!==undefined &&  this.props.navigation.getParam('serviceId'); 
 

  // Compare currentServiceId with previousServiceId
  if (currentServiceId !== prevState.previousServiceId) {
    // ServiceId has changed, update state
    this.setState({
      currentServiceId: currentServiceId,
      previousServiceId: currentServiceId, // Update previousServiceId,
      availablePlan:[],
      productCotent:{} as Product,
      loading:true,
      
    }, ()=>{
      this.callData(this.state.currentServiceId)
    });
  }
 }









  ContactUsScheam =  (t:Function)=> Yup.object().shape({
    orgName: Yup.string().required(t('nameRequired'))
    .transform(value => value.trim()) // Trim leading and trailing spaces
    .matches(/^[A-Za-z ]+$/, t('nameLetterOnly')),
    emailAddress: Yup.string().email(t('errorEmailInvalid')).required(t('errorEmailRequired')),
    applicantName: Yup.string().required(t('errorApplicantName'))
    .transform(value => value.trim()), // Trim leading and trailing spaces
    writeUs: Yup.string().required(t('cannotEmpty'))
    .transform(value => value.trim()), // Trim leading and trailing spaces
    phoneNumber: Yup.string().required(t('errorPhoneRequired'))
     .matches(/^[\d+]+$/, t('phoneInvalid'))
  });


  getProduct =  async(productId:string)=>{


    const langKey =   await getStorageData('i18nextLng')
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Accept-Language": langKey
    };

    const apiEndPoint = `${configJSON.productPageApiId}?service_id=${productId}&locale=${langKey}`;
    const getProductId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productApiId = getProductId.messageId;

    getProductId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    getProductId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getProductId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getProductId.id, getProductId);

  }

  handleApiResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId !== null && responseJson !== null) {
        this.handleApiResponseById(apiRequestCallId, responseJson);
    }
}

handleApiResponseById = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
        case this.productApiId:
            this.handleProductPageResponse(responseJson?.product_page_content);
            break;
        case this.planApiId:
            this.handlePlanResponse(responseJson.data);
            break;
        case this.availablePlanId:
            this.handleAvailablePlansResponse(responseJson.data);
            break;
        case this.contactUsApiId:
            this.handleContactUsResponse(responseJson);
            break;
    
        // Add more cases if needed
        default:
            // Handle unknown apiRequestCallId if necessary
            break;
    }
}


  handleProductPageResponse = (productInfo:Product)=>{

     this.setState({
      productCotent: productInfo,
      loading:false
     
     })
 }


 handleContactUsResponse =  (response:any)=>{
  if(response.data){
    this.setState({
       showSuccessModal:true
    })
 }
 else{
    console.log('someThing Went wrong')
 }
 }

 getPlan =  async(serviceId:string)=>{

  const langKey =   await getStorageData('i18nextLng')
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "Accept-Language": langKey
  };

  const apiEndPoint = `${configJSON.planApiUrl}?service_id=${serviceId}&locale=${langKey}`;
  const getPlansRequestId = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.planApiId = getPlansRequestId.messageId;

  getPlansRequestId.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  getPlansRequestId.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getPlansRequestId.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(getPlansRequestId.id, getPlansRequestId);



 }


 handlePlanResponse = (response: {id:string, 
  type:string, 
  attributes:{name:string, 
  service_id: number,
  pricing_section_id: number,
  visiblity: boolean,
  plan_details:string}}[])=>{
    
     this.setState({
       currentPlan: response[0].id,
     
     }, ()=>this.getAvailablePlans(response[1].id, this.state.currentServiceId))
 }

 handleAvailablePlansResponse = (response:PlansResponse[])=>{
  console.log("available Plans", response)

   
  
  this.setState(()=>{
    const availablePlans = (response || []).map((item: any) => ({
      name: item.attributes.name,
      price: item.attributes.price,
      content: item.attributes.content,
      planId: item.attributes.plan_id,
      visiblity: item.attributes.visiblity,
      discountAmount: item.attributes.discount_amount,
      previousAmount: item.attributes.previous_amount,
      features: item.attributes?.features?.data,
      priceContent:item.attributes.price_content
    }));

    return {
      availablePlan:availablePlans,
      loading:false
    }
  })

}



 getAvailablePlans  = async(planId:string,currentServiceId:string | null)=>{
  
  const langKey =   await getStorageData('i18nextLng')

  

  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "Accept-Language": langKey

  };

  const apiEndPoint = `${configJSON.plansOptions}?plan_id=${planId}&locale=${langKey}&service_id=${currentServiceId}`;
  const getAvaialblePlansId = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.availablePlanId = getAvaialblePlansId.messageId;

  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(getAvaialblePlansId.id, getAvaialblePlansId);




 }

 requestDemo =  (id:string)=>{
  this.props.navigation.navigate("RequestDemoWithStyles", {
    'id':id
    })
 }

 contactUsPostRequest = (value: {orgName: string,
 applicantName:string,
 phoneNumber:string,
 emailAddress: string,
 writeUs: string,
})=>{

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    };

  const httpBodys = JSON.stringify({
    data:{
      name: value.applicantName,
      email: value.emailAddress,
      phone_number: value.phoneNumber,
      description: value.writeUs,
      company_name: value.orgName
    }
  });
 
  const postDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.contactUsApiId = postDataMsgs.messageId;

  const apiEndpoints = configJSON.contactUsApi 


 postDataMsgs.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  httpBodys
);
postDataMsgs.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  apiEndpoints
);

postDataMsgs.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

postDataMsgs.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "POST"
);

runEngine.sendMessage(postDataMsgs.id, postDataMsgs);
}


 handleCloseModal =  ()=>{
   this.setState({
    showSuccessModal:false
   })
 }

  removePTags = (htmlContent:string) => {
  // Remove wrapping <p> tags
  return htmlContent.replace(/<p\b[^>]*>|<\/p\s*>/g, '');

  
  
}

callData =  (id:string | null)=>{
    if(id){
     this.getPlan(id);
     this.getProduct(id);

     
     }

 }



  // Customizable Area End
}
