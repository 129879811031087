import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


interface FooterResponse {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "service": {
      "id":number,
      "service_name":string
    },
    "companies": [
      {
        "id": number,
        "title": string,
        "title_ar": string,
        "url": null | string,
        "created_at": string,
        "updated_at": string,
        "footer_id": number
      }
    ],
    "quick_links": [
      {
        "id": number,
        "title": string,
        "title_ar": string,
        "url": string | null,
        "created_at": string,
        "updated_at": string,
        "footer_id": number
      }
    ]
  }
}

interface ComapnyResponse { name: string, link: string | null }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  company: ComapnyResponse[] | undefined,
  quickLinks: ComapnyResponse[] | undefined
  services:string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      company: [],
      quickLinks: [],
      services:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleApi(message)
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start


  async componentDidMount(): Promise<void> {
    this.getProductsName();


  }


  handleApi = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId !== null && responseJson !== null) {
      this.handeleApiResponse(apiRequestCallId, responseJson);
    }


  }



  handeleApiResponse = (apiRequestCallId: string, responseJson: any) => {

    if (apiRequestCallId === this.productApiId && !responseJson?.error && Boolean(responseJson)) {
      this.handleFooterCompaniesData(responseJson)
    }
  }


  handleFooterCompaniesData = (responseJson: { data: FooterResponse[] }) => {

    try {

      this.setState(() => {

        const companies = responseJson.data.reduce((previous: { name: string, link: string | null }[] | undefined, current) => {

          const currentCompanies = current.attributes.companies.map((item) => {
            return {
              name: item.title,
              link: item.url
            }
          })

          if (!previous) {
            return currentCompanies
          }
          return [...previous, ...currentCompanies]
         }, [])

       const quick_links = responseJson.data.reduce((previous: { name: string, link: string | null }[] | undefined, current) => {
        const currentQuickLinks = current.attributes.quick_links.map((item) => {
            return {
              name: item.title,
              link: item.url
            }
          })

          if (!previous) {
            return currentQuickLinks
          }
        return [...previous, ...currentQuickLinks] }, [])
      
        const services  = responseJson.data.map((item)=>item.attributes.service.service_name)

     

        return {
          company: companies,
          quickLinks: quick_links,
          services

        }
      })
    } catch (error) {
      console.error("Error processing footer data:", error);
    }


  }



  getProductsName = async () => {

    const langKey = await getStorageData('i18nextLng')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Accept-Language": langKey
    };

    const apiEndPoint = `${configJSON.ProductApi}?locale=${langKey}`;
    const getProductId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productApiId = getProductId.messageId;

    getProductId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    getProductId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getProductId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getProductId.id, getProductId);

  }



  // Customizable Area End
}
