Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AffiliateUrl";
exports.labelBodyText = "AffiliateUrl Body";

exports.btnExampleTitle = "CLICK ME";
exports.socialMediaUrl = "bx_block_affiliateurl/social_media_links"
exports.linkDein  =  "LinkedIn";
exports.youtube = "YouTube";
exports.insta =  "Instagram";
exports.facebook =  "Facebook";
exports.twitter = "Twitter";
exports.active = "Enable"

// Customizable Area End