import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPage: {section1: any, section2:any, section3:any, section4:any};
  landingPageTitle:string[];
  servicesIds: { id: string, serviceName: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      landingPage:{section1:{}, section2:{}, section3:{}, section4:{}},
      landingPageTitle:[],
      servicesIds: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponseMessage(message)
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount(): Promise<void> {

    this.getLandingPageData();
    this.getServices();

  
}


getServices = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  const apiEndPoint = `bx_block_request_demo/services`;
  const getAvaialblePlansId = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.serviceApiId = getAvaialblePlansId.messageId;

  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getAvaialblePlansId.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(getAvaialblePlansId.id, getAvaialblePlansId);
}

  landingPageId:string="";
  serviceApiId: string = "";
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  getLandingPageData = async()=>{

    
  const langKey =  await getStorageData('i18nextLng');

       const header = {
        "Content-Type": configJSON.apiContentType,
        'Accept-Language': langKey
      };
  
      const apiEndPoint = `${configJSON.landingPageUrl}?locale=${langKey}`;
      const getLandingPage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.landingPageId = getLandingPage.messageId;
  
      getLandingPage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint
      );
      getLandingPage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      getLandingPage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getLandingPage.id, getLandingPage);
  
    }

    goToPricing = () => {
      this.props.navigation.navigate('PricingFinal', { activeTab: this.state.servicesIds[0].id })
    }
    
    gotToDemo = () => {
      this.props.navigation.navigate("RequestDemoWithStyles", { 'id': this.state.servicesIds[0].id })
    }

    private handleApiResponseMessage(message: Message) {


      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
  
      if (apiRequestCallId !== null) {
  
  
        if (apiRequestCallId === this.landingPageId && responseJson !== null && Boolean(responseJson)) {
           console.log("landingPage", responseJson)
              
           
           const title = responseJson.landing_page_content.section1.title.split(/[{}]/).filter(Boolean);
          this.setState({
              landingPage:responseJson.landing_page_content,
              landingPageTitle:title
            })

  
        }
        
        if (apiRequestCallId === this.serviceApiId && responseJson !== null) {
          this.setState(() => {
            const data = responseJson.hasOwnProperty("data") ? responseJson.data.map((item: any) => {
              return { id: item.id, serviceName: item.attributes.service_name }
            }) : [];
  
            return { servicesIds: data }
  
          })
        }
     
        
      }
    }

  


  // Customizable Area End
}
