Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RequestDemo";
exports.labelBodyText = "RequestDemo Body";
exports.timeSlotsApiEndPoint = "bx_block_request_demo/request_demos/time_slots";
exports.bookDemoEndPoint = "bx_block_request_demo/request_demos";

exports.btnExampleTitle = "CLICK ME";
exports.demoTitle = "Let’s schedule you a demo";
exports.demoSubtitle ="We'll give you an intro to the perfect solution that fits your business. Get started on your journey to fearless growth.";
exports.agreeAll =  "I  agree to all the ";
exports.termsAndCondition = "Terms and Condiitons";
exports.next = "Next";
exports.demoForm = "Demo Form";
exports.feelFree = "Fill your personal details to";
exports.demoSession = "attend a demo session";
exports.weComm = "Wecom";
exports.demoConsultation = "Demo Consultation";
exports.halfMin = "30 Min";
exports.zoom = "Zoom";
exports.selectDate = "Select Date and Time";
exports.selectedDate = "Friday,November 7th";
exports.usTime = "Eastern Time-USa & Canada";
exports.noAvailaleSlots = "no available time slots"
exports.bookDemoText = "Book Demo";
exports.successMessage = "Your message has been sent . Someone from our support team will contact you shortly.";

// Customizable Area End