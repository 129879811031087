export const communicationBanner = require("../assets/banner.svg");
export const badge = require("../assets/badge_icon.svg");
export const mobility = require("../assets/mobility_solutions_icon.svg");
export const support = require("../assets/support_icon.svg");
export const communication = require("../assets/communication_icon.svg");
export const security = require("../assets/security_icon.svg");
export const card = require("../assets/card.png");
export const madame = require("../assets/madme.svg");
export const tegSoft = require("../assets/tegSoft.svg");
export const omante = require("../assets/omante.svg");
export const genisy = require("../assets/genisy.svg");
export const arrow = require("../assets/arrow.svg");