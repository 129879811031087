import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  socialMedia:{id:string, 
    type:string, 
    attributes:{
     name:string,
     link:string,
     status:string
  } }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AffiliateUrlController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  socailMediaApiID:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      socialMedia:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponseMessage(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

 getSocailMedialUrl =  ()=>{

    
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };

  const apiEndPoint = configJSON.socialMediaUrl;
  const getSocialMediaUrl = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.socailMediaApiID = getSocialMediaUrl.messageId;

  getSocialMediaUrl.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  getSocialMediaUrl.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  getSocialMediaUrl.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(getSocialMediaUrl.id, getSocialMediaUrl);




 }

 private handleApiResponseMessage(message: Message) {


  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


  if (apiRequestCallId !== null) {
    if (apiRequestCallId === this.socailMediaApiID && responseJson !== null && Boolean(responseJson)) {
       this.setState({
          socialMedia : responseJson.data
       })
    }

 
    
  }
}


async componentDidMount(): Promise<void> {

  this.getSocailMedialUrl();


}




  // Customizable Area End
}
