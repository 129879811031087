Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.servicesApiUrl = "bx_block_request_demo/services";
exports.planApiUrl =  "bx_block_request_demo/plans";
exports.pricingUrl =  "bx_block_custom_user_subs/price_options";
exports.userRangeApi = "bx_block_request_demo/user_ranges";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.monthly ="Monthly";
exports.anually ="Annually";
exports.save = "Save 25 %";
exports.usd = "USD";
exports.omr ="OMR";
exports.numberUsers = "Number of Users";
exports.comparePlan = "Compare all our plans features";
exports.allFeature =  "All Features";
exports.bookDemo = "Book Demo";
exports.voice = "voice";
exports.adHoc="Ad hoc conferencing*";
exports.anonymousCall="Anonymous call rejection";
exports.inBounding = "In-Outbound calling"; 
exports.callCompletion = "Call completion activation /deactivation";
exports.unifiedCommunication =  "Unified Communication (Instant Messaging)";
exports.videoCollabration = "Unified Communication (Video Collaboration)- Optional with additional Charges";
exports.markPoint = [{ point: "1-4" }, { point: "5-19" }, { point: "20-99" }, { point: "100+" }];
exports.standard = "Standard";
exports.advance = "Advance";
exports.premium = "Premium"; 



// Customizable Area End