export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const tick = require("../assets/tick.svg");
export const arrowRight = require("../assets/rightArrow.svg");
export const upArrows = require("../assets/upArrow.svg");
export const downArrows = require("../assets/downArrow.svg");
export const infoIcon = require("../assets/infoIcon.svg");
export const dotIcon = require("../assets/dotIcon.svg");