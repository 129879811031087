Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductQuickview";
exports.labelBodyText = "ProductQuickview Body";
exports.productPageApiId="bx_block_content_management/products";
exports.planApiUrl = "bx_block_request_demo/plans";
exports.plansOptions = "bx_block_custom_user_subs/price_options";
exports.contactUsApi = "bx_block_contact_us/contacts";
exports.sevicesApi = "bx_block_request_demo/services";
exports.demoBtn = "Book Demo";

exports.btnExampleTitle = "CLICK ME";
exports.mainTitle = "Virtual PBX";
exports.subHeading = "Virtual PBX offered by Omantel gives your business a complete freedom from traditional PBX solution with flexibility of scaling-up or down as per your business requirement along with pay as you go model.Traditional PBX solution require foresight planning and capital investment to setup office telephony system. Virtual PBX is here to provide you with the flexibility of scaling up or down your entire office telephony system as per your business requirements whether in terms of hiring new employees opening new branches for addressing additional business requirements across The Sultanate."
exports.getDemo = "Get a demo";
exports.whatOffer = "What we offer";
exports.cloudSoution = "Cloud Solution";
exports.agile = "Agile & Scalable";
exports.noUpfront = "Quick Deployment";
exports.quickDevlopment = "No Upfront Investment";
exports.easyToManage = "Easy to manage";
exports.professionalSupport = "Proffessional support";
exports.hostel = "Hostel Locally";
exports.ourCustomer = "Our Customers";
exports.rangeOfEndPoint = "Range of endpoint support";
exports.availablePlans = "Available Plans";
exports.compareAllPlan = "Compare all our plans features";

exports.cloudText = "Provides you flexibility to suit your Business requirement";
exports.agileText = "Quick and easy to start your business Telephony solution with scalability as add-on";
exports.noUpfrontText = "No planning for future expansion, as there will be no intial investments and commitments";
exports.quickDevlopmentText = "Faster and hassle free implementation. Compatible with all major phone brands";
exports.easyToManageText = "Administrations of the functions via a web based application and that regardless of where you are at any time";
exports.professionalSupportText = "Leave to headache to Expert to maange your  Business Telephony Solution";
exports.hostelText = "Hostel locally with Oman";
exports.strategicPartner = "Our Strategic Partners";
exports.virtualPbxText = "Virtual PBX is here to give you the freedom to scale up or down your complete office telephony system according to your needs."
exports.interstedVirtual = "Are you interested in Virtual PBX solution?";
exports.happyToServe = "Our team is happy to answer your sale queries, send us an inquiry and we will be in touch shortly."
exports.informationText = "Please enter your organization’s information and we will contact you";
exports.writeUs = "Write to us";
exports.submit = "Submit";

exports.feature = "Advanced Features";
exports.callRecording = "Call recording";

exports.endPointTexts = [
  "Call recording",
  "Voicemail",
  "Conferencing",
  "Call Transfer",
  "Call Waiting",
  "Instant Messaging",
  "Phonebook",
  "IVR",
  "Free Calling between Branches",
  "Multiple endpoints per extension",
  "Video Calling"
];

exports.multiVendorTitle = "Multi-vendor endpoint support";




// Customizable Area End