Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";
exports.termAndConditionUrl = "bx_block_admin/pages";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.signUpEndPoint = "account_block/accounts"

exports.createAccount = "Create Account";
exports.alreadyAccount = " Already have an account?";
exports.agreeAll = "I agree to all the";
exports.termsCondition = "Terms and Conditions";
exports.getStarted = "Get started with your account to enjoy additional";
exports.benfits = "benefits";
exports.login = "Login";
exports.alreadyExists = "Email Id already exists";
exports.termsAndConditionsHeading = "Terms & Conditions";
exports.lastUpdateText = "Last updated :";
exports.lastUpdateTime = "Nov 17th, 2022";
exports.declineButtonText = "Decline";
exports.acceptButtonText = "Accept";
exports.accountCreatedSuccessfully = "Account created Successfully";
exports.createProfile = "Create Profile";
exports.createMessage = "We'll give you an intro to the perfect solution that fits your business. Get started on your journey to fearless growth.";
exports.cancel = "Cancel";
exports.saveChanges = "Save Changes";
exports.createProfileUrl = "account_block/accounts/create_profile";
exports.profileCreate = "Profile created Successfully";
// Customizable Area End
