import React, { Component, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';

interface CustomInputProps {
    type: string;
    name: string;
    placeholder: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    inputError?: boolean;
    label: string;
    flexGrow?: number;
    error?: boolean;
    errorMessage?: string;
}

interface CustomInputState {
    showPassword: boolean;
}

const eyeIcon = require("./eye_icon.svg");

class CustomInput extends Component<CustomInputProps, CustomInputState> {
    constructor(props: CustomInputProps) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        const {
            type,
            name,
            placeholder,
            value,
            onChange,
            onFocus,
            disabled,
            onKeyDown,
            inputError,
            label,
            error,
            errorMessage
        } = this.props;

        const inputbox = {
            border: '1px solid #102126',
            borderRadius: '10px',
            backgroundColor: '#F6F6F6',
            height: '56px',
            width: '100%',
            outline: 'none',
            fontFamily: 'Poppins',
            fontWeight: 300,
            fontSize: '16px',
            paddingLeft: '16px',

        };

        const labelStyle = {
            color: '#102126',
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 300,
            marginBottom: '12px',
        };

        const flexGrowNumber = {
            flexGrow: this.props.flexGrow ? 1 : 0,
        }

        return (
            <div style={{ maxWidth: '573px', ...flexGrowNumber }}>
                <div style={labelStyle}>{label}</div>
                <style>
                    {`
                        &::placeholder {
                            font-size: 14px !important;
                            font-weight: 400 !important;
                            font-family: 'Poppins, Arial, sans-serif';
                        }
                    `}
                </style>
                <div style={(type === 'password' ? { position: 'relative' as 'relative' } : {})}>
                    <input
                        type={this.state.showPassword ? 'text' : type}
                        name={name}
                        placeholder={placeholder}
                        style={{ ...inputbox, ...(inputError ? { borderColor: 'red' } : {}), }}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                    />
                    {type === 'password' && (
                        <span
                            onClick={this.togglePasswordVisibility}
                            style={{

                                position: 'absolute',
                                cursor: 'pointer',
                                top: ' 50%',
                                transform: 'translateY(-50%)',
                                right: '6%',
                            }}
                        >
                            <img src={eyeIcon} alt='eyeIcon' />
                        </span>
                    )}
                    {error &&
                        <p style={{
                            fontWeight: 300,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#C30A2A',
                        }}>

                            {errorMessage}
                        </p>}
                </div>
            </div>
        );
    }
}

export default CustomInput;
