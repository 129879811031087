import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  createStyles,
  Theme,
  withStyles,
  Grid,
  Hidden
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { Formik, Form } from "formik";
import { configJSON } from "./EmailAccountLoginController";
import CustomInput from "../../../components/src/CustomInput.web";
import {loginSideBanner, topBackground} from "./assets"
import SuccessModal from "../../../components/src/SuccessModal.web";
import {Translation} from 'react-i18next'
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
    // Customizable Area Start
   // Customizable Area End
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  classes = this.props.classes
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <Translation>
        {t=>    <ThemeProvider theme={theme}>
      <Box className={this.classes.viewPortHeight}>
   <Grid container className={this.classes.viewPortHeight} >

<Hidden smDown> <Grid item xs={12} sm={12} md={6} >
<Box className={this.classes.linearGradient}>
 <Box > 
 <img src={loginSideBanner} alt='' width={'100%'} />
 </Box>

</Box>

</Grid></Hidden>

<Grid item xs={12} sm={12} md={6}>

<Box className={this.classes.formBackgroundColor}>


  <Box className={this.classes.createForm}>

    <Box style={{width:'100%'}}>
      <Typography variant='h3' className={this.classes.createHeading}>{t('signIn')}</Typography>
      <Typography variant='h5' className={this.classes.subHeading}>
        {t('welcomeBack')}<br />{t('enterDetail')}
      </Typography>
    </Box>

    <Formik
      data-test-id='formik'
      enableReinitialize
      validateOnBlur={true}
      initialValues={{
       emailAddress: '',
       password:''

      }}
      onSubmit={(values) => {
        this.doLogin(values);
      }}
      validationSchema={()=> this.FormSchema(t)}
    >
      {
        ({ errors, values,  handleChange, touched, setFieldValue }) => {
          return (

            <Form style={{width:'100%'}}>


              <Box className={this.classes.fieldContainer}>
               
                <Box>
                  <CustomInput
                    label={t('emailAddress')}
                    type='text'
                    name="emailAddress"
                    placeholder={t('emailAddress')}
                    onChange={handleChange}
                    value={values.emailAddress}
                  />

                  {
                    Boolean(touched.emailAddress && errors.emailAddress) && (
                      <Typography className={this.classes.error}>{errors.emailAddress}</Typography>
                    )
                  }

                </Box>
                <Box>
                  <CustomInput
                    label={t('password')}
                    type='password'
                    name="password"
                    placeholder={t('password')}
                    onChange={handleChange}
                    value={values.password}

                  />

                  {Boolean(touched.password && errors.password) && (
                    <Typography className={this.classes.error}>{errors.password}</Typography>
                  )
                    
                  }
                  {this.state.showError &&  <Typography className={this.classes.error}>{t('invalidCred')}</Typography>}
                </Box>
                <Box onClick={()=>this.props.navigation.navigate('ForgotPasswordWeb')}>
                <Typography align="right"  className={this.classes.forgotPassword}>
                 {t('forgotPassword')}
                 </Typography>
                </Box>
               <Box style={{maxWidth:'573px'}}>
                  <Button type="submit"
                    className={this.classes.createAccBtn}
                    color="primary"
                    data-test-id="submitBtn"
                    >
                    {t('signIn')}
                  </Button>
                </Box>
                <Box className={this.classes.alreadyAcc}>
                  <Typography className={this.classes.alreadyAccText}>
                    {t('doNotHaveAccount')}
                    <span className={this.classes.loginText} onClick={this.goToSignUp}>{t('signUp')}</span> </Typography>
                </Box>
              </Box>

            </Form>
          )
        }
      }
    </Formik>
   <SuccessModal 
    showSubmitSuccessModal={this.state.showSuccessModal} 
    handelCloseModal={this.handleCloseModal } 
    successMessage={t('successModal')}
    />

  </Box>



</Box>
</Grid>

</Grid>
   </Box>
   </ThemeProvider>}
      </Translation>
   
      // Customizable Area End
    );
  }
}

  // Customizable Area Start
  const styles = (theme: Theme) => createStyles({
    formBackgroundColor: {
      backgroundColor: '#F6F6F6',
      backgroundImage: `url(${topBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -200px',
      height:'100%'
  
    },
    
    linearGradient: {
      background: 'linear-gradient(180deg, #024EAF 14.18%, #1BC0FF 130.33%)',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative' as 'relative',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      marginTop: '32px',
    },
    createHeading: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '60px',
      paddingTop: '96px',
    },
    createForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height:'100%',
      maxWidth:'573px',
      margin:'auto',
    },
    subHeading: {
      fontSize: '20px',
      fontWeight: 300,
      lineHeight: '30px',
      marginTop: '12px',
      color: '#102126',
    },
   agreeInfo: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.60)',
    },
    createAccBtn: {
      borderRadius: '10px',
      background: '#012677',
      width: '100%',
      padding: '12px 0',
      fontSize: '24px',
      fontWeight: 500,
      color: '#fff',
      textTransform: 'none' as 'none',
      lineHeight: '36px',
      '&:hover': {
        background: '#012677',
        color: '#fff',
      },
    },
    alreadyAccText: {
      color: '#102126',
      fontWeight: 300,
      fontSize: '20px',
      lineHeight: '30px',
    },
    error: {
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#C30A2A',
    },
    loginText: {
      color: '#000',
      cursor: 'pointer',
      fontWeight:500 
    },
    alreadyAcc: {
      display: 'flex',
      justifyContent: 'center'
     },
    agreeAll:{
      textDecoration: 'underline', 
      fontWeight: 500
    },
    viewPortHeight:{
      minHeight:'100%'
    },
    forgotPassword:{
      color: "#1BC0FF",
     fontFamily: "Poppins",
       fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 300,
      cursor:'pointer'
    },
  
    "@media (max-width: 600px)": {
      createHeading: {
        fontSize: "30px", // Adjust the font size for smaller screens
        lineHeight: "45px",
      },
      createForm: {
        padding: "20px 20px", // Adjust the padding for smaller screens
      },
      linearGradient: {
        display: 'none'
      },
      formBackgroundColor:{
        height:'auto',
      }
  },
  
  
  });
  
  export const LoginWithStyles =   withStyles(styles)(EmailAccountLoginBlock);
  // Customizable Area End
