import React from "react";
  // Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { successChecked } from "./assets";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import { Formik, Form } from "formik";
import ContactusController, { Props } from "./ContactusController";
export const configJSON = require("./config");
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Translation } from 'react-i18next';
  // Customizable Area End
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
      // Customizable Area Start
      // Customizable Area End
  }
    // Customizable Area Start
    renderSubmitSuccessModal = () => {
      return (
        <Translation>
          {t => <Modal data-test-id ='openModalTestId' open={this.state.showSubmitSuccessModal}>
          <Box style={webStyle.modalMainBox}>
            <Box style={webStyle.innerModalBox as React.CSSProperties}>
              <Typography>
                <img style={webStyle.successCheckedIcon} src={successChecked} alt="successCheckedIcon" />
              </Typography>
              <Typography style={webStyle.modalSuccessText}>
                {t('submitSuccessfullText')}
              </Typography>
              <Button
                data-test-id="modalSuccessButtonID"
                variant="contained"
                color="primary"
                style={webStyle.okConfirmButton as React.CSSProperties}
                onClick={this.handleSuccessModalClose}
              >
                {t('modalOkButtonText')}
              </Button>
            </Box>
          </Box>
        </Modal>}
        </Translation>
        
      )
    };
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Translation>
        {t =>  <ThemeProvider theme={theme}>
        <AppHeaderWeb
          isHideGetStartButton={true}
          isShowSignUpHeading={true}
          isActiveGetStratedFooterButton={true}
          navigation={this.props.navigation}
          >
          <Box
            sx={webStyle.mainContainer}>
            <Box
              sx={webStyle.mainTextContainerBox}>
              <Typography variant="h3">{t('contactUsWebLabel')}</Typography>
              <Typography variant="h5">
                {t('contactUsBodyText')}
              </Typography>
            </Box>
            <Box
              sx={webStyle.mainFormBox}>
              <Typography variant="h6">
                {t('formHeadingText')}
              </Typography>
              <Formik
                data-test-id='formik'
                enableReinitialize
                validateOnBlur={true}
                initialValues={{
                  ...this.state.applicantFormData
                }}
                onSubmit={(values) => this.handleFormSubmit(values)}
                validationSchema={ ()=>  this.formValidationSchema(t)}
              >
                {
                  ({ errors, values, handleChange, touched, setFieldValue }) => {
                    return (
                      <Form style={webStyle.formElementTag as React.CSSProperties}>
                        <Box
                          style={webStyle.inputWrapperBox}>
                          <Input
                            data-testid="OrganisationNameID"
                            type='text'
                            name="organisationName"
                            placeholder={t('orgName')}
                            onChange={handleChange}
                            value={values.organisationName}
                            fullWidth
                          />
                          {
                            Boolean(touched.organisationName && errors.organisationName) && (
                              <Typography style={webStyle.errorMessageText}>{errors.organisationName}</Typography>
                            )
                          }
                        </Box>
                        <Box
                          style={webStyle.inputWrapperBox}>
                          <Input
                            data-testid="applicantFullNameID"
                            type='text'
                            name="applicantName"
                            placeholder={t('applicantName')}
                            onChange={handleChange}
                            value={values.applicantName}
                            fullWidth
                          />
                          {
                            Boolean(touched.applicantName && errors.applicantName) && (
                              <Typography style={webStyle.errorMessageText}>{errors.applicantName}</Typography>
                            )
                          }
                        </Box>
                        <Box
                          style={webStyle.inputWrapperBox}>
                          <Input
                            data-testid="phoneNumberID"
                            type='text'
                            name="phoneNumberApplicant"
                            placeholder={t('phoneNumber')}
                            onChange={handleChange}
                            value={values.phoneNumberApplicant}
                            fullWidth
                          />
                          {
                            Boolean(touched.phoneNumberApplicant && errors.phoneNumberApplicant) && (
                              <Typography style={webStyle.errorMessageText}>{errors.phoneNumberApplicant}</Typography>
                            )
                          }
                        </Box>
                        <Box
                          style={webStyle.inputWrapperBox}>
                          <Input
                            data-testid="EmailID"
                            type='text'
                            name="emailAddress"
                            placeholder={t('emailAddress')}
                            onChange={handleChange}
                            value={values.emailAddress}
                            fullWidth
                          />
                          {
                            Boolean(touched.emailAddress && errors.emailAddress) && (
                              <Typography style={webStyle.errorMessageText}>{errors.emailAddress}</Typography>
                            )
                          }
                        </Box>
                        <Box style={webStyle.inputMainMessageWrapperBox as React.CSSProperties}>
                          <Typography variant="body1" style={webStyle.labelTextTypography}>{t('writeUs')}</Typography>
                          <Box
                            style={webStyle.inputMessageWrapperBox}>
                            <Input
                              data-testid="discriptionInputID"
                              multiline
                              name="applicantMessage"
                              style={webStyle.textAreaField}
                              rows={5}
                              disableUnderline={true}
                              fullWidth
                              value={values.applicantMessage}
                              onChange={handleChange}
                            />
                            {
                              Boolean(touched.applicantMessage && errors.applicantMessage) && (
                                <Typography style={webStyle.errorMessageText}>{errors.applicantMessage}</Typography>
                              )
                            }
                          </Box>
                          <Button
                            data-test-id="submitButtonTestID"
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={webStyle.okConfirmButton as React.CSSProperties}>
                            {t('submit')}
                          </Button>
                        </Box>
                      </Form>
                    )
                  }
                }
              </Formik>
            </Box>
            {this.renderSubmitSuccessModal()}
          </Box>
        </AppHeaderWeb>
      </ThemeProvider> }
      </Translation>
     
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const webStyle = {
  mainContainer: {
    display: "flex",
    padding: "80px 20px",
    gap: "10px",
    justifyContent: "space-evenly",
    "@media (max-width: 550px)": {
      flexDirection: "column"
    }
  },

  mainTextContainerBox: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .MuiTypography-root": {
      "&:last-child": {
        maxWidth: "470px",
        minWidth: "240px",
        lineHeight: "40px"
      }
    }
  },

  mainFormBox: {
    maxWidth: "458px",
    backgroundColor: "#DFE8ED",
    padding: "40px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "25px"
  },

  okConfirmButton: {
    width: "180px",
    fontWeight: "bold",
    textTransform: "capitalize",
    backgroundColor: "#012677",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#012677"
    }
  },

  textAreaField: {
    backgroundColor: "white",
    borderRadius: "5px"
  },

  labelTextTypography: {
    color: "#666B6D"
  },

  formElementTag: {
    display: "flex",
    flexDirection: "column"
  },

  inputWrapperBox: {
    height: "60px"
  },

  errorMessageText: {
    color: "#C30A2A",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px"
  },

  inputMainMessageWrapperBox: {
    display: "flex",
    flexDirection: "column",
    gap: "15px"
  },

  inputMessageWrapperBox: {
    height: "145px"
  },

  modalMainBox: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  innerModalBox: {
    backgroundColor: "white",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 25px",
    maxWidth: "280px",
    gap: "20px"
  },

  successCheckedIcon: {
    width: "80px"
  },

  modalSuccessText: {
    fontSize: "23px"
  }
};

// Customizable Area End
