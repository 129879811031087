import React, { Component, RefObject } from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import {
  communicationBanner,
  badge,
  mobility,
  communication,
  security,
  card,
  madame,
  tegSoft,
  omante,
  genisy,
  arrow
} from "./assets";

import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { Translation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h1: {
      fontSize: "48px",
      fontWeight: 700
    },
    h2: {
      fontSize: "48px",
      fontWeight: 500
    },
    h3: {
      fontSize: "32px",
      fontWeight: 500
    },
    h4: {
      fontSize: "24px",
      fontWeight: 300
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500
    },
    h6: {
      fontSize: "20px",
      fontWeight: 300
    },

    fontFamily: "Poppins, sans-serif",

    subtitle1: {
      margin: "20px 0px"
    }
  }
});


const styles = (theme: Theme) => ({
  // Define your styles here

  main: {
    backgroundColor: "#F6F6F6"
  },
  BannerHeading: {
    fontSize: "46px",
    fontWeight: 700,
    lineHeight: "65px",
    color: "#012677",
    [theme.breakpoints.down("sm")]: {
      fontSize: "39px",
      lineHeight: "44px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px"
    }
  },
  communicationSubheading: {
    background: "linear-gradient(270deg, #024EAF 33.66%, #1BC0FF 96.97%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    textFillColor: "transparent"
  },
  bannerParagraphStyle: {
    fontSize: "24px",
    color: "#102126",
    lineHeight: "36px",
    marginTop: "38px",
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "28px"
    }
  },
  bannerSection: {
    paddingTop: "150px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px"
    }
  },
  btnContainer: {
    display: "flex",
    gap: "38px",
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      gap: "18px",
      marginBottom: "30px"
    }
  },
  primaryBtn: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#102126",
    padding: "8px 51px",
    border: "2px solid #012677",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 30px",
      fontSize: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 15px",
      fontSize: "15px"
    }
  },
  secondaryBtn: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#fff",
    padding: "8px 51px",
    border: "2px solid #012677",
    borderRadius: "10px",
    backgroundColor: "#012677",
    fontFamily: "Poppins",
    textTransform: "none" as "none",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 30px",
      fontSize: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 15px",
      fontSize: "15px"
    },
    "&:hover": {
      backgroundColor: "#012677" // Set the hover background color to be the same as the default color
    }
  },
  whyChooseUs: {
    color: "#102126",
    fontSize: "48px",
    fontStyle: "normal",
    marginTop: "100px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px"
    }
  },
  carouselContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "67px",
    margin: "32px 0"
  },
  sponserContainer: {
    marginTop: "100px"
  },
  bannerImage: {
    position: "relative" as "relative"
  },
  topText: {
    position: "absolute" as "absolute",
    left: "136px",
    top: "37px",
    color: "#285BCC",
    [theme.breakpoints.down("xs")]: {
      top: "11px"
    }
  },
  bottomText: {
    position: "absolute" as "absolute",
    right: "110px",
    bottom: "56px",
    color: "#285BCC",
    [theme.breakpoints.down("xs")]: {
      right: "63px",
      bottom: "28px"
    }
  },
  partnerContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: "149px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "55px",
    marginBottom: "120px"
  },
  marginTop_70: {
    marginTop: "70px"
  },
  paddingBottom_70: {
    paddingBottom: "70px"
  },
  serviceListContainer: {
    display: "flex",
    gap: "45px",
    flexWrap: "wrap" as "wrap",
    justifyContent: "center" as "center"
  },
  centerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
// Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { classes } = this.props;

    const servicesList = [
      {
        title_1: "One of the best",
        title_2: "ISP Class product",
        img: badge
      },
      {
        title_1: "Best in terms",
        title_2: "of Security",
        img: security
      },
      {
        title_1: "Unified Communication",
        title_2: "is bundled along",
        img: communication
      },
      {
        title_1: "Best in Class ",
        title_2: "Mobility Solutions",
        img: mobility
      },
      {
        title_1: "24 X 7 support ",
        title_2: "",
        img: security
      }
    ];

    return (
      // Customizable Area Start
      <Translation>

        {t =>    <>
        <AppHeader navigation={this.props.navigation}>
          <Box className={classes.main}>
            <Box className={classes.bannerSection}>
              <Container>
                {this.state.landingPage.section1.status &&
                  <Grid container>
                    <Grid item md={6}>
                      <Box>
                        <Typography
                          variant="h4"
                          className={classes.BannerHeading}
                        >

                          {this.state.landingPageTitle[0]}
                          <Box
                            component={"span"}
                            className={classes.communicationSubheading}
                          >
                            {" "}
                            {this.state.landingPageTitle[1]}
                          </Box>
                          {this.state.landingPageTitle[2]}
                        </Typography>

                        {this.state.landingPage.section1.img_content &&
                          <Typography className={classes.bannerParagraphStyle}>
                            <Box dangerouslySetInnerHTML={{__html:this.state.landingPage.section1.img_content[0].content }}/>
                            
                          </Typography>}

                      </Box>
                      <Box className={classes.btnContainer}>
                        <Button
                          className={classes.primaryBtn}
                          data-test-id="pricingBtn"
                          onClick={this.goToPricing}
                        >
                          {t('seePricing')}
                        </Button>
                        <Button className={classes.secondaryBtn}
                        data-test-id="getDemoBtn"
                        onClick={this.gotToDemo}>
                          {t('getDemo')}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box className={classes.bannerImage}>
                        <Typography className={classes.topText}>
                          {this.state.landingPage.section1.top_text}
                        </Typography>
                        {this.state.landingPage.section1.img_content && <img
                          src={this.state.landingPage.section1.img_content[0].image}
                          width="100%"
                          alt="banner"
                        />}
                        <Typography className={classes.bottomText}>
                          {this.state.landingPage.section1.bottom_text}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>}

              </Container>

              {this.state.landingPage.section2.status && <Box className={classes.marginTop_70}>
                <Container>
                  <Box className={classes.paddingBottom_70}>
                    <Typography align="center" className={classes.whyChooseUs}>
                      {this.state.landingPage.section2.title}
                    </Typography>
                  </Box>
                  <Box className={classes.serviceListContainer}>
                    {this.state.landingPage.section2.img_content.map((item: any) => (
                      <Box key={item.content}>
                        <Box className={classes.centerImage}>
                          <img
                            src={item.image}
                            alt={item.content}
                            height="200"
                            width="200"
                          />
                        </Box>

                        <Typography
                          align="center"
                          variant="h5"
                          style={{ marginTop: "32px", maxWidth: '200px' }}
                        >
                          <Box dangerouslySetInnerHTML={{__html: item.content}}/>
                          
                         </Typography>
                      </Box>
                    ))}
                  </Box>
                </Container>
              </Box>}

              {this.state.landingPage.section3.status &&
                <Box className={classes.carouselContainer}>
                  <Box>
                    <Typography align="center" className={classes.whyChooseUs}>
                      {this.state.landingPage.section3.title}
                    </Typography>
                  </Box>
                  <CarouselDisplay navigation={this.props.navigation} id={'landingPageCarousel'} slides={this.state.landingPage.section3.img_content || []}/>
                  
                </Box>}


              <Container>
                {this.state.landingPage.section4.status &&
                  <Box className={classes.sponserContainer}>
                    <Box>
                      <Typography align="center" className={classes.whyChooseUs}>
                        {this.state.landingPage.section4.title}
                      </Typography>
                    </Box>

                    <Box className={classes.partnerContainer}>

                      {this.state.landingPage.section4.img_content.map((item: any, index: number) => (
                        <Box key={index}>
                          <img src={item.image} alt={item.image} />
                        </Box>
                      ))}


                    </Box>
                  </Box>}

              </Container>
            </Box>
          </Box>
        </AppHeader>
      </>  }
      </Translation>
   
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(LandingPage);

// Customizable Area End
