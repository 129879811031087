import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";



interface CreateAccount {
  companyName: string;
  country: string;
  emailAddress: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  phone: string;
}

 interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  type?:string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any,
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  checked: boolean;
  initialValues: {
    name: string;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    acceptTerms: boolean;
  },
  EmailAlreadyExists: boolean;
  errorMessage: string;
  showModal: boolean;
  termAndConditionOpen: boolean;
  createAccount: boolean;
  token: string;
  createProfileModal:boolean;
  userImage: null | string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  signUpId: string = '';
  termsAndConditionApiId: string = "";
  createProfileId: string = "";
  fileInputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      checked: false,
      initialValues: {
        name: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
      },
      EmailAlreadyExists: false,
      errorMessage: '',
      showModal: false,
      termAndConditionOpen: false,
      createAccount: false,
      token: "",
      createProfileModal: false,
      userImage:null


      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsons = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponses = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorResponses", responseJsons.error)

      if (apiRequestCallIds && responseJsons) {
        if (apiRequestCallIds === this.validationApiCallId) {

          this.validationCall(responseJsons)

        } else if (apiRequestCallIds === this.createAccountApiCallId) {

          this.createAccountSuccess(responseJsons)

        }
        else if (apiRequestCallIds === this.signUpId) {

          this.signUpSuccess(responseJsons)
        }
        else if (apiRequestCallIds === this.createProfileId) {
            this.createProfileSuccess(responseJsons)
        }
        this.parseApiCatchErrorResponse(errorReponses);
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.getOtpAuth(message)
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.getSelectedCountryCode(message)
    }

  
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getToken();
    
  }



  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };



  FormSchema = (t:Function)=>  Yup.object().shape({
    name: Yup.string().required(t('nameRequired'))
      .transform(value => value.trim()) // Trim leading and trailing spaces
      .matches(/^[A-Za-z ]+$/, t('nameLetterOnly')),
    emailAddress: Yup.string().email(t('errorEmailInvalid')).required(t('errorEmailRequired')),
    password: Yup.string()
      .min(8, t('passwordMinLenght'))
      .max(50, t('passwordMaxLength')) // Max length validation
      .test(t('noEmpty'), t('passwordCannotSapace'), value => !/\s/.test(value)) // Custom validation for empty spaces
      .required(t('passwordIsRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('passwordNotMatch'))
      .required(t('cofirmPassword')),
    acceptTerms: Yup.boolean()
      .oneOf([true], t('acceptTerms'))

  });

  getSignUpData = async(values: any) => {
     removeStorageData('authToken')
  

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBodys = JSON.stringify({
      data: {
        type: "email_account",
        attributes: {
          name: values.name,
          email: values.emailAddress,
          password: values.password,
          password_confirmation: values.confirmPassword,
          activated: true
        }
      },
    });


    const getDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signUpId = getDataMsgs.messageId;


    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodys
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpEndPoint
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getDataMsgs.id, getDataMsgs);

    console.log("httpsBody", httpBodys);


  }


  signUpSuccess = async(responseJson: any) => {
    
   if (!responseJson.errors && !responseJson.error) {
      this.setState({
        showModal: true,
        EmailAlreadyExists: false
      })
     
    await  setStorageData('authToken', responseJson.meta.token)

    } 
    else if(responseJson.error){
       this.setState({
        EmailAlreadyExists:false
       })
       alert(responseJson.error)
       return 
    }
    
    else {
      this.setState({
          EmailAlreadyExists: true
        })
      return
    }
 }

  createAccountSuccess = (responseJsons: any) => {
    if (!responseJsons.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    }
    else {
      //Check Error Response
      this.parseApiErrorResponse(responseJsons);
    }

  }

  validationCall = (responseJsons: any) => {
    this.arrayholder = responseJsons.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  getOtpAuth = (message: any) => {

    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }


  }

  getSelectedCountryCode = (message: any) => {
    const selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  goToLogin = () => {
    this.props.navigation.navigate('LoginWithStyles')
  }

  handleTerms = () => {

    this.setState({
      ...this.state,
      termAndConditionOpen: !this.state.termAndConditionOpen
    })

  }
  handleCloseModal = () => {

    this.setState({
      showModal: false,
      termAndConditionOpen: false,
      createAccount: true
    })



  }





  updateStateWithFieldValues = (newValues: any) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...newValues,
      },
    });
  };


  createProfile = async(values: CreateAccount) => {
    const { firstName,
      lastName,
      companyName,
      emailAddress,
      jobTitle,
      country,
      phone } = values


  const  formdata = new FormData();
    formdata.append("name", firstName + " " + lastName);
    formdata.append("company_name", companyName);
    formdata.append("email", emailAddress);
    formdata.append("job_title", jobTitle);
    formdata.append("country", country);
    formdata.append("full_phone_number", phone);

    if (this.state.userImage) {
     
      const url = await fetch(this.state.userImage);
      const blob = await url.blob();
      const file = new File([blob], "image.jpg", { type: blob.type });

      formdata.append("image", file );
    }

    this.createProfileId = await this.callApis({
      contentType: "application/json",
      method: "PUT",
      endPoint: configJSON.createProfileUrl,
      body: formdata,
      type:"formData"
    })

  }
  



  callApis = async (data: APIPayloadType) => {

    const {  method, endPoint, body } = data;
    const header = {
      token: this.state.token || await getStorageData('authToken')
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessages.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  };


  createProfileSuccess = (responseJson:any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({
        createProfileModal: true,
        EmailAlreadyExists: false
      })

    

    } 
    else {
        alert(responseJson.erorr || responseJson.errors[0])
    
    }   
    
  }


  getToken =  async()=>{
   
    const token = await getStorageData('authToken');
    
    this.setState({ token })


  }


  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }


  handleCreateProfileModal = ()=>{
      this.setState({
           createProfileModal:false
      })

      this.props.navigation.navigate('LoginWithStyles');
  }


  handleImageChange = (e:any) => {
    const file = e.target.files[0];

    if (file) {
      // You can now handle the uploaded image here
      // For example, you can display the selected image or upload it to a server.
      const reader = new FileReader();
      reader.onload = (e:any) => {
        if (e.target.result) {
        this.setState({ userImage: e.target.result });
          
        }
      };
      reader.readAsDataURL(file);
    }
  }


  handleEditImageClick = () => {
    // Trigger a click event on the hidden file input element
    this.fileInputRef.current.click();
  };


  CreateAccountSchema = Yup.object().shape({
    firstName: Yup.string().required('*Name is required')
      .transform(value => value.trim()) // Trim leading and trailing spaces
      .matches(/^[A-Za-z ]+$/, 'FirstName can only contain letter'),
      lastName: Yup.string().required('*Name is required')
      .transform(value => value.trim()) // Trim leading and trailing spaces
      .matches(/^[A-Za-z ]+$/, 'LastName can only contain letter'),

    emailAddress: Yup.string().email('*Invalid Email ID').required('*Email is required'),
    companyName: Yup.string()
    .transform(value => value.trim()) // Trim leading and trailing spaces
    .matches(/^[A-Za-z ]+$/, 'Company Name can only contain letter'),
    
    jobTitle: Yup.string()
    .transform(value => value.trim()) // Trim leading and trailing spaces
    .matches(/^[A-Za-z ]+$/, 'JobTitle Name can only contain letter'),

    country: Yup.string()
    .transform(value => value.trim()) // Trim leading and trailing spaces
    .matches(/^[A-Za-z ]+$/, 'Country Name can only contain letter'),

    phone: Yup.string()
    .transform(value => value.trim()) // Trim leading and trailing spaces
    .matches(/^\d+$/, 'Phone number can only contain numeric characters'),



  });

  // Customizable Area End
}
