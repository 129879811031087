import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { faceBook ,linkdein, twitter, youtube, instagram } from "./assets"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AffiliateUrlController, {
  Props,
  configJSON,
} from "./AffiliateUrlController";

export default class AffiliateUrl extends AffiliateUrlController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        {this.state.socialMedia.map((item) => {

          if (item.attributes.name.toLowerCase() === configJSON.facebook.toLowerCase() && item.attributes.status === configJSON.active) {
            return <Box component={'li'}>
              <a href={item.attributes.link} target="_blank">
                <img src={faceBook} alt={item.attributes.name} />
              </a>
            </Box>
          }

          if(item.attributes.name.toLowerCase() === configJSON.linkDein.toLowerCase() && item.attributes.status === configJSON.active){
            return <Box component={'li'}>
            <a href={item.attributes.link} target="_blank">
              <img src={linkdein} alt={item.attributes.name} />
            </a>
          </Box>
          }

          if(item.attributes.name.toLowerCase() === configJSON.youtube.toLowerCase() && item.attributes.status === configJSON.active){
            return <Box component={'li'}>
            <a href={item.attributes.link} target="_blank">
              <img src={youtube} alt={item.attributes.name} />
            </a>
          </Box>
          }


          if(item.attributes.name.toLowerCase() === configJSON.insta.toLowerCase() && item.attributes.status === configJSON.active){
            return <Box component={'li'}>
            <a href={item.attributes.link} target="_blank">
              <img src={instagram} alt={item.attributes.name} />
            </a>
          </Box>
          }


          if(item.attributes.name.toLowerCase() === configJSON.twitter.toLowerCase() && item.attributes.status === configJSON.active){
            return <Box component={'li'}>
            <a href={item.attributes.link} target="_blank">
              <img src={twitter} alt={item.attributes.name} />
            </a>
          </Box>
          }
          


          

        


        })}
 
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
