Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.userProfileDataApiEndPoint = "account_block/accounts/profile_show";
exports.btnExampleTitle = "CLICK ME";
exports.editProfileApiMethodType = "PUT";
exports.formCancelButtonText = "Cancel";
exports.formSaveButtonText = "Save changes";
exports.modalEditProfileHeading = "Edit Profile Picture";
exports.modalUploadButton = "Upload New Profile Picture";
exports.modalRemoveButton = "Remove Current Profile Picture";
exports.errorFirstNameText = "*First Name is required";
exports.errorLastNameText = "*Last Name is required";
exports.errorCompanyNameText = "*Company Name is required";
exports.errorJobTitleText = "*Job Title is required";
exports.errorCountryNameText = "*Country Name is required";
exports.errorInvalidEmailText = "*Invalid Email ID";
exports.errorRequiredEmailText = "*Email is required";
exports.errorTypePhoneNumberText = "*It should be a number";
exports.errorLengthPhoneNumberText = "*It should be 10 digit";
exports.errorPhoneNumMoreText = "lessThanTen";
exports.errorPhoneNumLessText = "moreThanTen";
exports.errorPhoneMessageStarting = "startingDigit";
exports.errorPhoneStarting = "*Number start with 6,7,8 or 9";
exports.errorRequiredPhoneNumberText = "*Phone Number is required";
exports.submitSuccessText = "Submitted Successfully";
exports.okButtonText = "Ok"
exports.phoneNumberRegex = /^\d{10}$/;
exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
exports.firstNameRegex = /^[a-zA-Z\s\-]+$/;
exports.errorFirstNameValid = "*Fist Name can contain only letters";
exports.errorLastNameValid = "*Last Name can contain only letters";
// Customizable Area End