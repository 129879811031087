import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import * as Yup from "yup";
import 'moment/locale/ar';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  gotoNext: boolean;
  currentDate: any;
  selectedDate: any;
  serviceId: string;
  initialValues: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    company: string,
    numberOfEmployees: string,
    termCondition: boolean
  },
  availableTimeSlots: { id: string, startTime: string }[],
  selectedTimeId: string,
  isFormSubmitted: boolean,
  termConditionOpen: boolean,
  langKeys: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestDemoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTimeSlotsId: string = "";
  bookDemoId: string = "";
  langKey:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      gotoNext: false,
      currentDate: new Date(),
      selectedDate: null,
      serviceId: '',
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        numberOfEmployees: '',
        termCondition: false
      },
      availableTimeSlots: [],
      selectedTimeId: '',
      isFormSubmitted: false,
      termConditionOpen: false,
      langKeys:'en'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponseMessage(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  gotoNextStep = () => {
    this.setState({
      gotoNext: true
    })
  }



  nextMonth = () => {
    const nextDate = new Date(this.state.currentDate);
    nextDate.setMonth(nextDate.getMonth() + 1);
    this.setState({
      currentDate: nextDate
    })
  };


  prevMonth = () => {
    const prevDate = new Date(this.state.currentDate);
    prevDate.setMonth(prevDate.getMonth() - 1);
    this.setState({
      currentDate: prevDate
    })
  };

  months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  daysOfWeek: string[] = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];


  getDaysInMonth = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  getFirstDayOfWeek = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  handleDateClick = (date: Date) => {
    
    this.setState({
      selectedDate: date
    })
  };


  async componentDidMount(): Promise<void> {

    this.setState({
      selectedDate: this.state.currentDate,
      serviceId: this.props.navigation.getParam('id'),
    })
    this.getTimeSlots(moment(new Date()).locale('en').format('D/M/YYYY'))
  }


  FormSchema = (t:Function)=> Yup.object().shape({
    firstName: Yup.string().required(t('firstNameRequired'))
      .transform(value => value.trim()) // Trim leading and trailing spaces
      .matches(/^[A-Za-z ]+$/, t('firstNameLetterOnly')),
    lastName: Yup.string().required(t('lastNameRequired'))
      .transform(value => value.trim()) // Trim leading and trailing spaces
      .matches(/^[A-Za-z ]+$/, t('lastNameLetter')),

    email: Yup.string().email(t('errorEmailInvalid')).required(t('errorEmailRequired')),
    phone: Yup.string()
       .matches(/^[0-9+]+$/, t('phoneNumberInvalid'))
      .test(t('noEmpty'), t('phoneNumberCannotSpace'), value => !/\s/.test(value)) // Custom validation for empty spaces
      .required(t('errorPhoneRequired')),
    company: Yup.string()
      .required(t('companyNameRequired')),
    numberOfEmployees: Yup.number().required(t('employeeCannotEmpty')),
    termCondition: Yup.boolean()
      .oneOf([true], t('acceptTerms'))

  });


  getTimeSlots = (date: string) => {



    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const apiEndPoint = `${configJSON.timeSlotsApiEndPoint}?service_id=${this.props.navigation.getParam('id')}&date=${date}`;
    const getTimeSlots = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeSlotsId = getTimeSlots.messageId;

    getTimeSlots.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    getTimeSlots.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getTimeSlots.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTimeSlots.id, getTimeSlots);

  }


  private handleApiResponseMessage(message: Message) {


    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId !== null) {


      if (apiRequestCallId === this.getTimeSlotsId && responseJson !== null) {


        this.timeSlots(responseJson)


      }

      if (apiRequestCallId === this.bookDemoId && responseJson !== null) {
         if(responseJson.errors && responseJson.errors.length > 0){
          alert(responseJson.errors[0]);
          
         }
        else if (!responseJson.error || !responseJson.errors || responseJson.errors.length===0) {
          this.setState({
            isFormSubmitted: true,
            gotoNext: false
          })
        }
       





      }
      this.parseApiCatchErrorResponse(errorResponse)
    }
  }


  timeSlots = (response: any) => {


    const newTimeSlots = response.data.map((item: any) => {

      const obj = { id: item.id, startTime: item.start_time }
      return obj

    })


    this.setState({
      availableTimeSlots: newTimeSlots
    })


  }


  bookDemo = () => {


    if (this.state.selectedTimeId === '') {
      alert('Please select time slots')
      return
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
   
    const httpBodys = JSON.stringify({
      data: {
        attributes: {
          "service_id": this.state.serviceId,
          "first_name": this.state.initialValues.firstName,
          "last_name": this.state.initialValues.lastName,
          "mobile_no": this.state.initialValues.phone,
          "book_date": moment(this.state.selectedDate).locale('en').format('DD-MM-YYYY'),
          "location": "Raipur",
          "book_time_id": this.state.selectedTimeId,
          "email": this.state.initialValues.email
        }
      },

    });

    const getDataMsgs = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.bookDemoId = getDataMsgs.messageId;


    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodys
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bookDemoEndPoint
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getDataMsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getDataMsgs.id, getDataMsgs);



  }

  
  gotoHomepage = ()=>{
    this.props.navigation.navigate('Home')
  }


  handleTermsCondition = () => {

    this.setState({
      termConditionOpen: !this.state.termConditionOpen,
    })



  }


  updateStateWithFieldValues = (newValues: any) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...newValues,
      },
    });
  };


 
  // Customizable Area End
}
