Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.postContactApiEndPOint = "bx_block_contact_us/contacts";
exports.token = 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTUsImV4cCI6MTY5NzAyNjM3Nn0.aSW9aNfSFIoJ_jIYBgq99eD-kl3y8Vrxfhq5B36nUDehfC8KCl9x_SGK0l8YltMOeY1EHvMwFxboJEqdHkJaow'

exports.contactUsWebLabel="Contact Us";
exports.contactUsBodyText="Our team is happy to answer your sale queries, send us an inquiry and we will be in touch shortly."
exports.formHeadingText="Please enter your organization’s information and we will contact you";
exports.submitButtonText="Submit";
exports.submitSuccessfullText="Submitted Successfully";
exports.modalOkButtonText="Ok";

exports.errorOrganisationName="*Organisation Name is required";
exports.errorApplicantName="*Applicant Name is required";
exports.errorEmailRequired="*Email is required";
exports.errorEmailInvalid="*Invalid Email ID";
exports.errorPhoneType="*Should be a number";
exports.errorPhoneDecimal="*A phone number can't include a decimal point";
exports.errorPhoneLength="*It should be 10 digit";
exports.errorPhoneMessageLess="lessThanTen";
exports.errorPhoneMessageMore="moreThanTen";
exports.errorPhoneMessageSpace="no-empty-spaces";
exports.errorPhoneMessageStarting="starting digit";
exports.errorPhoneSpace="*Phone No. cannot contain empty spaces";
exports.errorPhoneStarting="*Phone number invalid";
exports.errorPhoneRequired="*Phone number is required";
exports.errorMessageRequired="*Message is required";


// Customizable Area End