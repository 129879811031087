import React from "react";
// Customizable Area Start



import {
    Box,
    Button,
    Typography,
    Grid,
    Container
  } from "@material-ui/core";





  import {Translation} from "react-i18next"

  import {tick}  from "./assets"
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
   // Customizable Area Start
    // Customizable Area End
} from "./CustomisableusersubscriptionsController";



export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
            <Translation>
                {t =>     <Container>
                
                  <Grid container spacing={10} justifyContent={'center'}>
                    {this.props.availablePlans && this.props.availablePlans.map((item:any) => item.visiblity && (
                      <Grid item xs={12} sm={6} md={4} spacing={0} >
                        <Box style={webStyle.cardParentContainer}>
                          <Typography style={webStyle.standard}>{item.name}</Typography>
                          <Typography style={webStyle.easy}>{item.content}</Typography>
                          <Box style={webStyle.saveParentContainer}>
                            <Typography style={{...webStyle.easy,...webStyle.lineThrough}} >{item.previousAmount}</Typography>
                            <Typography style={webStyle.standardLow}>{item.discountAmount}</Typography>
                          </Box>


                          <Box>
                            <Typography style={webStyle.mainPrice}>
                              {item.price}
                            </Typography>

                            <Typography style={webStyle.userMonth}>
                              {item.priceContent}
                            </Typography>
                          </Box>

                          <Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                              {item.features && item.features.map((item:any) => item.attributes.visiblity && item.attributes.name !=="" && (
                                <Box style={{ display: 'flex', alignItems: 'baseline', gap: '22px' }}>
                                  <Box>
                                    <img src={tick} alt="" />
                                  </Box>

                                  <Typography style={webStyle.menuList}>
                                    {item.attributes.name}
                                  </Typography>
                                </Box>

                              ))}
                            </Box>
                          </Box>
                          <Button style={webStyle.bookDemoBtn} data-test-id="bookDemo" onClick={() => this.gotoDemo(this.props.virutalPbx || '1')}>
                            {t('bookDemo')}
                          </Button>
                        </Box>

                      </Grid>

                    ))}

                  </Grid>
                </Container>}
            </Translation>
          
        </>
      // Customizable Area End
    );
  }
}



// Customizable Area Start
const webStyle = {
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
    inputStyle: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    buttonStyle: {
      width: "100%",
      height: "45px",
      marginTop: "40px",
      border: "none",
      backgroundColor: "rgb(98, 0, 238)",
    },
    cardParentContainer: {
        flexDirection: "column" as 'column',
        display: "flex",
        gap: "15px",
        background: "linear-gradient(45deg, #BED8E6 0%, rgba(246, 246, 246, 0.49) 100%)",
        filter: "drop-shadow(0px 2px 30px rgba(16, 33, 38, 0.20))",
        borderRadius: "8px",
        maxWidth: "295px",
        padding: "31px"
      },
      marginTop200: {
        marginTop: "200px"
      },
      standard: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal"
      },
      easy: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 300,
    
      },
      saveParentContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '28px'
      },
      lineThrough: {
        textDecorationLine: 'line-through',
        lineHeight: '30px'
      },
      standardLow: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "36px",
        marginLeft: "18px"
      },
      mainPrice: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal"
      },
      userMonth: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal"
      },
      menuList: {
        color: "#102126",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal"
      },
      bookDemoBtn: {
        backgroundColor: "#F6F6F6",
        borderRadius: "10px",
        textTransform: 'none' as 'none',
        fontFamily: 'Poppins',
        fontSize: "20px",
        fontWeight: 500,
        '&:hover': {
          backgroundColor: '#F6F6F6;', // Set the hover background color to be the same as the default color
        }
      },
  };
// Customizable Area End
