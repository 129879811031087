import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Theme,
  withStyles,
  createTheme,
  ThemeProvider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { backArrow } from "./assets";
import {Translation} from 'react-i18next'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
  },

  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    h3: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '60px',
    },
  },
});

// Customizable Area End

import TermsAndConditionsController, {
  Props,
  configJSON,
} from "./TermsAndConditionsController";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {

     const MainContainer = withStyles((theme: Theme) => ({
      root: {
        padding: "80px",
        backgroundColor:'#f6f6f6',
        height:'100%',
        "@media (max-width: 600px)": {
          padding: "10px",
         
        },
      },
    }))(Box);

   const TermsAndBodyContainer =  withStyles((theme:Theme)=> ({
      root:{
        height: "500px",
        color: "#102126",
        fontSize: "20px",
        fontWeight: 300,
        lineHeight: "28px",
        overflow: "auto",
        paddingRight: "40px",
        "@media (max-width:450px)": {
          paddingRight: 0,
          height:"300px"
        }
      }
    }))(Box)


    const MainHeadingText =  withStyles((theme:Theme)=> ({
      root:{
        color: "#102126",
        fontSize: "48px",
        fontWeight: 500,
        width: "100%",
        textAlign: "center" as 'center',
        "@media (max-width: 600px)": {
          fontSize: "24px",
        },
      }
    }))(Typography)

    


    return (
      <Translation>
        {t=>   <ThemeProvider theme={theme}>
        {this.state.termsAndCondition.content !== '' &&  <MainContainer>
          <Box>
            <Box style={webStyle.headingBoxContainer}>
              <img
                data-test-id="goBackButtonTestID"
                src={backArrow}
                style={webStyle.backArrowImage}
                onClick={this.props.closeTermsAndCondition}
              />
              <MainHeadingText data-test-id="title">
                {this.state.termsAndCondition.title}
              </MainHeadingText>
            </Box>
            <Typography style={webStyle.lastUpdateTypography}>
              {t('lastUpdateText')} {this.state.termsAndCondition.meta_title}.
            </Typography>
            <TermsAndBodyContainer>
            <div dangerouslySetInnerHTML={{ __html: this.state.termsAndCondition.content }} />
           </TermsAndBodyContainer>
          </Box>
              
      

          </MainContainer> }
       
      </ThemeProvider>}
      </Translation>
    
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    padding: "80px",
    backgroundColor:'#f6f6f6',
    "@media (max-width: 600px)": {
      padding: "10px",
    },
  },

  headingBoxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px"
  },

  headingTextTypography: {
    color: "#102126",
    fontSize: "48px",
    fontWeight: 500,
    width: "100%",
    textAlign: "center" as 'center',
    "@media (max-width: 600px)": {
      fontSize: "24px",
    },
  },

  backArrowImage: {
    width: "24px",
    height: "13.973px",
    cursor: "pointer"
  },

  lastUpdateTypography: {
    color: "#102126",
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "30px"
  },

  mainTermBodyTextBox: {
    height: "500px",
    color: "#102126",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "28px",
    overflow: "auto",
    paddingRight: "40px",
    "@media (max-width:450px)": {
      paddingRight: 0
    }
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "100px",
    marginTop: "90px",
    "@media (max-width:450px)": {
      gap: "50px",
    },
    "@media (max-width:400px)": {
      flexDirection: "column",
      gap: "30px",
    },

    "& .MuiButton-root": {
      fontSize: "20px",
      fontWeight: 500,
      width: "163px",
      height: "48px",
      textTransform: "capitalize",
      borderRadius: "10px",
      "@media (max-width:400px)": {
        width: "100%"
      }
    },
  },

  declineButton: {
    display: 'flex',
    width: '163px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#102126',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    border: '1px solid #012677',
    borderRadius: '10px'
  },

  acceptButton: {
    color: "white",
    display: 'flex',
    width: '163px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: '10px',
    backgroundColor: "#012677",
    "&:hover": {
      backgroundColor: "#012677"
    }
  },
  buttonContainer: {
  display: 'flex',
  justifyContent: 'center',
  gap: '104px',
  marginTop:'98px'
  }
};
// Customizable Area End
