import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Container,
  Slider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, Theme, withStyles, createStyles } from "@material-ui/core/styles";
import { getStorageData } from "../../../framework/src/Utilities";

import AppHeaderWeb from "../../../components/src/AppHeader.web";
import { Translation } from "react-i18next";

import Customisableusersubscriptions from "../../customisableusersubscriptions/src/Customisableusersubscriptions.web"


import { tick, arrowRight, upArrows, downArrows, infoIcon, dotIcon } from "./assets"

export const cards = [{
  title: "Standard",
  subHeading: "Easy, quick start plan",
  price: "$18.95",
  save: "Save 25%",
  mainPrice: "$18.95",
  userMonth: "/user/month",
  inforIcon: infoIcon,
  img: tick,
  benefit: "Unlimited voice & video calling",
  btnText: "Book Demo"
},
{
  title: "Advance",
  subHeading: "Easy, quick start plan",
  price: "$18.95",
  save: "Save 25%",
  mainPrice: "$18.95",
  infoIcon: infoIcon,
  userMonth: "/user/month",
  img: tick,
  benefit: "Unlimited voice & video calling",
  btnText: "Book Demo"
},
{
  title: "Premium",
  subHeading: "Easy, quick start plan",
  price: "$18.95",
  save: "Save 25%",
  mainPrice: "$18.95",
  infoIcon: infoIcon,
  userMonth: "/user/month",
  img: tick,
  benefit: "Unlimited voice & video calling",
  btnText: "Book Demo"
},
]



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif', // Replace 'Poppins' with the actual font name
    // You can also specify font weights and other typography styles here
    h3: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '60px',
    },
    // Add other typography elements as needed
  },
});

const styles = (theme: Theme) => createStyles({
  mainContainer: {
    backgroundColor: "#F6F6F6"

  },
  switchBtnsParentContainer: {

    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "66px",

  },
  marginTop66: {
    marginTop: "66px"
  },
  switchBtnMonthly: {
    borderRadius: "31px",
    backgroundColor: "#DFE8ED",
    color: "#012677",
    width: "200px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#DFE8ED;', // Set the hover background color to be the same as the default color
    }
  },
  switchBtnAnnualy: {
    borderRadius: "31px",
    backgroundColor: "#012677",
    color: "#F6F6F6",
    width: "161px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    transform: 'translateX(-30px)',
    transition: 'smooth ease-in-out',
    transitionDelay: '1s',
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },
  selectedSwtichBtnMonthly: {
    borderRadius: "31px",
    backgroundColor: "#012677",
    color: "#F6F6F6",
    width: "161px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    transform: 'translateX(22px)',

    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },

  usdBtn: {
    borderRadius: "31px",
    backgroundColor: "#012677",
    color: "#F6F6F6",
    width: "95px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    transform: 'translateX(36px)',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },
  selectedUsdBtn: {
    borderRadius: "31px",
    backgroundColor: "#012677",
    color: "#F6F6F6",
    width: "95px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    transform: 'translateX(-22px)',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },
  omrBtn: {
    borderRadius: "31px",
    backgroundColor: "#DFE8ED",
    color: "#012677",
    width: "130px",
    height: "48px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#DFE8ED;', // Set the hover background color to be the same as the default color
    }
  }
  ,

  save23: {
    color: "#012677",

    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
  },
  standard: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  easy: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 300,

  },
  lineThrough: {
    textDecorationLine: 'line-through',
    lineHeight: '30px'
  },
  standardLow: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "36px",
    marginLeft: "18px"
  },
  mainPrice: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },

  menuList: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal"
  },
  bookDemoBtn: {
    backgroundColor: "#F6F6F6",
    borderRadius: "10px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#F6F6F6;', // Set the hover background color to be the same as the default color
    }
  },
  cardParentContainer: {
    flexDirection: "column",
    display: "flex",
    gap: "15px",
    background: "linear-gradient(45deg, #BED8E6 0%, rgba(246, 246, 246, 0.49) 100%)",
    filter: "drop-shadow(0px 2px 30px rgba(16, 33, 38, 0.20))",
    borderRadius: "8px",
    maxWidth: "295px",
    padding: "31px"
  },
  userMonth: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal"
  },
  menuContainer: {
    gap: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  centerMenu: {
    gap: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  bookDemo2: {
    width: "163px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#012677",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 500,
    color: "#F6F6F6",
    marginTop: "10",
    '&:hover': {
      backgroundColor: '#012677;', // Set the hover background color to be the same as the default color
    }
  },
  collasableBoxContainer: {
    backgroundColor: "#012677", height: '88px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 43px',
  },
  adHocConfencing: {
    color: "rgba(16, 33, 38, 0.60)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  td: {
    width: "25%", textAlign: 'center'
  },
  activeBackgroundColorTd: {
    height: "88px", backgroundColor: "rgba(27, 192, 255, 0.08)"
  },
  inActiveBackgroundColorTd: {
    height: "88px",
  },
  collapsableText: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  monthBtnParenContainer: {
    display: "flex",
    gap: "22px",
    alignItems: 'center'
  },
  numberOfUserParentContainer: {
    display: "flex",
    gap: "44px",
    alignItems: "center",
    marginTop: "46px",
    marginBottom:"100px"
  },
  marginTop200: {
    marginTop: "200px"
  },
  easyQuick: {
    display: "flex",
    gap: "32px",
    alignItems: "center"
  },
  demoParentContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "160px",
    marginBottom: "22px",
  },
  comaperePlansParent: {
    display: "flex",
    justifyContent: "center"
  },
  comparePlansInner: {
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  collasibleParentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
    marginBottom: "160px"
  },
  infoIcon: {
    marginLeft: "12px",
    height: "14px",
    width: "14px",
    curosr: 'pointer'
  },
  saveParentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '28px'
  },
  compareText: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  plansContainer: {
    gap: '10px',
    display: 'flex',
    flexGrow: 1.5,
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'inherit'
  },
  downArrow: {
    cursor: 'pointer'
  },
  borderCollapse: {
    borderCollapse: 'collapse'
  },
  width50: {
    width: "50%"
  },
  "@media (max-width: 600px)": {

    switchBtnMonthly: {
      borderRadius: "31px",
      width: "100px",
      height: "30px",
      fontSize: "12px",
      fontWeight: 500,

    },
    switchBtnAnnualy: {
      borderRadius: "31px",
      width: "100px",
      height: "30px",
      fontSize: "12px",
      fontWeight: 500,
      transform: 'translateX(-19px)'
    },
    selectedSwtichBtnMonthly: {
      width: "100px",
      height: "30px",
      fontSize: '12px'
    },
    selectedUsdBtn: {
      width: "48px",
      height: "30px",
      fontSize: "12px"
    },

    save23: {
      fontSize: "12px",
    },
    usdBtn: {
      width: "48px",
      height: "30px",
      fontWeight: 500,
      transform: 'translateX(12px)',
      fontSize: "12px"
    },
    omrBtn: {
      width: "48px",
      height: "30px",
      fontWeight: 500,
      fontSize: "12px"
    },
    monthBtnParenContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px"
    },
    standardLow: {
      fontSize: "12px",

    },
    bookDemo2: {
      fontSize: "12px",
      width: '64px',
      height: '44px'
    },
    collapsableText: {
      fontSize: "12px"
    },
    adHocConfencing: {
      fontSize: "12px"
    },
    numberOfUserParentContainer: {
      flexDirection: 'column'
    },
    width50: {
      width: "90%"
    },
  },
});

const CustomSlider = withStyles({
  root: {
    height: 4,

  },
  rail: {
    background: "rgba(1, 38, 119, 0.40)",
    opacity: 0.7,
    height: 4,
    borderRadius: 7,
    overflow: "hidden"
  },
  track: {
    backgroundImage: `linear-gradient(180deg, #0ef7f1 0%, blue 100%)`,
    height: 4,
    borderRadius: 4,
    overflow: "hidden"
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "0px 0px 0px 8px rgba(255, 105, 135, 0.3)"
    }
  }
})(Slider);




// Customizable Area End

import CategoriessubcategoriesController, {
  Props,
  configJSON,
} from "././CategoriessubcategoriesController";

class PricingFinal extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.timer = null;
    this.langKey = this.getLangKey()
    this.setState({
      currentActiveTab: this.props.navigation.getParam('activeTab')
    })
    // Customizable Area End
  }

  // Customizable Area Start
  renderTable = (data: { feature: string, availablity: boolean[] }[]) => {
    const rows = [
      { label: configJSON.adHoc, class: this.props.classes.activeBackgroundColorTd },
      { label: configJSON.anonymousCall, class: this.props.classes.inActiveBackgroundColorTd },
      { label: configJSON.inBounding, class: this.props.classes.activeBackgroundColorTd },
      { label: configJSON.callCompletion, class: this.props.classes.inActiveBackgroundColorTd },
    ];

    return (
      <>
        {/* <table className={this.props.classes.borderCollapse}> */}
        {data.map((item, index) => (
          <TableRow key={index} className={rows[index].class}>
            <TableCell style={{ borderBottom: "none" }} className={this.props.classes.td}>
              <Box className={this.props.classes.adHocConfencing}>
                {item.feature}
              </Box>
            </TableCell>

            {

              item.availablity.map((availablity, cellIndex) => (
                <TableCell style={{ borderBottom: "none" }} key={cellIndex} className={this.props.classes.td}>

                  {availablity ? <Box style={{ fontSize: '24px' }}>&#10003;</Box> : <Box style={{ fontSize: '14px' }}>&#10060;</Box>}
                </TableCell>
              ))
            }

            {item.availablity.length == 2 && (
              <>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>
              </>


            )}
            {item.availablity.length == 1 && (
              <>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>

              </>


            )}
            {item.availablity.length == 3 && (
              <>
                <TableCell style={{ borderBottom: "none", width: "25%" }} className={this.props.classes.td}></TableCell>


              </>


            )}

          </TableRow>
        ))}
        {/* </table> */}
      </>
    );
  }

  getLangKey = async () => {
    return getStorageData('i18nextLng')
  }


  renderSlider = () => {



    const getValueStyles = (minValue: number, maxValue: number, userRange: string) => {



      const isActive = this.state.sliderValue > minValue && this.state.sliderValue < maxValue;
      return {
        ...webStyle.valuesStyles,
        color: isActive ? "#012677" : "rgba(1, 38, 119, 0.40)",
        fontWeight: isActive ? 500 : 300,
      };
    };

    const handleSliderChange = (event: any, newValue: number | number[]) => {
      this.setState({ sliderValue: newValue as number });

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.handleDebouncedInput();
      }, 1000);
    };





    return (
      <div>

        <CustomSlider style={this.state.zoomLevel * 100 === 100 ? { zoom: 100 * (100 / 67) + "%" } : {}} value={this.state.sliderValue} min={0}
          max={100} onChange={(event, newValue) => handleSliderChange(event, newValue)}
          aria-labelledby="continuous-slider" />
        <div style={webStyle.parentContainer}>
          {this.state.userRange.length > 0 && this.state.userRange.map((item: any, index: number) => item.visiblity && (
            <span key={index} style={getValueStyles(Number(item.min), Number(item.max), item.userRange)}>{item.userRange}</span>
          ))}



        </div>


      </div>

    );



  }

  renderPlanButton = (t: Function) => {
    return (
      <>
        {this.state.planId?.monthlyId.visiblity &&
          <Button data-test-id="monthly" className={this.state.selectedPlan.toLowerCase() === 'monthly' ?
            this.props.classes.selectedSwtichBtnMonthly : this.props.classes.switchBtnMonthly}
            onClick={() => this.selectedPlan('monthly', this.state.planId?.monthlyId.id)}>
            {t('monthly')}
          </Button>
        }
        {
          this.state.planId?.monthlyId.visiblity &&
          <Button
            className={this.state.selectedPlan.toLowerCase() === 'anually'
              ? this.props.classes.switchBtnAnnualy :
              this.props.classes.switchBtnMonthly} onClick={() => this.selectedPlan('anually', this.state.planId?.anuallyId.id)} >
            {t('anually')}
          </Button>
        }
      </>
    )
  }

  renderInnerContent = (t: Function) => {


    if (this.state.noPlans) {
      return (
        <Grid container justify="center">
          <Box data-test-id="noPlans" padding="80px">{this.state.noPlans}</Box>
        </Grid>

      )
    }


    return (
      <>
       { this.state.userRange.some((element)=>!element.visiblity)   &&  <Box style={{marginBottom:"80px"}}/>}
        <Customisableusersubscriptions virutalPbx={this.state.currentActiveTab || '1'} id="2" data={[]}
          availablePlans={this.state.availablePlans} navigation={this.props.navigation} route={""} />
        <Container className={this.props.classes.marginTop66}>
          <Box className={this.props.classes.comaperePlansParent}>
            {/* <Box className={this.props.classes.comparePlansInner}>
              <Typography className={this.props.classes.compareText}>
                {t('comparePlan')}
              </Typography>
              <Box>
                <img src={arrowRight} alt="" />
              </Box>
            </Box> */}
          </Box>

        </Container>



        <Container>

          <TableContainer style={{ marginTop: "160px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '25%' }} className={this.props.classes.standardLow}>
                    {t('allFeature')}
                    <Box style={{ height: "48px" }}></Box>
                  </TableCell>
                  {this.state.availablePlans?.map((item) => item.visiblity && (
                    <TableCell style={{ textAlign: "center", width: '25%' }} >
                      <Typography className={this.props.classes.standardLow}>{item.name}</Typography>
                      <Button className={this.props.classes.bookDemo2} data-test-id="bookDemo" onClick={() => this.gotoDemo(this.state.currentActiveTab || '1')}>
                        {t('bookDemo')}

                      </Button>

                    </TableCell>

                  ))}

                  {this.state.availablePlans?.length === 3 && <TableCell></TableCell>}
                  {this.state.availablePlans?.length === 2 && (
                    <>
                      <TableCell style={{ width: '25%' }}></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}

                  {this.state.availablePlans?.length === 1 && (
                    <>
                      <TableCell style={{ width: '25%' }}></TableCell>
                      <TableCell style={{ width: '25%' }}></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}


                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow style={{ backgroundColor: "#012677", height: '88px' }}>

                  <TableCell colSpan={4}><Box>
                    <Typography className={this.props.classes.collapsableText}>{t('voice')}</Typography>
                  </Box></TableCell>



                  <TableCell style={{ textAlign: "end" }}>
                    <Box>
                      <Box onClick={() => this.handleExpandTable("1")} className={this.props.classes.downArrow} data-test-id="collasbleTable">
                        {this.state.tableOpenState[0].open ? <img src={upArrows} alt="" /> : <img src={downArrows} alt="" />}
                      </Box>

                    </Box>

                  </TableCell>



                </TableRow>
               {this.state.tableOpenState[0].open && this.renderTable(this.state.voiceTable)}
              <TableRow data-test-id="table2" style={{ backgroundColor: "#012677", height: '88px' }}>
                  <TableCell colSpan={4}><Box>
                    <Typography className={this.props.classes.collapsableText}>{t('unifiedCommunication')}</Typography>
                  </Box></TableCell>


                  <TableCell style={{ textAlign: "end" }}>
                    <Box>
                      <Box onClick={() => this.handleExpandTable("2")} className={this.props.classes.downArrow} data-test-id="collasbleTable">
                        {this.state.tableOpenState[1].open ? <img src={upArrows} alt="" /> : <img src={downArrows} alt="" />}
                      </Box>

                    </Box>

                  </TableCell>


                </TableRow>

                {this.state.tableOpenState[1].open && this.renderTable(this.state.unifiedInstant)}

                <TableRow style={{ backgroundColor: "#012677", height: '88px' }}>
                  <TableCell colSpan={4}><Box>
                    <Typography className={this.props.classes.collapsableText}>{t('videoCollabration')}</Typography>
                  </Box></TableCell>
                   <TableCell style={{ textAlign: "end" }}>
                    <Box>
                      <Box data-test-id="table3" onClick={() => this.handleExpandTable("3")} className={this.props.classes.downArrow} >
                        {this.state.tableOpenState[2].open ? <img src={upArrows} alt="" /> : <img src={downArrows} alt="" />}
                      </Box>

                    </Box>

                  </TableCell>
              </TableRow>
                {this.state.tableOpenState[2].open
                  && this.renderTable(this.state.unifiedTable)}


              </TableBody>

            </Table>
          </TableContainer>


        </Container>



      </>
    )
  }

  renderCommonContent = (t: Function, classes: any) => {
    return (
      <Container>
        <Box className={classes.switchBtnsParentContainer}>
          <Box className={classes.monthBtnParenContainer}>
            <Box style={{ direction: 'ltr' }}>{this.renderPlanButton(t)}</Box>
          </Box>
          <Box>
            <Box style={{ direction: 'ltr' }}>
              <Button
              data-test-id="usd"
                className={
                  this.state.selectedCurrency.toUpperCase() === 'USD'
                    ? classes.usdBtn
                    : classes.omrBtn
                }
                onClick={() => this.selectedCurrency('USD')}
              >
                {t('usd')}
              </Button>
              <Button
                data-test-id="omr"
                className={
                  this.state.selectedCurrency.toUpperCase() === 'OMR'
                    ? classes.selectedUsdBtn
                    : classes.omrBtn
                }
                onClick={() => this.selectedCurrency('OMR')}
              >
                {t('omr')}
              </Button>
            </Box>
          </Box>
        </Box>
        {this.state.userRange.some((element) => element.visiblity) && (
          <Box className={classes.numberOfUserParentContainer}>
            <Box>{t('numberUsers')}</Box>
            <Box className={classes.width50}>{this.renderSlider()}</Box>
          </Box>
        )}

        
      </Container>
    );
  };


  renderVirtualPbx = (t: Function) => {
    const classes = this.props.classes;
    return (
      <Box key="1" className={classes.mainContainer}>
        {this.renderCommonContent(t, classes)}
        {this.renderInnerContent(t)}
      </Box>
    );
  };


  renderContactCentre = (t: Function) => {
    const classes = this.props.classes;
    return (
      <Box key="2" className={classes.mainContainer}>
        {this.renderCommonContent(t, classes)}
        {this.renderInnerContent(t)}
      </Box>
    );
  };

  renderCampainMangement = (t: Function) => {
    const classes = this.props.classes;
    return (
      <Box key="3" className={classes.mainContainer}>
        {this.renderCommonContent(t, classes)}
        {this.renderInnerContent(t)}
      </Box>
    );
  };


  renderActiveTabContent = (t: Function) => {

    return (
      <>
        {this.props.navigation.getParam('activeTab') === this.state.servicesIds[0] && this.renderVirtualPbx(t)}
        {this.props.navigation.getParam('activeTab') === this.state.servicesIds[1] && this.renderContactCentre(t)}
        {this.props.navigation.getParam('activeTab') === this.state.servicesIds[2] && this.renderCampainMangement(t)}
      </>
    )
  }





  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Translation>
          {t => <AppHeaderWeb navigation={this.props.navigation}>
            {this.state.loading ? <Box data-test-id="circularBox"
              display="flex"
              justifyContent="center"
              margin="20px 0px">
              <CircularProgress style={{color:"#012677"}} />
            </Box> : this.renderActiveTabContent(t)}
          </AppHeaderWeb>}
        </Translation>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  valuesStyles: {
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  parentContainer: {
    display: "flex",
    justifyContent: "space-between",
    direction: 'ltr' as 'ltr'
  }
}
export default withStyles(styles)(PricingFinal);

// Customizable Area End
