import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import { Translation } from 'react-i18next';
import { ProductContent } from "../src/ProductQuickviewController"
import Customisableusersubscriptions from "../../customisableusersubscriptions/src/Customisableusersubscriptions.web";

import {
  imageCloud,
  agile,
  cloud,
  manage,
  hostel,
  professional,
  quickDeploy,
  upfront,
  endPointImage,
  dotImage,
  omantel,
  tick,
  rightArrow,
  smartContactSectionOne,
  smartContactSectionThree,
  smartSelection,
  imageCloudMobile,
  sectionThreeMobile,
  sectionFourMobile
} from "./assets"
import { cards } from "../../categoriessubcategories/src/PricingFinal.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import { vendorList, rangeList } from "./ProductQuickviewController";
import { Formik, Form } from "formik";
import SuccessModal from "../../../components/src/SuccessModal.web";

const DemoBtn = withStyles((theme: Theme) => ({
  root: {
    borderRadius: '10px',
    background: '#012677',
    width: "238px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    marginTop: "40px",
    '&:hover': {
      background: '#012677',
      color: '#fff',
    }

  }
}))(Button)


const MainTitle = withStyles((theme: Theme) => ({
  root: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'

  }
}))(Typography)

const SubHeading = withStyles((theme: Theme) => ({
  root: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'

  }
}))(Typography)

const OfferBox = withStyles((theme: Theme) => ({
  root: {
    width: '100px',
    height: '102px',
    borderRadius: '15px',
    background: '#DFE8ED',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  }
}))(Box)

const OfferBoxContainer = withStyles((theme: Theme) => ({
  root: {
    maxWidth: '35%',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'


  }
}))(Box)

const OfferBoxHeading = withStyles((theme: Theme) => ({
  root: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap'

  }
}))(Typography)

const OfferBoxSubHeading = withStyles((theme: Theme) => ({
  root: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'

  }
}))(Typography)


const AdvanceFeautureContainer = withStyles((theme: Theme) => ({
  root: {
    marginTop: '200px',
    display: 'flex',
    flexDirection: 'column',
    gap: '56px'

  }
}))(Box)

const EndPointContianer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      // Apply these styles for screens with a width of 600px and above
      flexDirection: "column",
      justifyContent: 'center',
      alignItems: 'center'
    },

  }
}))(Box)


const EndPointTextContianer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  }
}))(Box)

const EndPointText = withStyles((theme: Theme) => ({
  root: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '30px'
  }
}))(Typography)

const VendorContainer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '72px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))(Box)


const RangeEndPointContainer = withStyles((theme: Theme) => ({
  root: {
    borderRadius: '15px',
    background: 'linear-gradient(45deg, rgb(190, 216, 230) 0%, rgba(246, 246, 246, 0.49) 156.22%)',
    boxShadow: 'rgba(16, 33, 38, 0.2) 0px 2px 30px 0px',
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    flexWrap: 'wrap',
    padding: '30px'
  }
}))(Box)



const RangePointBox = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: "270px",
    height: "228px",
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    borderRadius: '15px',
    background: '#E5E6E7',
    boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.20)',
    margin: "26px 0"
  }
}))(Box)



const AnotherDemoBtn = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    borderRadius: "10px",
    textTransform: 'none' as 'none',
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#F6F6F6;', // Set the hover background color to be the same as the default color
    }
  }
}))(Button)



const PlanContainer = withStyles((theme: Theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    gap: "15px",
    background: "linear-gradient(45deg, #BED8E6 0%, rgba(246, 246, 246, 0.49) 100%)",
    filter: "drop-shadow(0px 2px 30px rgba(16, 33, 38, 0.20))",
    borderRadius: "8px",
    maxWidth: "295px",
    padding: "31px"
  }
}))(Box)

const PlanMainHeading = withStyles((theme: Theme) => ({
  root: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  }
}))(Typography)


const PlanSubHeading = withStyles((theme: Theme) => ({
  root: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 300,
  }
}))(Typography)

const PlansCurrencyText = withStyles((theme: Theme) => ({
  root: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 300,
    textDecorationLine: 'line-through',
    lineHeight: '30px'
  }
}))(Typography)


const SaveText = withStyles((theme: Theme) => ({
  root: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "36px",
    marginLeft: "18px"
  }
}))(Typography)

const CurrencyMainText = withStyles((theme: Theme) => (

  {
    root: {
      color: "#102126",
      fontFamily: "Poppins",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal"
    }

  }
))(Typography)

const BenefitBoxContainer = withStyles((theme: Theme) => ({
  root: {
    gap: '21px',
    display: 'flex',
    alignItems: 'baseline',
    padding: '6px'
  }
}))(Box)


const InformationText = withStyles((theme: Theme) => ({
  root: {
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      // Apply these styles for screens with a width of 600px and above
      marginTop: "50px"
    },
  }
}))(Typography)


const InputBox = withStyles((theme) => ({
  root: {
    width: "100%",
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: '2px solid #dfe8ed !important',
      transition: 'none'
    },
    '&:after': {
      borderBottom: '2px solid #dfe8ed !important', // Change to the desired underline color for when the input is focused
    },
    '&:hover $underline:before, &:hover $underline:after, &:focus-within $underline:before, &:focus-within $underline:after': {
      borderBottom: '2px solid #dfe8ed !important', // Change to the desired underline color for hover and focus
    },
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  },
}))(Input);

const WriteUs = withStyles(() => ({
  root: {
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
  }
}))(Typography)



const MainTitleBox = withStyles(() => ({
  root: {
    display: 'flex',
    gap: "10px",
    marginTop: "90px",
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      // Apply these styles for screens with a width of 600px and above
      flexDirection: "column",
      justifyContent: 'center',
      alignItems: 'normal',
    },
    [theme.breakpoints.up('md')]: {
      // Apply these styles for screens with a width of 960px and above

    },
  }
}))(Box)

const ContactUsContainer = withStyles(() => ({
  root: {
    background: 'linear-gradient(45deg, rgb(2, 78, 175) 1.04%, rgb(27, 191, 254) 100.96%, rgb(27, 192, 255) 100.96%)',
    margin: '160px 0',
    display: 'flex',
    justifyContent: 'space-around',
    padding: "100px",
    [theme.breakpoints.down('sm')]: {
      // Apply these styles for screens with a width of 600px and above
      flexDirection: "column",
      padding: '22px',
      margin: "18px 0"
    },

  }
}))(Box)


const FormContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  }


}))(Box)


const ErrorTypography = withStyles(() => ({
  root: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#C30A2A',
  }


}))(Typography)


const Products =  {
  'virtualPbx' : '1',
  'smartContactCenter' : '2',
  'Voice of Customer Platform' : '3'

}

// Customizable Area End

import ProductQuickviewController, {
  Props,
  configJSON,
} from "./ProductQuickviewController";






export default class ProductQuickview extends ProductQuickviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // this.getServices();
    // this.callData(this.props.navigation.getParam('serviceId'))
    this.setState({
      currentServiceId: this.props.navigation.getParam('serviceId')
    })

    
  
    // Customizable Area End
  }

  // Customizable Area Start

  renderSection1 = (section1: ProductContent, t: Function) => {


    return (

      <>
        {section1 && section1.status && <MainTitleBox>
          <Box>
            <MainTitle>
              {section1 && section1.title}
            </MainTitle>
            <SubHeading>
              <div dangerouslySetInnerHTML={{ __html: section1.img_content[0].content }} />

            </SubHeading>

            <Box>
              <DemoBtn data-test-id="demoBtn1" onClick={() => this.props.navigation.navigate("RequestDemoWithStyles", { 'id': this.props.navigation.getParam('serviceId') })}>
                {t('getDemo')}
              </DemoBtn>
            </Box>
          </Box>

          <Box>
            <img style={{
              height: 'auto',
              width: '-webkit-fill-available'
            }} src={section1.img_content[0].image} alt="" />
          </Box>

        </MainTitleBox>}
      </>
    )


  }

  renderSection2 = (section2: ProductContent, t: Function) => {

    return (

      <>
        {section2 &&
          section2.status &&
          <Container style={{ marginTop: '126px' }}>

            <MainTitle style={{ textAlign: 'center', marginBottom: '50px' }}>
              <div dangerouslySetInnerHTML={{ __html: section2.title }} />
            </MainTitle>

            <Grid container spacing={10} justifyContent="center">

              {section2.img_content.map((item: any) => (
                <Grid item md={3} lg={4}>
                  <OfferBoxContainer>
                    <OfferBox>
                      <img src={item.image} alt={item.content} height='64px' />
                    </OfferBox>
                    <Box style={{ maxWidth: '250px' }}>
                      <OfferBoxHeading style={{ marginTop: "10px" }}>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      </OfferBoxHeading>
                      <OfferBoxSubHeading>
                        <div dangerouslySetInnerHTML={{ __html: item.sub_content }} />
                      </OfferBoxSubHeading>
                    </Box>
                  </OfferBoxContainer>
                </Grid>
              ))}
            </Grid>
          </Container>}


      </>

    )
  }

  renderSection3 = (section3: ProductContent, t: Function) => {
    return (<>
      {section3
        && section3.status &&
        <Container>
          <AdvanceFeautureContainer>
            <MainTitle style={{ textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: section3.title }} />
            </MainTitle>
            <EndPointContianer>
              <img src={section3.img_content[0].image} style={{ width: "100%", maxWidth:"650px" }} alt="" />
              <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {section3.img_content[0].content.split(',').map((item: string) => (

                  <>
                    <EndPointTextContianer>
                      <Box>
                        <img src={dotImage} alt="" />
                      </Box>
                      <EndPointText dangerouslySetInnerHTML={{ __html: item.replace(/<p>/g, '').replace(/<\/p>/g, '') }}>
                      </EndPointText>
                    </EndPointTextContianer>
                  </>

                ))}
              </Box>
            </EndPointContianer>
          </AdvanceFeautureContainer>
        </Container>}

    </>
    )
  }

  renderSection4 = (section4: ProductContent, t: Function) => {
    return (<>
      {section4 && section4.status && <Container>
        <AdvanceFeautureContainer>
          <MainTitle style={{ textAlign: "center" }}>
            {section4.title}
          </MainTitle>
          <VendorContainer>
            {section4.img_content.map((item: any) => (
              <Box key={item.content}>
                <img src={item.image} alt="" />
              </Box>
            ))}
          </VendorContainer>
        </AdvanceFeautureContainer>
      </Container>
      }
    </>
    )
  }

  renderSection5 = (section5: ProductContent, t: Function) => {

    return (
      <>
        {section5 && section5.status && <Container>
          <AdvanceFeautureContainer>

            <MainTitle style={{ textAlign: "center" }}>
              {section5.title}
            </MainTitle>
            {
              section5.img_content?.length>0 &&
              <EndPointContianer>
                <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  {section5.img_content[0]?.content?.split(',').map((item: string) => (

                    <div style={{marginBottom:"20px"}}>
                      <EndPointTextContianer>
                        <Box>
                          <img src={dotImage} alt="" />
                        </Box>
                        <EndPointText dangerouslySetInnerHTML={{ __html: item.replace(/<p>/g, '').replace(/<\/p>/g, '') }}>
                        </EndPointText>
                      </EndPointTextContianer>
                    </div>

                  ))}
                </Box>
                <img src={section5.img_content[0]?.image} style={{ width: "100%", maxWidth:'450px' }} alt={section5.img_content[0]?.content} />
              </EndPointContianer>
            }
          </AdvanceFeautureContainer>

        </Container>}
      </>
    )

  }

  renderSection6 = (section6: ProductContent, t: Function) => {

    return (
      <>
        {section6 && section6.status && <Container>
          <AdvanceFeautureContainer>
            <MainTitle style={{ textAlign: "center" }}>
              {section6.title}
            </MainTitle>


           

              {section6.img_content.map((item) => (
                 <Box style={{
                  borderRadius: '15px',
                  background: '#FFF',
                  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)',
                  display: 'flex',
                  padding: '20px 40px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '40px'
                }}>
                  <Box margin={'auto'}>
                    <img src={item.image} alt="" />
                  </Box>

                  <Typography style={{
                    color: '#102126',
                    fontFamily: 'Poppins',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '0.54px'
                  }}>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />

                  </Typography>

                  <DemoBtn 
                   onClick={() => this.props.navigation.navigate("RequestDemoWithStyles", 
                  { 'id': this.props.navigation.getParam('serviceId') })} 
                  style={{ marginTop: "0px" }} 
                  data-test-id="demoBtn">
                    {t('bookDemo')}
                  </DemoBtn>
                </Box>

              ))}



            
          </AdvanceFeautureContainer>
        </Container>}
      </>
    )

  }

  renderSection7 = (section7: ProductContent, t: Function) => {

    return (
      <>
        {section7 && section7.status && <Container>
          <AdvanceFeautureContainer>
            <MainTitle style={{ textAlign: "center" }}>
              {section7.title}
            </MainTitle>

            <RangeEndPointContainer>

              {section7.img_content.map((item: any) => (


                <RangePointBox>

                  <Box>
                    <img src={item.image} alt="" height="100px" width="100px" />
                  </Box>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <OfferBoxSubHeading 
                    dangerouslySetInnerHTML={{ __html: item.content ? 
                    this.removePTags(item.content) : '' }} 
                    />
                    <OfferBoxSubHeading 
                    dangerouslySetInnerHTML={{ __html: item.sub_content 
                    ? this.removePTags(item.sub_content) : 
                    '' }} />


                  </Box>



                </RangePointBox>


              ))}


            </RangeEndPointContainer>



          </AdvanceFeautureContainer>

        </Container>}

      </>
    )
  }


  renderContactUsForm = (t: Function) => {

    return (
     !this.state.loading &&  <>
   
        <ContactUsContainer>

          <Box>

            <Box style={{ maxWidth: '412px' }}>
              <Typography style={webStyle.intersetedVirtual}>
                {t('interstedVirtual')}
              </Typography>
            </Box>

            <Box style={{
              maxWidth: '473px',
              height: '108px',
              flexShrink: 0
            }}>

              <Typography style={webStyle.happyToServe} >

                {t('happyToServe')}
              </Typography>
            </Box>
          </Box>




          <Box style={{ maxWidth: '557px' }}>

            <InformationText >
              {t('informationText')}
            </InformationText>
            <Formik
              data-test-id="formik"
              enableReinitialize
              validateOnBlur={true}
              initialValues={{
                orgName: '',
                applicantName: '',
                phoneNumber: '',
                emailAddress: '',
                writeUs: '',



              }}
              validationSchema={() => this.ContactUsScheam(t)}
              onSubmit={(values, { resetForm }) => {
                console.log("values", values)
                this.contactUsPostRequest(values)
                resetForm();
              }}

            >


              {({
                errors, values, handleChange, touched, setFieldValue
              }) => {

                return (

                  <Form>

                    <FormContainer>

                      <Box>
                        <InputBox name="orgName" value={values.orgName} onChange={handleChange} style={{ padding: "15px 0" }} placeholder={t('orgName')} />
                        {Boolean(touched.orgName && errors.orgName) && (
                          <ErrorTypography>
                            {errors.orgName}
                          </ErrorTypography>
                        )}
                      </Box>


                      <Box>
                        <InputBox name="applicantName" value={values.applicantName} onChange={handleChange} style={{ padding: "15px 0" }} placeholder={t('applicantName')} />
                        {Boolean(touched.applicantName && errors.applicantName) && (
                          <ErrorTypography>
                            {errors.applicantName}
                          </ErrorTypography>
                        )}
                      </Box>


                      <Box>
                        <InputBox name="phoneNumber" value={values.phoneNumber} onChange={handleChange} style={{ padding: "15px 0" }} placeholder={t('phoneNumber')} />
                        {Boolean(touched.phoneNumber && errors.phoneNumber) && (
                          <ErrorTypography>
                            {errors.phoneNumber}
                          </ErrorTypography>
                        )}
                      </Box>


                      <Box>
                        <InputBox name="emailAddress" value={values.emailAddress} onChange={handleChange} style={{ padding: "15px 0" }} placeholder={t('emailAddress')} />
                        {Boolean(touched.emailAddress && errors.emailAddress) && (
                          <ErrorTypography>
                            {errors.emailAddress}
                          </ErrorTypography>
                        )}
                      </Box>
                      <Box>

                        <WriteUs>
                          {t('writeUs')}
                        </WriteUs>
                        <Input
                          data-testid="discriptionInputID"
                          multiline
                          name="writeUs"
                          style={{
                            background: "white", padding: '10px'
                          }}
                          onChange={handleChange}
                          rows={8}
                          disableUnderline={true}
                          fullWidth
                          value={values.writeUs}


                        />
                        {Boolean(touched.writeUs && errors.writeUs) && (
                          <ErrorTypography>
                            {errors.writeUs}
                          </ErrorTypography>
                        )}
                      </Box>



                    </FormContainer>
                    <DemoBtn type="submit">
                      {t('submit')}
                    </DemoBtn>



                  </Form>
                )

              }



              }

            </Formik>
          </Box>

          <SuccessModal
            successMessage={t('submitSuccessfullText')}
            showSubmitSuccessModal={this.state.showSuccessModal}
            handelCloseModal={this.handleCloseModal} />

        </ContactUsContainer>


      </>
    )



  }

             


  renderVirtualPbx = (t: Function) => {
    return (
      <>
        <Container>
          {this.renderSection1(this.state.productCotent.section1, t)}
        </Container>
        {this.renderSection2(this.state.productCotent.section2, t)}
        {this.renderSection3(this.state.productCotent.section3, t)}
        {this.renderSection4(this.state.productCotent.section4, t)}
        {this.renderSection5(this.state.productCotent.section5, t)}
        {this.renderSection6(this.state.productCotent.section6, t)}
        {this.renderSection7(this.state.productCotent.section7, t)}
        <Container>
          <AdvanceFeautureContainer>
            <MainTitle style={{ textAlign: "center" }}>
              {t('availablePlans')}
            </MainTitle><Grid container alignItems="center" justifyContent={'center'} data-test-id="CardsContainer">

              <Customisableusersubscriptions
                virutalPbx={this.state.virutalPbx}
                id="2" data={[]}
                availablePlans={this.state.availablePlan}
                navigation={this.props.navigation}
                route={""} />

         

            </Grid>


            <Box style={webStyle.comparePlanParentContainer}>
              <Typography style={webStyle.compareAllPlan}>
                {t('compareAllPlans')}
              </Typography>

              <Box style={{ cursor: "pointer" }}>
                <img src={rightArrow} alt="" />
              </Box>
            </Box>



          </AdvanceFeautureContainer>



        </Container>
        {this.renderContactUsForm(t)}





      </>
    )
  }





  renderAdvantage = (data: ProductContent, t: Function) => {

    
    return (<>
      {data
        && data.status &&
        <Container>
          <AdvanceFeautureContainer>
            <MainTitle style={{ textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </MainTitle>
            <EndPointContianer style={{ flexDirection: 'row-reverse' }}>
             {data.img_content.length > 0 &&  <img src={data?.img_content[0]?.image} style={{ maxWidth: "470px" }} alt="" /> }
              <Box style={{ display: "flex", flexDirection: "column", gap: "46px" }}>
                {data.img_content[0]?.content.split(',').map((item: string) => (

                  <>
                    <EndPointTextContianer>
                      <Box>
                        <img src={dotImage} alt="" />
                      </Box>
                      <EndPointText dangerouslySetInnerHTML={{ __html: item.replace(/<p>/g, '').replace(/<\/p>/g, '') }}>
                      </EndPointText>
                    </EndPointTextContianer>
                  </>

                ))}
              </Box>
            </EndPointContianer>
          </AdvanceFeautureContainer>
        </Container>}

    </>
    )
  }

  renderCommonSection =  (t:Function)=>{
    return (
      <>
        <Container>
          {this.renderSection1(this.state.productCotent.section1, t)}
        </Container>
        {this.renderSection2(this.state.productCotent.section2, t)}
        {this.renderSection3(this.state.productCotent.section3, t)}
        {this.renderSection5(this.state.productCotent.section4, t)}
        {this.renderAdvantage(this.state.productCotent.section5, t)}
        {this.renderSection6(this.state.productCotent.section6, t)}
        {this.renderContactUsForm(t)}
      </>
    )
  }

  renderSmartContactCenter = (t: Function) => {
    return (
          <>
           {this.renderCommonSection(t)}
          </>
      )
   }


  renderMobileAndVoice =  (t:Function)=>{
      return (
        <>
        {this.renderCommonSection(t)}
      </>
    )

  }


  renderProduct = (t: Function) => {
 
    const id = this.props.navigation.getParam('id');
   
 
    let productComponent;

  


    if(id==='virtualPbx'){
         productComponent = this.renderVirtualPbx(t)
    }
    else if(id==='contactCenter'){
        productComponent =this.renderSmartContactCenter(t)
    }
    else if(id==='mobileEngagement'){
       productComponent = this.renderMobileAndVoice(t)
    }


    return <>{productComponent}</>;
}
 


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Translation>
        {
          t => <AppHeaderWeb navigation={this.props.navigation}>
            <ThemeProvider theme={theme}>
              {this.state.loading ? <Box data-test-id="circularBox" display="flex" justifyContent="center"><CircularProgress color="secondary"/></Box> : this.renderProduct(t)}
            </ThemeProvider>
          </AppHeaderWeb>
        }

        
      </Translation>




      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  happyToServe: {
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
  },
  intersetedVirtual: {
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',

  },
  comparePlanParentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center'
  },
  compareAllPlan: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textDecorationLine: 'underline'
  }
};
// Customizable Area End
