import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LanguageSupportController, {
  Props,
  configJSON,
} from "./LanguageSupportController";

export default class LanguageSupport extends LanguageSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
     
     <div style={{ ...webStyle.languageDropdown, cursor:'pointer'  }}>
        <div style={webStyle.menuItemsBox}>

          <Box data-test-id="english" 
          onClick={()=>this.props.languageTranslation 
          && this.props.languageTranslation('en')}
          >
            

            
           <Typography>{configJSON.english}</Typography>
          </Box>
          <Divider />
          <Box onClick={()=>this.props.languageTranslation && this.props.languageTranslation('ar')} data-test-id="arabic">
            <Typography>{configJSON.arabic}</Typography>
          </Box>
          <Divider />
        
        </div>
      </div>
     
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  languageDropdown:{
    position: 'absolute' as 'absolute',
    top: 46,
    right: 0,
    left: 0,
    zIndex: 1,
    borderRadius: '16px',
    width: '96px',
    boxShadow: 'rgb(0 0 0 / 21%) 0px 2px 25px 0px',
    background:"#DFE8ED"

  },
  menuItemsBox: {
    padding: "9px",
    "& .MuiBox-root": {
      display: "flex",
      gap: "24px",
      cursor: "pointer",
      padding: "0 40px 0 16px",
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        color: "#102126",
        fontSize: "16px",
        fontWeight: 300
      },
      "& img": {
        width: "24px"
      }
    },
    "& .MuiDivider-root": {
      marginTop: "6.25px",
      marginBottom: "16px"
    }
  }
};
// Customizable Area End
