import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  withStyles,
  Grid,
  Theme,
  Link
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { Translation } from 'react-i18next';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const ListItem = withStyles((theme: Theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 300,
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    marginBottom: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  }
}))(Box)
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <Translation>

          {
            t => <Grid container >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Typography style={webStyle.mainFooterHeading}>{t('aboutUs')}</Typography>
                  <Typography style={webStyle.mainFooterPara}>
                    {t('aboutCompany')}
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={12} sm={12} md={2} lg={2}>
                <Box>
                  <Typography style={webStyle.listHeading}>{t('products')}</Typography>
                  <Box component={'ul'} style={webStyle.list}>
                    {this.state.services.map((item)=>(
                          <ListItem component={'li'} >
                          { item.length > 15 ? item.slice(0,15) + '...' : item}
                        </ListItem>
                    ))}
                  
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={2} lg={2}>
                <Box>
                  <Typography style={webStyle.listHeading}>{t('company')}</Typography>
                  <Box component={'ul'} style={webStyle.list}>
                    {this.state.company && this.state.company.map((item) => (
                      <ListItem component={'li'} >
                        {item.name.length > 15  ? item.name.slice(0,15) + "..." : item.name}
                      </ListItem>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={2} lg={2}> <Box>
                <Typography style={webStyle.listHeading}>{t('quickLinks')}</Typography>
                <Box component={'ul'} style={webStyle.list}>
                  {this.state.quickLinks && this.state.quickLinks.map((item) => (
                    <ListItem data-test-id="quickLinks" component={'li'} >
                      <Link href={item.link as string} target="_blank" >
                        {item.name.length > 15  ? item.name.slice(0,15) + "..." : item.name}
                        </Link>
                    </ListItem>
                  ))}
                </Box>
              </Box>
              </Grid>
            </Grid>
          }

        </Translation>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainFooterHeading: {
    color: '#F6F6F6',
    fontSize: '32px',
    fontWeight: 500,
  },
  mainFooterPara: {
    color: '#F6F6F6',
    fontSize: '20px',
    paddingTop: '33px',
    maxWidth: '503px',
    fontWeight: 300,
    fontFamily: 'Poppins',
  },
  listHeading: {
    fontSize: '32px',
    color: '#F6F6F6',
    fontWeight: 500,

  },
  list: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    marginTop: '33px'
  },
};
// Customizable Area End
