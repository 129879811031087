export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeIcon = require("../assets/closeIcon.svg");
export const checkIcon = require("../assets/checkIcon.svg");
export const checkedIcon = require("../assets/checkedBox.svg");
export const unCheckedIcon = require("../assets/unChecked.svg");
export const secheduleImage = require("../assets/Schedule-rafiki.png");
export const topBackground = require("../assets/Wecom_logo.svg");
export const topBackground2 = require("../assets/Wecom_logo_second.svg");
export const clock = require("../assets/clock.svg");
export const video = require("../assets/video.svg");
export const prevBtn = require("../assets/prevBtn.svg");
export const nextBtn = require("../assets/nextBtn.svg");
export const globe = require("../assets/globe.svg");
export const successIcon = require("../assets/successIcon.svg");
export const disablePrevBtn =  require("../assets/disablePrevBtn.svg");