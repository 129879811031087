import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {imgPasswordVisible , imgPasswordInVisible } from "./assets"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  enableOtpField:boolean,
  otpCode:string,
  otpVerified:boolean,
  enablePasswordChageField:boolean,
  showSuccessModal:boolean;
  otpVerifiedModal:boolean;
  passwordChangeModal:boolean;
  errorModal:boolean;
  errorMessage:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean=false;
  sendOtpApiId:string ="";
  otpConfirmationApiId:string="";
  passwordChangeApiId:string="";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible : any = imgPasswordInVisible ;
  imgPasswordVisible : any = imgPasswordVisible ;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      enableOtpField:false,
      otpCode:'',
      otpVerified:false,
      enablePasswordChageField:false,
      showSuccessModal:false,
      otpVerifiedModal:false,
      passwordChangeModal:false,
      errorModal:false,
      errorMessage:''
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
       // Customizable Area Start
       if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        await this.handleNavigationPayLoadMessage(message);
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.validationAPICallId &&
        this.validationAPICallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        await this.handleValidationResponse(message);
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestEmailOtpCallId !== null &&
        this.requestEmailOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        await this.handleEmailOtpResponse(message);
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestPhoneOtpCallId !== null &&
        this.requestPhoneOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        await this.handleEmailOtpResponse(message);
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestGoToConfirmationCallId !== null &&
        this.requestGoToConfirmationCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        await this.handleConfirmationResponse(message);
      } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
        this.handleCountryCodeMessage(message);
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        this.handleApiResponses(message);
      }
    // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : ""
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : ""
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : ""
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area Start
  ResetPasswordSchema = (t:Function)=> Yup.object().shape({
    emailAddress: Yup.string().email(t('errorEmailInvalid')).required(t('errorEmailRequired')),
   });


   OtpSchema = (t:Function)=> Yup.object().shape({
    otp: Yup.number()
    .min(4)
    .required(t('otpRequired')),
   });


   NewPasswordSchema = (t:Function)=> Yup.object().shape({
    password: Yup.string()
    .min(8, t('passwordMinLenght'))
    .max(50, t('passwordMaxLength')) // Max length validation
    .test(t('noEmpty'), t('passwordCannotSapace'), value => !/\s/.test(value)) // Custom validation for empty spaces
    .required(t('passwordIsRequired')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], t('passwordNotMatch'))
    .required(t('confirmPassword')),
   })


  sendOtp = (values:any)=>{
   

 const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.sendOtpApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OtpApiAddress
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      attributes:{
        email: values.emailAddress
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  otpConfirmation = (values:any)=>{


    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.otpConfirmationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OtpConfimationApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      
        token: this.state.token,
        otp_code: values.otp
  
    };

    this.setState({
      otpCode:values.otp
    })
    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  passwordChangeAfterOtp = (values:any)=>{

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.passwordChangeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      
        token: this.state.token,
        otp_code: this.state.otpCode,
        new_password: values.confirmPassword
        
  
    };

    this.setState({
      otpCode:values.otp
    })
    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



  }
  

  otpSuccess =  (responseJson:any)=>{
    if (!responseJson.errors) {
     console.log("OTP Generated", responseJson.data)
     this.setState({
      showSuccessModal:true,
      token: responseJson.meta.token,
      errorModal:false
     })

   
    } else {
      //Check Error Response
      this.parsedApiErorr(responseJson);
    }
  }

  otpConfimationSuccess =  (responseJson:any)=>{
    if (!responseJson.errors) {
  
      this.setState({
        otpVerifiedModal:true,
        errorModal:false
      })
    

      console.log("otp", this.state.otpVerified)


     } else {
       //Check Error Response
       this.parsedApiErorr(responseJson);
     }
  }

  passwordChangeSuccess = (responseJson:any)=>{
    if (!responseJson.errors) {
        
      this.setState({
        passwordChangeModal:true,
        errorModal:false
      })
    
    } else {
       //Check Error Response
       this.parsedApiErorr(responseJson);
     }
  }


  async handleNavigationPayLoadMessage(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ token: otpAuthTkn });
      if (this.isChangePassword) {
        this.setState({ accountStatus: "ChangePassword" });
      }
      this.otpToken = this.state.token;
    } else {
      const accountType = message.getData(getName(MessageEnum.NavigationForgotPasswordPageInfo));
      if (accountType) {
        this.startForgotPassword(accountType);
      }
    }
  }
  
  async handleValidationResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson === undefined) {
      return;
    }
  
    if (responseJson.data && responseJson.data[0]) {
      const passRegex = RegExp(responseJson.data[0].password_validation_regexp);
      const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
      const passwordRulesFromValidation = responseJson.data[0].password_validation_rules;
  
      this.setState({
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress)
        },
        passwordSchema: {
          password: Yup.string()
            .required(configJSON.pleaseEnterAPassword)
            .matches(passRegex, configJSON.invalidPassword),
          confirmPassword: Yup.string()
            .required(configJSON.pleaseConfirmYourPassword)
            .when("password", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf([Yup.ref("password")], configJSON.passwordsMustMatch)
            })
        },
        passwordRules: passwordRulesFromValidation
      });
    }
  }
  
  async handleEmailOtpResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });
      // Handle navigation to OTP page
    } else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
  
  async handleConfirmationResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson !== undefined && responseJson.data) {
      this.setState({
        accountStatus: "Confirmation"
      });
    } else if (responseJson !== undefined && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  
  handleCountryCodeMessage(message: Message) {
    const selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected: selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode
      });
    }
  }
  
  handleApiResponses(message: Message) {
    const apiRequestCallIds = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJsons = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  

    if(responseJsons.error){
        this.setState({
             errorModal:true,
             errorMessage:responseJsons.error
        })

        return
    }
    
  
    if (apiRequestCallIds && responseJsons) {
       this.setState({
        errorModal:false,
        errorMessage:''
       })

      if (apiRequestCallIds === this.sendOtpApiId) {
        this.otpSuccess(responseJsons);
      } else if (apiRequestCallIds === this.otpConfirmationApiId) {
        this.otpConfimationSuccess(responseJsons);
      } else if (apiRequestCallIds === this.passwordChangeApiId) {
        this.passwordChangeSuccess(responseJsons);
      }
    }
  }


  handleCloseModal = ()=>{
    this.setState({
    
      enableOtpField: true,
      errorModal:false
    })
  }


  handleCloseModalOtp = ()=>{
        this.setState({
        otpVerified: true,
        enableOtpField:false,
        errorModal:false
       })
  }

  handleCloseModalPassowrdChange = ()=>{
     this.setState({
      otpVerified: false,
      enableOtpField:false,
      errorModal:false
     })
  
     this.props.navigation.navigate('LoginWithStyles')
  }


  parsedApiErorr =  (responseJson:any)=>{
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors = '';
    errors.forEach((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
  })


  if(allerrors){
    this.setState({
        errorModal:true,
        errorMessage:allerrors
    })
  }


   
}


  // Customizable Area End
}
