import React, { Component } from 'react';


const successCheck =  require('./successCheck.svg')
import {Translation} from "react-i18next"

import {
    Modal,
    Box,
    Button,
    Typography,
 
  } from "@material-ui/core";


interface CustomInputProps {
   successMessage:string;
   showSubmitSuccessModal:boolean,
   handelCloseModal:Function
}





class SuccessModal extends Component<CustomInputProps> {
    constructor(props: CustomInputProps) {
        super(props);
     }

   render() {
        return (
           
          <Translation>
            {t =>  <Modal data-test-id ='openModalTestId' open={this.props.showSubmitSuccessModal}>
          <Box style={webStyle.modalMainBox}>
            <Box style={webStyle.innerModalBox as React.CSSProperties}>
              <Typography>
                <img style={webStyle.successCheckedIcon} src={successCheck} alt="successCheckedIcon" />
              </Typography>
              <Typography style={webStyle.modalSuccessText as React.CSSProperties}>
                {this.props.successMessage}
              </Typography>
              <Button
                data-test-id="modalSuccessButtonID"
                variant="contained"
                color="primary"
                style={webStyle.okConfirmButton as React.CSSProperties}
                onClick={()=>this.props.handelCloseModal()}
              >
                {t('ok')}
              </Button>
            </Box>
          </Box>
        </Modal>}
       
        </Translation>
     
    
        );
    }
}


const webStyle = {
    mainContainer: {
      display: "flex",
      padding: "80px 20px",
      gap: "10px",
      justifyContent: "space-evenly",
      "@media (max-width: 550px)": {
        flexDirection: "column"
      }
    },
  
    mainTextContainerBox: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      "& .MuiTypography-root": {
        "&:last-child": {
          maxWidth: "470px",
          minWidth: "240px",
          lineHeight: "40px"
        }
      }
    },
  
    mainFormBox: {
      maxWidth: "458px",
      backgroundColor: "#DFE8ED",
      padding: "40px",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "25px"
    },
  
    okConfirmButton: {
      width: "180px",
      fontWeight: "bold",
      textTransform: "capitalize",
      backgroundColor: "#012677",
      fontSize: "18px",
      "&:hover": {
        backgroundColor: "#012677"
      }
    },
  
    textAreaField: {
      backgroundColor: "white",
      borderRadius: "5px"
    },
  
    labelTextTypography: {
      color: "#666B6D"
    },
  
    formElementTag: {
      display: "flex",
      flexDirection: "column"
    },
  
    inputWrapperBox: {
      height: "60px"
    },
  
    errorMessageText: {
      color: "#C30A2A",
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "24px"
    },
  
    inputMainMessageWrapperBox: {
      display: "flex",
      flexDirection: "column",
      gap: "15px"
    },
  
    inputMessageWrapperBox: {
      height: "145px"
    },
  
    modalMainBox: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  
    innerModalBox: {
      backgroundColor: "white",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "30px 25px",
      maxWidth: "280px",
      gap: "20px"
    },
  
    successCheckedIcon: {
      width: "80px"
    },
  
    modalSuccessText: {
      fontSize: "23px",
      textAlign:'center'
    }
  };
  

export default SuccessModal;
