import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Hidden

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik, Form } from 'formik';
import moment from "moment";
import TermsAndConditions from "../../email-account-registration/src/TermsAndConditions.web";
import {Translation} from "react-i18next";
import 'moment/locale/ar';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = () => createStyles({
  linearGradient: {
    background: 'linear-gradient(180deg, #024EAF 14.18%, #1BC0FF 130.33%)',
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  createForm: {
    padding: "40px 80px",
    backgroundColor: "#FFF"
  },
  demoTitle: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  demoSubTitle: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "30px",
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: "pointer"
  },
  termCondition: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "19px",
  },
  textsConditionContainer: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    marginTop: "85px"
  },
  termsCondition2: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  createAccBtn: {
    borderRadius: '10px',
    background: '#012677',
    width: '100%',
    padding: '12px 0',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    marginTop: "64px",
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    '&:hover': {
      background: '#012677',
      color: '#fff',
    },
  },
  sechduleBtnsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  sechudleBtn: {
    borderRadius: '5px',
    background: '#1BC0FF',
    maxWidth: '254px',
    padding: '12px 24px',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    fontFamily: 'Poppins',
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    '&:hover': {
      background: '#1BC0FF',
      color: '#fff',
    },
  },
  unSelectedBtn: {
    borderRadius: '5px',
    background: '#fff',
    maxWidth: '254px',
    padding: '12px 24px',
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: "#2C3135",
    textTransform: 'none' as 'none',
    border: "1px solid #1BC0FF",
    lineHeight: '36px',
    '&:hover': {
      background: '#fff',
      color: '#2C3135',
    },
  },
  demoFormText: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "64px",
    fontWeight: 700,
    lineHeight: '96px'
  },
  feelFree: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
  },
  feelFreeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "26px",
    alignItems: "center"
  },
  backgroundImageleft: {
    backgroundImage: `url(${topBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'right -200px'
  },
  topBackgroundStyle: {
    position: 'absolute',
    top: '0',
    left: '0',
    opacity: 0.2
  },
  topBackgroundStyle2: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    opacity: 0.1
  },
  viewPortHeight: {
    minHeight: "100%"
  },
  containerBackground: {
    backgroundColor: "#F6F6F6",
    "@media (min-width: 900px)": {
      height:'100%'
    }
    
  },
  mainContainerCalender: {
    height: "100%",
    display: "flex",
    backgroundColor:'#f6f6f6'
  },
  leftBox: {
    width: "30%", borderRight: "2px solid #ddd"
  },
  weCommContainer: {
    padding: "30px",
    borderBottom: "2px solid #ddd"
  },
  demoTitleStyle: {
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: '36px'
  },
  zommContainer: {
    gap: '27px',
    display: 'flex',
    alignItems: 'center'
  },
  halfMin: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '30px'
  },
  selectDateTime: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  calender: {
    fontFamily: 'Poppins',

  },
  calendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    color: "#102126",
    borderRadius: "5px 5px 0 0",
    marginBottom: "10px"
  },
  currentMonth: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  calendarGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    borderRadius: "0 0 5px 5px",
    overflowX: 'auto'
  },
  calendarCell: {
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
    color: "#102126"
  },

  dayLabel: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  cellClass: {
    display: "flex",
    justifyContent: "center",
    color: "#102126",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "30px",
    cursor: "pointer",
    padding: "10px 0",

  },
  btnContainer: {
    display: "flex",
    gap: "12px"
  },
  selctedCell: {
    backgroundColor: '#1BC0FF',
    width: '30px',
    height: '30px',
    display: 'flex',
    borderRadius: '16px',
    justifyContent: 'center',
    color: '#F6F6F6',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  bookDemoBtn: {
    borderRadius: '10px',
    background: '#012677',
    padding: '12px 24px',
    fontSize: '24px',
    fontWeight: 500,
    color: '#fff',
    marginTop: "64px",
    textTransform: 'none' as 'none',
    lineHeight: '36px',
    '&:hover': {
      background: '#012677',
      color: '#fff',
    },
  },
  marginLeft25: {
    marginLeft: '25px'
  },
  canadatimeContainer: {
    marginTop: '172px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginLeft: '101px'
  },
  timeUsa: {
    color: 'rgba(16, 33, 38, 0.50)',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
  },
  margintBottom30: {
    marginBottom: "30px"
  },
  zoomParentContainer: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '27px'
  },
  rightBox: {
    width: '70%',
  },
  error: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#C30A2A',
  },
  successMessage: {
    color: '#102126',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  successMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '48px'
  },
  selectDateTimeText: {
    justifyContent: 'start',
  },
  overflowHorizontal: {
    overflowX: 'scroll'
  },
  inputFieldContainer:{
    display: 'flex',
    flexDirection: 'column',
    gap: '27px',
    marginTop: '38px'
  },
  "@media (max-width: 600px)": {
    demoTitle: {
      fontSize: "26px"
    },
    demoSubTitle: {
      fontSize: "16px"
    },
    createForm: {
      padding: "14px 20px"
    },
    mainContainerCalender: {
      flexDirection: 'column',
      height: "100%"
    },
    leftBox: {
      width: "100%"
    },
    rightBox: {
      width: "100%"
    },
    selectDateTimeText: {
      justifyContent: 'center'
    },
    selectDateTime: {
      textAlign: 'center'
    },
    sechduleBtnsContainer: {
      alignItems: 'center'
    },
    marginLeft25: {
      margin: 'auto'
    }
  },


});
import {
  closeIcon,
  checkedIcon,
  unCheckedIcon,
  secheduleImage,
  topBackground,
  topBackground2,
  video,
  clock,
  nextBtn,
  prevBtn,
  globe,
  successIcon,
  disablePrevBtn
} from "./assets";


interface CustomInputProps {
  placeholder: string;
  width: number;
  hideBorderRigth?: boolean;
  hideBorderLeft?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  name: string;
  error: boolean;
  errorMessage: string;


}

// Customizable Area End

import RequestDemoController, {
  Props,
  configJSON,
} from "./RequestDemoController";
import { getStorageData } from "../../../framework/src/Utilities";



export default class RequestDemo extends RequestDemoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.langKey = this.getLangKey()
    // Customizable Area End
  }

  // Customizable Area Start

  getLangKey =()=>{
      
       getStorageData('i18nextLng').then((value)=>{
          this.setState({
            langKeys:value
          })
       } ).catch(()=> console.log('something went wrong'))

      
  }

  renderCalendar = () => {
    const daysInMonth = this.getDaysInMonth(this.state.currentDate);
    const firstDayOfWeek = this.getFirstDayOfWeek(this.state.currentDate);
    const calendar: JSX.Element[] = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
      calendar.push(
        <div key={`empty-${i}`} className="calendar-cell empty-cell" />
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        this.state.currentDate.getFullYear(),
        this.state.currentDate.getMonth(),
        day
      );

      const isDateSelected =
        this.state.selectedDate !== null &&
        date.toDateString() === (this.state.selectedDate as Date).toDateString();
      const isToday = date.toDateString() === new Date().toDateString();
      const isDisabled = date < new Date() && !isToday;


      const onClickHandler = () => {
        if (!isDisabled) {
          this.handleDateClick(date)

          const formatTime = moment(date).locale('en').format('D/M/YYYY');

          this.getTimeSlots(formatTime)

        }
      };

      calendar.push(
        <div key={day} data-test-id="calenderSlots" className={`${this.props.classes.cellClass}`} onClick={onClickHandler}>
          <div className={`${isDateSelected && this.props.classes.selctedCell}`} style={isDisabled ? { opacity: 0.6 } : {}}>
            {day}

          </div>
        </div>
      );
    }

    return calendar;
  };

  customInputField = (props: CustomInputProps) => {

    const inputStyle = {
      height: '56px',
      width: '100%',
      borderRadius: '10px',
      border: '1px solid rgba(16, 33, 38, 0.24)',
      padding: '12px',
      outline: 'none',
    }

    const hideRight = props.hideBorderRigth && {
      borderRight: '0',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    }
    const hideLeft = props.hideBorderLeft && {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0'
    }

    const labelStyle = {
      color: '#102126',
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 300,
      marginBottom: '12px',
    };

    return (
      <>

        <Box style={{ width: `${props.width}%`, position: "relative" }}>
          <input type="text" placeholder={props.placeholder}
            name={props.name}
            onChange={props.onChange}
            value={props.value} style={{
            ...inputStyle,
            ...hideRight,
            ...hideLeft,
            ...labelStyle
          }} />
          <Box style={{
            position: 'absolute',
            right: '2%',
            top: '30%'
          }}>
            {/* <img src={checkIcon} alt="" /> */}
          </Box>
          {props.error && <>
            <Typography className={this.props.classes.error}>{props.errorMessage}</Typography>
          </>}
        </Box>

      </>
    )

  }

  renderCalenderDemo = (t:Function) => {
  const  isCurrentMonth = this.state.currentDate.getMonth() === new Date().getMonth();
    return (

      <Box className={`${this.props.classes.containerBackground}`}>
        <Box
          className={this.props.classes.mainContainerCalender}
        >
          <Box className={this.props.classes.leftBox}>
            <Box className={this.props.classes.weCommContainer}>
              <Typography className={this.props.classes.demoTitleStyle}>{t('weComm')}</Typography>
              <Typography className={this.props.classes.demoTitleStyle}>{t('demoConsultation')}</Typography>
            </Box>
            <Box className={this.props.classes.zoomParentContainer}>
              <Box className={this.props.classes.zommContainer}>
                <img src={clock} alt="" />
                <Typography className={this.props.classes.halfMin}>{t('halfMin')}</Typography>

              </Box>
              <Box className={this.props.classes.zommContainer}>
                <img src={video} alt="" />
                <Typography className={this.props.classes.halfMin}>{t('zoom')}</Typography>

              </Box>
            </Box>
          </Box>
          <Box className={this.props.classes.rightBox}>
            <Container>
              <Grid container className={this.props.classes.selectDateTimeText}>
                <Box style={{ padding: "70px 0px" }}>
                  <Typography variant="h5" className={this.props.classes.selectDateTime}>{t('selectDate')}</Typography>
                </Box>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={8} >
                  <Box

                  >
                    <div className={this.props.classes.calender}>
                      <div className={this.props.classes.calendarHeader}>
                        <div className={this.props.classes.currentMonth}>
                          {t(`${this.months[this.state.currentDate.getMonth()]}`)} {this.state.currentDate.getFullYear()}
                        </div>
                        <div className={this.props.classes.btnContainer}>

                          {isCurrentMonth ? (
                            <img src={disablePrevBtn} alt="" />
                          ) : (
                            <img data-test-id="prev" onClick={this.prevMonth} src={prevBtn} alt="" />
                          )}

                          <img data-test-id="next" onClick={this.nextMonth} src={nextBtn} alt="" />

                        </div>
                      </div>
                      <div className={this.props.classes.calendarGrid}>
                        {this.daysOfWeek.map((day) => (
                          <div key={day} className={`${this.props.classes.calendarCell}  ${this.props.classes.dayLabel}`}>
                            {t(`${day}`)}
                          </div>
                        ))}
                        {this.renderCalendar()}
                      </div>
                    </div>


                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Box className={this.props.classes.margintBottom30}>
                    <Typography className={this.props.classes.selectDateTime}>
                      {moment(this.state.selectedDate).locale(this.state.langKeys).format('dddd, MMMM Do')}
                    </Typography>
                  </Box>
                  <Box className={this.props.classes.sechduleBtnsContainer}>
                    {this.state.availableTimeSlots.length === 0 && <>
                      <Typography>{t('noAvailaleSlots')}</Typography>
                    </>}
                    {this.state.availableTimeSlots.length > 0 && this.state.availableTimeSlots.map((item) => (
                      <>
                        <Button key={item.id} onClick={() => this.setState({
                          selectedTimeId: item.id
                        })}
                          className={this.state.selectedTimeId === item.id ?
                            this.props.classes.sechudleBtn :
                            this.props.classes.unSelectedBtn}>
                          {moment.utc(item.startTime).locale(this.state.langKeys).format('HH:mm a')}
                        </Button>
                      </>
                    ))}
                  </Box>
                </Grid>
                <Box className={this.props.classes.marginLeft25}>
                  <Button
                    onClick={this.bookDemo}
                    data-test-id="bookDemoBtn"
                    className={this.props.classes.bookDemoBtn}>
                    {t('bookDemoText')}
                  </Button>
                </Box>


              </Grid>

            </Container>
            <Box className={this.props.classes.canadatimeContainer}>
              <Box>
                <img src={globe} alt="" />
              </Box>
              <Typography className={this.props.classes.timeUsa}>
                {t('usTime')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )

  }

  renderDemoSuccess = (t:Function) => {

      setTimeout(()=>{
        this.props.navigation.navigate('Home')
      },2000)
     
    return (
      <>
        <Box
          className={this.props.classes.mainContainerCalender}
        >

          <Box margin="auto" className={this.props.classes.successMessageContainer}>

            <Box>
              <img src={successIcon} alt="" />
            </Box>
            <Box>
              <Typography className={this.props.classes.successMessage}>
                {t('successMessage')}
              </Typography>
            </Box>



          </Box>

        </Box>
      </>
    )

  }

  renderDemoForm = (t:Function) => {


  

    return  (

 
      <Box className={`${this.props.classes.containerBackground}`}>

        <Grid container className={this.props.classes.viewPortHeight}>
          <Hidden smDown>
            <Grid item md={6} >


              <Box className={this.props.classes.linearGradient}>


                <Box className={this.props.classes.topBackgroundStyle}>
                  <img src={topBackground} alt="" />

                </Box>
                <Box className={this.props.classes.feelFreeContainer}>



                  <Box>
                    <img src={secheduleImage} alt="" />
                  </Box>

                  <Typography className={this.props.classes.demoFormText}>
                    {t('demoForm')}
                  </Typography>
                  <Typography className={this.props.classes.feelFree}>
                    {t('feelFree')}
                    <br />
                    <Typography className={this.props.classes.feelFree}>
                      {t('demoSession')}
                    </Typography>
                  </Typography>
                </Box>

                <Box className={this.props.classes.topBackgroundStyle2}>
                  <img src={topBackground2} alt="" style={{ height: "400px" }} />

                </Box>

              </Box>



            </Grid>
          </Hidden>
          <Grid item md={6} xs={12}>
            <Box className={this.props.classes.createForm}>
              <Formik
                enableReinitialize
                validateOnBlur={true}
                initialValues={this.state.initialValues}
                onSubmit={(values) => {
                  this.setState({
                    initialValues: { ...values },
                    gotoNext: true
                  })
                }}
                validationSchema={()=> this.FormSchema(t)}

              >
                {

                  ({ errors, values, handleChange, touched, setFieldValue }) => {

                    return (

                      <Form>

                        <Box className={this.props.classes.closeIconContainer} onClick={this.gotoHomepage}>
                          <img src={closeIcon} alt="" />
                        </Box>

                        <Box>
                          <Typography className={this.props.classes.demoTitle}>{t('demoTitle')}</Typography>
                          <Box style={{ marginTop: "12px" }}>
                            <Typography className={this.props.classes.demoSubTitle}>{t('demoSubtitle')}</Typography>
                          </Box>
                        </Box>
                        <Box className={this.props.classes.inputFieldContainer}>
                          <Box style={{ display: "flex" }}>

                            {this.customInputField({
                              placeholder: t('firstName'),
                              width: 50,
                              hideBorderRigth: true,
                              name: "firstName",
                              value: values.firstName,
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({firstName:e.target.value})
                              },
                              error: Boolean(touched.firstName && errors.firstName),
                              errorMessage: errors.firstName || ""
                            })}

                            {this.customInputField({
                              placeholder: t('lastName'),
                              width: 50, hideBorderLeft: true,
                              name: "lastName",
                              value: values.lastName,
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({lastName:e.target.value})
                              },
                              error: Boolean(touched.lastName && errors.lastName),
                              errorMessage: errors.lastName || ""
                            })}

                            

                          </Box>

                          <Box>
                            {this.customInputField({
                              placeholder: t('enterEmail'),
                              width: 100,
                              name: "email",
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({email:e.target.value})
                              },
                              value: values.email,
                              error: Boolean(touched.email && errors.email),
                              errorMessage: errors.email || ""
                            })}


                          </Box>


                          <Box>
                            {this.customInputField({
                              placeholder: t('phone'),
                              width: 100,
                              name: "phone",
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({phone:e.target.value})
                              },
                              value: values.phone,
                              error: Boolean(touched.phone && errors.phone),
                              errorMessage: errors.phone || ""
                            })}


                          </Box>


                          <Box>
                            {this.customInputField({
                              placeholder: t('company'),
                              width: 100,
                              name: "company",
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({company:e.target.value})
                              },
                              value: values.company,
                              error: Boolean(touched.company && errors.company),
                              errorMessage: errors.company || ""
                            })}

                          </Box>

                          <Box>
                            {this.customInputField({
                              placeholder: t('numberEmployee'),
                              width: 100,
                              name: "numberOfEmployees",
                              onChange: (e) => {
                                handleChange(e);
                                this.updateStateWithFieldValues({numberOfEmployees:e.target.value})
                              },
                              value: values.numberOfEmployees,
                              error: Boolean(touched.numberOfEmployees && errors.numberOfEmployees),
                              errorMessage: errors.numberOfEmployees || ""
                            })}

                          </Box>
                        </Box>


                        <Box className={this.props.classes.textsConditionContainer}>
                          <Box>
                            <img src={values.termCondition ? checkedIcon : unCheckedIcon} onClick={() => {
                              this.updateStateWithFieldValues({ termCondition: !values.termCondition });
                              setFieldValue('termCondition', !values.termCondition)}
                              
                            }
                              alt="checkBox" />
                          </Box>
                          <Typography className={this.props.classes.termCondition}>
                            {t('agreeAll')}
                            <span className={this.props.classes.termsCondition2} onClick={this.handleTermsCondition}>
                              {t('termsAndCondition')}
                            </span>
                          </Typography>


                        </Box>

                        {
                          Boolean(touched.termCondition && errors.termCondition) && (
                            <>

                              <Typography className={this.props.classes.error}>
                                {errors.termCondition}
                              </Typography>

                            </>
                          )
                        }

                        <Box style={{ maxWidth: '864px' }}>
                          <Button
                            type="submit"
                            className={this.props.classes.createAccBtn}
                            color="primary"
                            data-test-id="submitBtn"

                          >
                            {configJSON.next}
                          </Button>
                        </Box>



                      </Form>



                    )


                  }



                }




              </Formik>






            </Box>


          </Grid>

        </Grid>


      </Box>



    )


  }


  renderContent(t:Function) {
   if (this.state.gotoNext) {
      return this.renderCalenderDemo(t);
    } else if (this.state.isFormSubmitted) {
      return this.renderDemoSuccess(t);
    }
    else if(this.state.termConditionOpen){
    
      return (
      <TermsAndConditions 
       closeTermsAndCondition={this.handleTermsCondition} 
       id="RequestDemo" 
       navigation={null} /> 
      )
    }
    else {
      return this.renderDemoForm(t);
    }
  
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Translation>
          {t=>  this.renderContent(t) }
        </Translation>
      
          
      
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start




export const RequestDemoWithStyles = withStyles(styles)(RequestDemo);

// Customizable Area End
