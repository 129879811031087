// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AutomatedEmailSending from "../../blocks/AutomatedEmailSending/src/AutomatedEmailSending";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import RequestDemo from "../../blocks/RequestDemo/src/RequestDemo";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import AffiliateUrl from "../../blocks/AffiliateUrl/src/AffiliateUrl";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import ProductQuickview from "../../blocks/ProductQuickview/src/ProductQuickview";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import MemberDirectory2 from "../../blocks/MemberDirectory2/src/MemberDirectory2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import {LoginWithStyles} from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web"
import PricingFinal from "../../blocks/categoriessubcategories/src/PricingFinal.web"
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import TermsAndConditions from "../../blocks/email-account-registration/src/TermsAndConditions.web"
import {RequestDemoWithStyles} from "../../blocks/RequestDemo/src/RequestDemo.web"
import { getStorageData } from "../../framework/src/Utilities";

const routeMap = {
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AutomatedEmailSending:{
 component:AutomatedEmailSending,
path:"/AutomatedEmailSending"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
RequestDemo:{
 component:RequestDemo,
path:"/RequestDemo"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AffiliateUrl:{
 component:AffiliateUrl,
path:"/AffiliateUrl"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
ProductQuickview:{
 component:ProductQuickview,
path:"/ProductQuickview/:id/:serviceId"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
MemberDirectory2:{
 component:MemberDirectory2,
path:"/MemberDirectory2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
LoginWithStyles: {
   component: LoginWithStyles,
   path: "/loginWeb"
},
PricingFinal:{
  component: PricingFinal,
  path: "/pricingFinal/:activeTab"
},
ForgotPasswordWeb:{
  component: ForgotPasswordWeb,
  path:"/forgotPasswordWeb"
},
RequestDemoWithStyles:{
  component:RequestDemoWithStyles,
  path:"/RequestDemoWeb/:id"
},
Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions",
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const Router = ({ langKey }) => {
 const WebRoutesGenerat =  React.useCallback(() => WebRoutesGenerator({ routeMap,  langKey }), [langKey]);
 return <WebRoutesGenerat />
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: 'en',
      prevLangKey:'en'
    };
  }




  async componentDidMount() {
    // Add event listener to handle language changes
    window.addEventListener('storage', this.handleLanguageChange);
    
    // Fetch initial language from storage or other source and set state accordingly
    const langKey = await getStorageData('i18nextLng');
    if (langKey) {
      this.setState({
        langKey,
        prevLangKey: langKey
      });
    }
  }

  componentWillUnmount() {
    // Remove event listener to prevent memory leaks
    window.removeEventListener('storage', this.handleLanguageChange);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if language key has changed and update document body direction
    if (prevState.langKey !== this.state.langKey) {
      document.body.dir = this.state.langKey === 'ar' ? 'rtl' : 'ltr';
    }
  }

  handleLanguageChange = async() => {
    // Handle language change event
    const langKey = await getStorageData('i18nextLng');
    if (langKey && langKey !== this.state.langKey) {
      this.setState({
        langKey,
        prevLangKey: this.state.langKey
      });
    }
  };

   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View > 
        <Router langKey={this.state.langKey} />
        <ModalContainer />
      </View>
    );
  }
}

export default App;
