import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, dummyUserImage } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

interface UserProfileInfo {

  id: string;
  type: string;
  name: string;
  company_name: string;
  job_title: string;
  country: string;
  email: string;
  full_phone_number: string;
  account_id: number;
  image: string



}

interface ErrorUpdateResponse {
  errors: Array<{
    token: string;
  }>;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start

  userFirstName: string;
  userLastName: string;
  companyName: string;
  jobTitle: string;
  country: string;
  emailAddress: string;
  phoneNumber: string;
  userFullName: string;
  userImage: File | null;
  changeImage: boolean;
  showImage: string;
  headerUserImage: string;
  showUplodImageModal: boolean;
  showSubmitSuccessModal: boolean;

  errorsUserFirstName: string;
  errorsUserLastName: string;
  errorsCompanyName: string;
  errorsJobTitle: string;
  errorsCountry: string;
  errorsEmailAddress: string;
  errorsPhoneNumber: string;
  showError: boolean;
  token: string;
  userId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editProfileCallID: string = "";
  showProfileCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseToken)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start

      userFirstName: "",
      userLastName: "",
      companyName: "",
      jobTitle: "",
      country: "",
      emailAddress: "",
      phoneNumber: "",
      userFullName: "",
      showImage: dummyUserImage,
      userImage: dummyUserImage,
      headerUserImage: dummyUserImage,
      changeImage: false,
      showUplodImageModal: false,
      showSubmitSuccessModal: false,

      errorsUserFirstName: "",
      errorsUserLastName: "",
      errorsCompanyName: "",
      errorsJobTitle: "",
      errorsCountry: "",
      errorsEmailAddress: "",
      errorsPhoneNumber: "",
      token: "",
      showError: false,
      userId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token == undefined) {
        let gettoken = await getStorageData("authToken")
        this.setState({ token: gettoken },
          () => {
            this.showUserProfileData();
          });
      } else {
        this.setState({ token: token },
          () => {
            this.showUserProfileData();
          });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.userProfileUpdateSuccessCall(apiRequestCallId, responseJson);
        this.userProfileShowSuccessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.userProfileUpdateFailureCall(apiRequestCallId, responseJson);
        this.userProfileShowFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.getToken();
  }

  userProfileUpdateSuccessCall = (apiRequestCallId: string, responseJson: UserProfileInfo) => {
    if (apiRequestCallId === this.editProfileCallID) {
      this.userstatusUpdateSuccessCallBack(responseJson);
    }
  };

  userProfileUpdateFailureCall = (apiRequestCallId: string, responseJson: ErrorUpdateResponse) => {
    if (apiRequestCallId === this.editProfileCallID) {
      this.userstatusUpdateFailureCallBack(responseJson);
    }
  };

  userProfileShowSuccessCall = (apiRequestCallId: string, responseJson: UserProfileInfo) => {
    if (apiRequestCallId === this.showProfileCallID) {
      this.showProfileSuccessCallBack(responseJson);
    }
  };

  userProfileShowFailureCall = (apiRequestCallId: string, responseJson: ErrorUpdateResponse) => {
    if (apiRequestCallId === this.showProfileCallID) {
      this.showProfileFailureCallBack(responseJson);
    }
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handleOpenUploadImageModal = () => {
    this.setState({ showUplodImageModal: true });
  };

  handleCloseUploadImageModal = () => {
    this.setState({ showUplodImageModal: false });
  };

  handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let file = event.target.files[0]
      this.setState({ userImage: file, showImage: URL.createObjectURL(event.target.files[0]), changeImage: true });
    }
  };

  handleRemoveImage = () => {
    this.setState({ userImage: null, showImage:"" });
  }

  handleSuccessModalClose = () => {
    this.setState({ showSubmitSuccessModal: false });
    this.props.navigation.navigate('Home');
    
  };

  apiEditCall = async (data: APIPayloadType) => {

    const { method, endPoint, body } = data;
    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validateForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let errorCheck = false;

    if (!this.state.userFirstName.trim() || !this.state.userFirstName.match(configJSON.firstNameRegex)) {
      this.checkFirstNameError();
      errorCheck = true;
    }

    if (!this.state.userLastName.trim() || !this.state.userLastName.match(configJSON.firstNameRegex)) {
      this.checkLastNameError();
      errorCheck = true;
    }

    if (!this.state.companyName.trim()) {
      this.setState({
        errorsCompanyName: 'companyNameRequired',
      });
      errorCheck = true;
    }

    if (!this.state.jobTitle.trim()) {
      this.setState({
        errorsJobTitle: 'errorJobTitleText',
      });
      errorCheck = true;
    }

    if (!this.state.country.trim()) {
      this.setState({
        errorsCountry: 'errorCountryNameText',
      });
      errorCheck = true
    }

    if (!this.state.emailAddress.match(configJSON.emailRegex)) {
      this.checkEmailError();
      errorCheck = true
    }

    if (!errorCheck) {
      this.onStatusUpdate()
    }
  };

  onStatusUpdate = async () => {
    let formdata = new FormData();
    formdata.append("name", this.state.userFirstName + " " + this.state.userLastName);
    formdata.append("company_name", this.state.companyName);
    formdata.append("email", this.state.emailAddress);
    formdata.append("job_title", this.state.jobTitle);
    formdata.append("country", this.state.country);
    formdata.append("full_phone_number", `${this.state.phoneNumber}`);
    if (this.state.userImage && this.state.changeImage) {
      const url = await fetch(this.state.showImage);
      const blob = await url.blob();
      const file = new File([blob], "image.jpg", { type: blob.type });
      formdata.append("image", file);
    }
    if (!this.state.userImage) {
      formdata.append("remove_image", "true");
    }
    this.editProfileCallID = await this.apiEditCall({
      contentType: "",
      method: configJSON.editProfileApiMethodType,
      endPoint: `account_block/accounts/update_profile`,
      body: formdata,
      type: 'formData'
    });
  }

  checkFirstNameError = () => {
    if (!this.state.userFirstName.match(configJSON.firstNameRegex)) {
      this.setState({
        errorsUserFirstName: 'firstNameLetterOnly',
      });
    }
    if (!this.state.userFirstName.trim()) {
      this.setState({
        errorsUserFirstName: 'firstNameRequired',
      });
    }
  };

  checkLastNameError = () => {
    if (!this.state.userLastName.match(configJSON.firstNameRegex)) {
      this.setState(({
        errorsUserLastName: 'lastNameLetter',
      }));
    }
    if (!this.state.userLastName.trim()) {
      this.setState({
        errorsUserLastName: 'lastNameRequired',
      });
    }
  };

  checkEmailError = () => {
    if (this.state.emailAddress.length == 0) {
      this.setState({
        errorsEmailAddress: 'errorEmailRequired',
      });
    }
    else {
      this.setState({
        errorsEmailAddress: 'errorEmailInvalid',
      });
    }
  };

  checkPhoneNumberError = () => {
    if (this.state.phoneNumber.length == 0)
      this.setState({
        errorsPhoneNumber: 'errorPhoneRequired',
      });
    else
      this.setState({
        errorsPhoneNumber: 'errorLengthPhoneNumberText',
      });
  }

  userstatusUpdateSuccessCallBack = async (responseJson: UserProfileInfo) => {
    this.setState({ showSubmitSuccessModal: true });
    this.showUserProfileData();
  };

  userstatusUpdateFailureCallBack = (error: ErrorUpdateResponse) => {
    alert(error.errors[0].token);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errorsUserFirstName: "",
      errorsUserLastName: "",
      errorsCompanyName: "",
      errorsJobTitle: "",
      errorsCountry: "",
      errorsEmailAddress: "",
      errorsPhoneNumber: "",
      showError: false
    }));
  };

  showUserProfileData = async () => {
    this.showProfileCallID = await this.apiEditCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.userProfileDataApiEndPoint,
    });
  };

  showProfileSuccessCallBack = (responseJson: UserProfileInfo) => {
    this.setState({
      userFirstName: responseJson.name.split(" ")[0],
      userLastName: responseJson.name.split(" ")[1],
      companyName: responseJson.company_name,
      jobTitle: responseJson.job_title,
      country: responseJson.country,
      emailAddress: responseJson.email,
      phoneNumber: responseJson.full_phone_number,
      userId: responseJson.id,
      changeImage: false,
      userImage: dummyUserImage,
      userFullName: responseJson.name
    });
    if (responseJson.image == null) {
      this.setState({
        showImage: dummyUserImage,
        headerUserImage: dummyUserImage,
      });
    }
    else {
      this.setState({
        showImage: responseJson.image,
        headerUserImage: responseJson.image,
      });
    }
  };

  showProfileFailureCallBack = (error: ErrorUpdateResponse) => {
    alert(error.errors[0].token);
  }

  parseMessage = (message:string, t:Function)=>{
    return t(message)
  }


  goToHome = ()=>this.props.navigation.navigate('Home')


  // Customizable Area End
}
