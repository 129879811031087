import React from "react";

// Customizable Area Start
import { Box, withStyles, Theme, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { slider1, arrow } from "./assets";
import { Translation } from 'react-i18next';


const Slide = withStyles((theme: Theme) => ({
  root: {
    flex: "0 0 auto",
    margin: "0 22px", // Adjust the horizontal margin between slides
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Set your desired slide background color
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },

}
))(Box)

const CardContainer = withStyles((theme: Theme) => ({
  root: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    background: "#F6F6F6",
    width: "444px",
    margin:"auto",
    minHeight: "703px", // as per figma
    [theme.breakpoints.down("xs")]: {
      width: "100vw"
    }

  }
}))(Box)







// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderReadMore = (item: { content: string }, index: number) => {


    return (

      <Translation>
        {t =>  <>

{item.content.length > 200 ? (
  <Typography
    align="left"
    variant="h6"
    style={webStyle.lineHeight_30}
  >
    {this.state.expandedCards[index]
      ? <Box
        dangerouslySetInnerHTML={{ __html: item.content }}
      />
      :

      <Box dangerouslySetInnerHTML={{ __html: `${item.content.slice(0, 200)}...` }} />

    }
    <span
      style={webStyle.readMore}
      data-test-id="ReadMore"
      onClick={() => this.toggleCardExpansion(index)}
    
    >
      {this.state.expandedCards[index] ? t('readLess') : t('readMore')}
    </span>
  </Typography>
) : (
  <Typography
    align="left"
    variant="h6"
    style={webStyle.lineHeight_30}
  >
    <Box
      dangerouslySetInnerHTML={{ __html: item.content }}
    />
  </Typography>
)}

</>}
      </Translation>
     
    )


  }


  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <Translation>
 
         {(t,options)=>  <ThemeProvider theme={theme}>
        
        
        <div style={webStyle.carouselContainer}>
          
        <div data-test-id="moveLeft" style={{ ...webStyle.arrow, ...webStyle.leftArrow }}   onClick={this.moveCarouselLeft}>
                {"<"}
              </div>
          <div style={{padding:"0 62px 0 46px", direction:'ltr'}}>
          <div
            style={webStyle.carousel}
            data-test-id="carouselRef"
            ref={this.carouselContainerRef}
          >
            {this.state.slides && this.state.slides.map((item, index) => ( 
              <Slide
                data-test-id="pressSlide"
                key={index}
                innerRef={this.carouselRef}
                style={
                  index === this.state.currentSlide
                    ? { ...webStyle.activeSlide, ...(options.lng === 'ar' && { direction: 'rtl' }) }
                    : { ...webStyle.unactive, ...(options.lng === 'ar' && { direction: 'rtl' }) }
                }
                
                
                onClick={() => this.goToSlide(index)}
              >
                <CardContainer>
                  <Box height="348px" padding="15px">
                    <img src={item.image} alt="card" style={webStyle.carouselCardImage} />
                  </Box>
 
                  <Box>
                    <Box style={webStyle.carouselCardTyoographyContainer}>
                      <Box style={webStyle.carouselCardTypography}>
                        <Typography style={webStyle.cardHeading}>
                          {item.product_name}
                        </Typography>
                      </Box>
                      {this.renderReadMore(item, index)}
                      <Box style={webStyle.seeMoreContainer}>
                        <Box>
                          <Typography
                            align="right"
                            style={webStyle.seeMore}
                          >
                           {t('seeMore')}
                          </Typography>
                        </Box>
                        <Box>
                          <img src={arrow} alt="arrow" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContainer>
              </Slide>
            ))}
          </div>
          <div style={webStyle.dotContainer}>
            {this.state.slides.map((_, index) => (
              <span
                key={index}
                style={{ ...webStyle.dot, ...(index === this.state.currentSlide ? webStyle.activeDot : {}) }}
                onClick={() => this.goToSlide(index)}
                data-test-id="dotBtn"
              />
            ))}
          </div>
          </div>

          <div data-test-id="moveRight" style={{ ...webStyle.arrow, ...webStyle.rightArrow }}
                onClick={this.moveCarouselRight} >
                {">"}
        </div>
        

          
        </div>
       
        
      </ThemeProvider> }

      </Translation>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontSize: "20px",
      fontWeight: 300
    },
    fontFamily: "Poppins",
   }
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carouselImage: {
    width: "100%",
  },
  carousel: {
    display: "flex" as 'flex',
    overflowX: "hidden" as 'hidden',
    scrollBehavior: "smooth" as 'smooth',
    WebkitOverflowScrolling: "touch" as 'touch', // Enable smooth scrolling on iOS
    padding: "10px 0", // Adjust the top and bottom padding as needed
    scrollSnapAlign: "center"
  },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    transform: "translateY(-120%)",
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
  carouselContainer: {
    position: "relative" as 'relative',
    width: "100%", // Adjust the width as needed
    overflow: "hidden",
    textAlign: "center" as 'center',
    margin: "auto",
    maxWidth: "1440px"
  },
  activeSlide: {
    opacity: 1,
    borderRadius: "15px",
    boxShadow: "0px 2px 25px 0px rgba(0, 0, 0, 0.21)"
  },
  unactive: {
    opacity: 0.6,
    borderRadius: "15px",
    border: "2px solid rgba(16, 33, 38, 0.20)"
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px" // Adjust the top margin as needed
  },

  dot: {
    width: "10px",
    height: "10px",
    backgroundColor: "#ccc",
    borderRadius: "50%",
    margin: "0 5px", // Adjust the horizontal margin between dots as needed
    cursor: "pointer"
  },
  activeDot: {
    backgroundColor: "#333"
  },
  carouselCardTyoographyContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "43px",
    padding: "0 19px"
  },
  carouselCardTypography: {
    display: "flex",
    justifyContent: "align-left"
  },
  cardHeading: {
    fontWeight: 700,
    fontSize: "20px"
  },
  lineHeight_30: {
    lineHeight: "30px"
  },
  seeMoreContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    gap: "10px"
  },
  carouselCardImage: {
    objectFit: "cover" as 'cover',
    height: "100%",
    width: "100%",
    borderRadius: "15px",
    objectPosition: '24%'
  },
  seeMore: {
    textDecorationLine: "underline",
    fontWeight: 700,
    color: "#102126",
    fontSize: "20px"
  },
  arrow: {
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "32px",
    cursor: "pointer",
  },
  leftArrow: {
    left: "0",
  },
  rightArrow: {
    right: "0",
  },
  readMore:{ cursor: 'pointer', color: 'blue' }
};
// Customizable Area End
